import classNames from 'classnames'
import { useField } from 'react-final-form'
import { MultiValue, SingleValue } from 'react-select'
import { FormFieldProps } from '../../../../types/commonTypes'
import { CreatableMultiSelect, MultiSelect, Select, SelectOption, SelectProps } from '../../Select/Select'
import { ERROR_ROUNDED_OUTLINE_CLASS_NAME, FormFieldWithError } from '../FormFieldError/FormFieldWithError'
import fieldStyles from '../FormFields.module.css'
import styles from './SelectFormField.module.css'

export function SelectFormField<T extends string | number | boolean = string>({
  name,
  label,
  srOnlyLabel,
  id,
  options,
  inline,
  fieldContainerClassName,
  validateOnlyIfDirty,
  labelClassName,
  className,
  errorClassName,
  parse,
  format,
  ...rest
}: FormFieldProps<SelectProps<T>>) {
  const { input } = useField(name, { parse, format })
  const getValue = () => options.find((option) => option.value === input.value) || undefined

  return (
    <FormFieldWithError
      name={name}
      wrapperClassName={classNames(inline && fieldStyles.inlineField, fieldContainerClassName)}
      validateOnlyIfDirty={validateOnlyIfDirty}
      errorClassName={classNames(styles.selectError, errorClassName)}
    >
      <label htmlFor={id ?? name} className={classNames(srOnlyLabel && 'sr-only', fieldStyles.label, labelClassName)}>
        {label}
      </label>
      <Select<T>
        {...input}
        {...rest}
        id={id ?? name}
        onChange={(option: SingleValue<SelectOption<T>>) => input.onChange(option?.value)}
        value={getValue()}
        options={options}
        className={classNames(ERROR_ROUNDED_OUTLINE_CLASS_NAME, className)}
      />
    </FormFieldWithError>
  )
}

export const MultiSelectFormField: React.FC<FormFieldProps<SelectProps>> = ({
  name,
  label,
  srOnlyLabel,
  id,
  options,
  inline,
  fieldContainerClassName,
  validateOnlyIfDirty,
  className,
  errorClassName,
  ...rest
}) => {
  const { input } = useField(name)
  const getValue = () =>
    options.filter((option) => ((input.value as Array<string | number>) || []).includes(option.value))

  return (
    <FormFieldWithError
      name={name}
      wrapperClassName={classNames(inline && fieldStyles.inlineField, fieldContainerClassName)}
      validateOnlyIfDirty={validateOnlyIfDirty}
      errorClassName={classNames(styles.selectError, errorClassName)}
    >
      <label htmlFor={id ?? name} className={classNames(srOnlyLabel && 'sr-only', fieldStyles.label)}>
        {label}
      </label>
      <MultiSelect
        {...input}
        {...rest}
        id={id ?? name}
        onChange={(selectedOptions: MultiValue<SelectOption>) =>
          input.onChange(selectedOptions.map((option) => option.value))
        }
        value={getValue()}
        options={options}
        className={classNames(ERROR_ROUNDED_OUTLINE_CLASS_NAME, className)}
      />
    </FormFieldWithError>
  )
}

export const CreatableMultiSelectFormField: React.FC<FormFieldProps<Omit<SelectProps, 'options'>>> = ({
  name,
  label,
  srOnlyLabel,
  id,
  inline,
  fieldContainerClassName,
  validateOnlyIfDirty,
  className,
  errorClassName,
  ...rest
}) => {
  const { input } = useField(name)
  const getValue = () =>
    input.value?.map?.((item: string, index: number) => ({ label: item, value: `${item}:${index}` }))

  return (
    <FormFieldWithError
      name={name}
      wrapperClassName={classNames(inline && fieldStyles.inlineField, fieldContainerClassName)}
      validateOnlyIfDirty={validateOnlyIfDirty}
      errorClassName={classNames(styles.selectError, errorClassName)}
    >
      <label htmlFor={id ?? name} className={classNames(srOnlyLabel && 'sr-only', fieldStyles.label)}>
        {label}
      </label>
      <CreatableMultiSelect
        {...input}
        {...(rest as any)}
        id={id ?? name}
        onChange={(options: MultiValue<SelectOption>) =>
          input.onChange(options.map((option) => option.value.split(':')[0]))
        }
        value={getValue()}
        className={classNames(ERROR_ROUNDED_OUTLINE_CLASS_NAME, className)}
      />
    </FormFieldWithError>
  )
}
