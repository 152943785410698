import { TFunction } from 'i18next'
import React, { createContext, PropsWithChildren, useCallback, useContext, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ConfirmationDialog, ConfirmationProps } from '../composites/confirmationDialog/ConfirmationDialog'
import { noop } from '../util/functional'

const getDefaultConfirmationProps = (t: TFunction): ConfirmationProps => ({
  title: t('confirmation_dialog.default_title', 'Warning'),
  text: t('confirmation_dialog.default_text', 'Are you sure you want to proceed?'),
  confirmActionText: t('confirmation_dialog.default_confirm_action_text', 'Confirm'),
  cancelActionText: t('confirmation_dialog.default_cancel_action_text', 'Cancel'),
})

type ConfirmationContextType = {
  requestConfirmation: (props: ConfirmationProps) => Promise<boolean>
}

const ConfirmationContext = createContext<ConfirmationContextType>({
  requestConfirmation: () => Promise.resolve(false),
})

export const ConfirmationContextProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const { t } = useTranslation()
  const defaultConfirmationProps = useMemo<ConfirmationProps>(() => getDefaultConfirmationProps(t), [t])

  const [show, setShow] = useState<boolean>(false)
  const [confirmationProps, setConfirmationProps] = useState<ConfirmationProps>(defaultConfirmationProps)
  const [promiseResolveRef, setPromiseResolveRef] = useState<(value: boolean) => void>(() => noop)
  const lastFocusedElementRef = useRef<any>()

  const requestConfirmation = useCallback((props: ConfirmationProps) => {
    lastFocusedElementRef.current = document.activeElement
    const confirmationPromise = new Promise<boolean>((resolve) => {
      setPromiseResolveRef(() => resolve)
    })
    setConfirmationProps(props)
    setShow(true)
    return confirmationPromise
  }, [])

  const resolveConfirmation = useCallback(
    (answer: boolean) => {
      promiseResolveRef(answer)
      setShow(false)
      lastFocusedElementRef.current?.focus?.()
      setConfirmationProps(defaultConfirmationProps)
      setPromiseResolveRef(() => noop)
    },
    [promiseResolveRef, defaultConfirmationProps],
  )

  return (
    <ConfirmationContext.Provider
      value={{
        requestConfirmation,
      }}
    >
      <ConfirmationDialog
        show={show}
        forceTop={confirmationProps.forceTop}
        confirmationProps={confirmationProps}
        resolveConfirmation={resolveConfirmation}
      />
      {children}
    </ConfirmationContext.Provider>
  )
}

export const useConfirmation = () => {
  const context = useContext(ConfirmationContext)
  if (!context) throw new Error('Expected to be wrapped in a ConfirmationContextProvider!')
  return context
}
