import React from 'react'
import styles from './UnsupportedBrowser.module.css'
import { t } from 'i18next'
import ControlsPic from '../../assets/images/controls2.png'
import LaptopPic from '../../assets/images/laptop.png'
import { isMobileDevice } from '../../util/browserSpecifics'

export const UnsupportedBrowser: React.FC = () => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <img src={'/Seppo_logo.png'} alt='Seppo' className={styles.seppoLogo} />
        <div className={styles.title}>
          {isMobileDevice()
            ? t('unsupported_browser.title_mobile', 'Unsupported device')
            : t('unsupported_browser.title', 'Unsupported browser')}
        </div>
        <br />
        <div className={styles.basicText}>
          {isMobileDevice()
            ? t(
                'unsupported_browser.content_mobile',
                'Mobile devices are not supported by Seppo 3D games. Currently it is uncertain if mobile devices will be supported in the future.',
              )
            : t(
                'unsupported_browser.content_safari',
                "Your browser does not support Seppo 3D games. 3D games' movement controls require mouse locking and the way how it is implemented in your browser breaks the playing experience.",
              )}
        </div>
        <br />
        <img
          style={{ width: '250px', alignSelf: 'center' }}
          src={isMobileDevice() ? LaptopPic : ControlsPic}
          alt='Controls'
        />
        <br />
        <div className={styles.boldText}>
          {isMobileDevice()
            ? t('unsupported_browser.content2_mobile', 'Please use laptop / desktop computer for playing 3D games.')
            : t('unsupported_browser.content2', 'Please use Chrome or Firefox browser for best 3D game experience')}
        </div>
      </div>
    </div>
  )
}
