import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from '../../../../../../../common/components/button/Button'
import { SelectableOption } from '../../../../../../../common/components/selectable/SelectableOption'
import { Game } from '../../../../../../../types/commonTypes'
import { flashTasksSort, levelOrderTasksSort } from '../../../../../../../util/game'
import { StartGameButton } from '../../../../StartGameButton/StartGameButton'
import { ToggleTaskContext } from '../../../Build/components/ToggleTaskContext/ToggleTaskContext'
import sharedSidebarStyles from '../../../EditorSidebar.module.css'
import { PlayTab, PlayerSummary, TaskSummary } from '../../types'
import { Scoreboard } from '../Scoreboard/Scoreboard'
import { PlayersList } from './PlayersList'
import { TasksList } from './TasksList'

type ManageGameProps = {
  selectedTaskId?: number
  selectedPlayerId?: number
  playerSummaries: PlayerSummary[]
  taskSummaries: TaskSummary[]
  game: Game
  onClickTask: (id: number) => void
  onChangePlayTab: (tab: PlayTab) => void
  playTab: PlayTab
  onSelectPlayer: (playerId: number) => void
  isCompact: boolean
  setIsCompact: (isCompact: boolean) => void
  onClickAwardBadge: (playerId: number) => void
  flashDisabled?: boolean
}

export const ManageGamePanel: React.FC<ManageGameProps> = ({
  selectedTaskId,
  taskSummaries,
  playerSummaries,
  game,
  onClickTask,
  onChangePlayTab,
  onSelectPlayer,
  playTab,
  selectedPlayerId,
  isCompact,
  setIsCompact,
  onClickAwardBadge,
  flashDisabled = false,
}) => {
  const { t } = useTranslation()
  const [showScoreboard, setShowScoreboard] = useState<boolean>(false)
  const toggleShowScoreboard = () => setShowScoreboard((prev) => !prev)

  const [showFlash, setShowFlash] = useState<boolean>(false)

  const filteredTaskSummaries = useMemo(() => {
    const filtered = taskSummaries.filter(({ task }) => (showFlash ? task.advanced.isFlash : !task.advanced.isFlash))
    if (showFlash) {
      filtered.sort((a, b) => flashTasksSort(a.task, b.task))
    } else if (game.advancedSettings.levelsEnabled) {
      filtered.sort((a, b) => levelOrderTasksSort(a.task, b.task))
    } else {
      filtered.sort((a, b) => levelOrderTasksSort(a.task, b.task))
    }
    return filtered
  }, [taskSummaries, showFlash, game])

  return (
    <div className={sharedSidebarStyles.editorSidebarPanelContainer}>
      <div className={sharedSidebarStyles.sidebarTopContainer}>
        <div className={sharedSidebarStyles.sidebarHeader}>
          <h3>{t('game_editor.sidebar.manage_game_panel.title', 'Manage game')}</h3>
          <StartGameButton />
        </div>
        <div className={sharedSidebarStyles.sidebarTabSwitchContainer}>
          <SelectableOption
            groupName='playViewTabs'
            id={PlayTab.PLAYERS}
            onChange={() => onChangePlayTab(PlayTab.PLAYERS)}
            selectedOption={playTab}
            text={t('game_editor.sidebar.manage_game_panel.tab_button_players', 'Players')}
          />
          <SelectableOption
            groupName='playViewTabs'
            id={PlayTab.TASKS}
            onChange={() => onChangePlayTab(PlayTab.TASKS)}
            selectedOption={playTab}
            text={t('game_editor.sidebar.manage_game_panel.tab_button_tasks', 'Tasks')}
          />
        </div>
        <ToggleTaskContext
          isFlash={showFlash}
          hasActiveFlashTask={taskSummaries.some(({ task }) => task.advanced.isFlash && task.isOpen)}
          isCompact={isCompact}
          onChangeIsFlash={setShowFlash}
          onChangeIsCompact={setIsCompact}
          hideTaskTypeToggle={playTab === PlayTab.PLAYERS}
          flashDisabled={flashDisabled}
          noPointsGame={game.advancedSettings.noPointsGame}
          totalScore={game.maxPoints}
        />
      </div>
      <div className={sharedSidebarStyles.sidebarMainContainer}>
        {playTab === PlayTab.TASKS ? (
          <TasksList
            hasLevels={game.advancedSettings.levelsEnabled}
            isCompact={isCompact}
            isFlash={showFlash}
            onClickTask={onClickTask}
            taskSummaries={filteredTaskSummaries}
            noPointsGame={game.advancedSettings.noPointsGame}
            selectedTaskId={selectedTaskId}
          />
        ) : (
          <PlayersList
            playerSummaries={playerSummaries}
            game={game}
            isCompact={isCompact}
            onSelectPlayer={onSelectPlayer}
            selectedPlayerId={selectedPlayerId}
            onClickAwardBadge={onClickAwardBadge}
          />
        )}
      </div>

      {!game.advancedSettings.noPointsGame && (
        <>
          <div className={sharedSidebarStyles.sidebarBottomContainer}>
            <Button variant='outline-normal' onClick={toggleShowScoreboard}>
              {t('game_editor.sidebar.manage_game_panel.show_scoreboard_button', 'Show scoreboard')}
            </Button>
          </div>

          {game.gameId != null && showScoreboard && <Scoreboard gameId={game.gameId} onClose={toggleShowScoreboard} />}
        </>
      )}
    </div>
  )
}
