import classNames from 'classnames'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { PointsPill } from '../../../../../../../../../common/components/PointsPill/PointsPill'
import { ToggleSwitch } from '../../../../../../../../../common/components/ToggleSwitch/ToggleSwitch'
import { CloseButton } from '../../../../../../../../../common/components/button/CloseButton'
import { getIcon } from '../../../../../../../../../common/components/icons/utils'
import { useGame } from '../../../../../../../../../contexts/GameContext'
import { Task } from '../../../../../../../../../types/commonTypes'
import styles from './EvaluationHeaderTask.module.css'

const CLOSE_BUTTON_ID = 'evaluation_list_close_button'

type EvaluationHeaderTaskProps = {
  task: Task
  noPointsGame?: boolean
  onClose: () => void
}

export const EvaluationHeaderTask: React.FC<EvaluationHeaderTaskProps> = ({ task, noPointsGame, onClose }) => {
  const { t } = useTranslation()
  const { updateTaskOpenStatus } = useGame()

  const handleChangeAcceptingAnswers = () => updateTaskOpenStatus(task.id, !task.isOpen)

  useEffect(() => {
    document.querySelector<HTMLButtonElement>(`#${CLOSE_BUTTON_ID}`)?.focus?.()
  }, [task.id])

  return (
    <div className={classNames(styles.container, task.advanced.isFlash && styles.container_flash)}>
      <div className={styles.leftContainer}>
        {task.advanced.isFlash ? getIcon('taskFlash') : getIcon('exercisePin')}
      </div>
      <div className={styles.middleContainer}>
        <h5>{task.name}</h5>
        <div className={styles.middleBottomRow}>
          {!noPointsGame && !task.isSingleExplore && (
            <PointsPill
              noIcon
              label={t('game_editor.sidebar.evaluation_list_panel.pill_max_points', {
                defaultValue: '%{points_sum} max',
                points_sum: task.pointsSum,
              })}
              size='small'
            />
          )}
          <div className={styles.toggleSwitchContainer}>
            <ToggleSwitch small onChange={handleChangeAcceptingAnswers} checked={task.isOpen} />
            <p className='tiny'>
              {t('game_editor.sidebar.evaluation_list_panel.accepting_answers_toggle', 'Accepting answers')}
            </p>
          </div>
        </div>
      </div>
      <CloseButton id={CLOSE_BUTTON_ID} className={styles.closeButton} onClick={onClose} />
    </div>
  )
}
