import React, { useEffect, useLayoutEffect, useMemo } from 'react'
import { MapType } from '../../../../api/gameTypes'
import { useGame } from '../../../../contexts/GameContext'
import { MapInstanceID, useMap } from '../../../../util/map'
import { MapOptions } from '../../types'
import { refreshConnectionLinesToMap } from '../Line/LiveConnectionLineHelper'
import { refreshDraggableMarkersToMap } from '../Marker/LiveDraggableMarkerHelper'
import { refreshPlayerMarkersToMap } from '../Marker/LivePlayerMarkerHelper'

type MapboxContainerProps = {
  boardType: MapType
  showMapbox: boolean
  mapInstanceId: MapInstanceID
  mapOptions: MapOptions
  locked?: boolean
}

export const MapboxContainer: React.FC<MapboxContainerProps> = ({
  showMapbox,
  mapInstanceId,
  mapOptions: { center, zoom },
  locked,
}) => {
  const map = useMap(mapInstanceId)
  const { tasks, gameData, moveTask, playerLocations, taskConnections, isReachable } = useGame()

  const filteredTasks = useMemo(() => {
    return tasks.filter((t) => !(t.advanced.isFlash || (gameData?.advancedSettings.levelsEnabled && t.level === 0)))
  }, [tasks, gameData?.advancedSettings.levelsEnabled])

  useEffect(() => {
    if (mapInstanceId === MapInstanceID.EDITOR) {
      refreshDraggableMarkersToMap(filteredTasks, map.api, moveTask, taskConnections, isReachable)
      setTimeout(() => {
        // Do this even if not branching to remove lines in certain cases
        refreshConnectionLinesToMap(filteredTasks, taskConnections, map.api, isReachable)
      }, 20)
    }
  }, [
    map.api,
    moveTask,
    filteredTasks,
    mapInstanceId,
    taskConnections,
    gameData?.rootTaskId,
    isReachable,
    gameData?.gameBoardSettings.isBranching,
  ])

  useEffect(() => {
    if (mapInstanceId === MapInstanceID.EDITOR) {
      refreshPlayerMarkersToMap(playerLocations, map.api)
    }
  }, [map.api, mapInstanceId, playerLocations])

  useLayoutEffect(() => {
    map.api.jumpTo({ center: [...center], zoom })
  }, [center, zoom, map, isReachable])

  return (
    <div
      // inert prevents the geocoder search field autofocus that can't be turned off (https://github.com/mapbox/mapbox-gl-geocoder/issues/388)
      // react does not have type defs for the attribute, hence the awkward spread (https://stackoverflow.com/questions/72720469/error-when-using-inert-attribute-with-typescript)
      {...{ inert: locked ? 'true' : undefined }}
      ref={map.elementRef}
      style={{
        width: showMapbox ? '100%' : 0,
        height: showMapbox ? '100%' : 0,
      }}
    />
  )
}
