import classNames from 'classnames'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { SingleValue } from 'react-select'
import { getIcon } from '../../common/components/icons/utils'
import { ThreeDots } from '../../common/components/loaders/ThreeDots/ThreeDots'
import { RadioItem } from '../../common/components/radio/RadioItem'
import { useConfirmation } from '../../contexts/ConfirmationContext'
import { GameDataProviderInterface } from '../../contexts/OwnGamesContextProvider'
import { routes } from '../../routes'
import { GameAction, GameStatus, GamesFilterSettings, TGameCard } from '../../types/commonTypes'
import { LegacyEditorVersion, setLegacyEditorVersion } from '../../util/editorPreference'
import { isEmpty } from '../../util/functional'
import { safeIsNullOrEmpty } from '../../util/string'
import { GamePreviewModal } from '../GamePreviewModal/GamePreviewModal'
import styles from './GamesOverview.module.css'
import { EmptyContent, EmptyContentProps } from './components/EmptyContent'
import { GamesGrid } from './components/GamesGrid'
import { GamesList } from './components/GamesList'
import { LoaderWithOverlay } from './components/LoaderWithOverlay'
import { PaginationControls, PaginationSelectOption } from './components/PaginationControls'
import { GameFiltersForm } from './components/filters/GameFiltersForm'
import { GamesSort } from './components/sort/GamesSort'
import { getFocusableGameItemId } from './helpers'
import { SponsoredModalWrapper } from '../../pages/sponsored/SponsoredModalWrapper'

type GamesOverviewProps = {
  gameData: GameDataProviderInterface
  title?: string | JSX.Element
  filterSection?: JSX.Element
  libraryMode?: boolean
  showCreateGameCard?: boolean
  onClickCreateGame?: () => void
  onClickCreateWithAi?: () => void
  emptyContent?: EmptyContentProps
  filterFormSettings?: GamesFilterSettings
  allowSorting?: boolean
  isInstructorView?: boolean
  isSponsored?: boolean
  ltiContext?: string
  hidePaginationAndFilters?: boolean
}

enum GameDisplay {
  LIST = 'LIST',
  GRID = 'GRID',
}

export const GamesOverview: React.FC<GamesOverviewProps> = ({
  title,
  filterSection,
  libraryMode,
  showCreateGameCard,
  onClickCreateGame,
  onClickCreateWithAi,
  gameData,
  emptyContent,
  filterFormSettings,
  allowSorting,
  isInstructorView = false,
  isSponsored = false,
  ltiContext,
  hidePaginationAndFilters = false,
}) => {
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const [selectedGameDisplay, setSelectedGameDisplay] = useState<GameDisplay>(() => {
    if ([`${GameDisplay.LIST}`, `${GameDisplay.GRID}`].includes(searchParams.get('display')?.toUpperCase() ?? '')) {
      return searchParams.get('display')?.toUpperCase() as GameDisplay
    }
    return GameDisplay.GRID
  })
  const [gameBeingPreviewed, setGameBeingPreviewed] = useState<TGameCard>()
  const { requestConfirmation } = useConfirmation()
  const { t } = useTranslation()

  const {
    allPageOptions,
    copyGame,
    currentPage,
    deleteGame,
    importGame,
    lastPage,
    loading,
    next,
    pageData,
    pageSize,
    prev,
    selectPageSize,
    setPage,
    filters,
    setFilters,
    gameCreators,
    sortBy,
    setSortBy,
    activateLtiGame,
  } = gameData

  useEffect(() => {
    return () => {
      setFilters?.({})
    }
  }, [setFilters])

  const [showFiltersForm, setShowFiltersForm] = useState<boolean>(false)

  const lastCurrentPage = useRef<number>(currentPage)

  useEffect(() => {
    if (currentPage !== lastCurrentPage.current) {
      const firstGameElement = document.querySelector<HTMLElement>(`[id^=${getFocusableGameItemId('')}]`)
      firstGameElement?.focus({ preventScroll: true })
      if ((firstGameElement?.getBoundingClientRect().top ?? 0) < 0) {
        firstGameElement?.scrollIntoView({ block: 'end', inline: 'nearest', behavior: 'smooth' })
      }
    }
    lastCurrentPage.current = currentPage
  }, [currentPage])

  useEffect(() => {
    setLegacyEditorVersion(LegacyEditorVersion.LEGACY_FRAME)
  }, [])

  const onSelectDisplay = useCallback(
    (e: any) => {
      setSearchParams((prev) => {
        prev.set('display', e.currentTarget.id.toLowerCase())
        return prev
      })
      setSelectedGameDisplay(e.currentTarget.id)
    },
    [setSearchParams],
  )

  const onChangePageSize = useCallback(
    (option: SingleValue<PaginationSelectOption>) => {
      selectPageSize(option!.value)
    },
    [selectPageSize],
  )

  const onChangeCurrentPage = useCallback(
    (option: SingleValue<PaginationSelectOption>) => {
      setPage(option!.value)
    },
    [setPage],
  )

  const onEditGame = (gameId: number, inProgress: boolean, disableNewEditor: boolean) => {
    navigate(routes.createGameEditorWithId(gameId.toString(), disableNewEditor), { state: { isOpen: inProgress } })
  }

  const handleGameAction = (action: GameAction, game: TGameCard) => {
    switch (action) {
      case GameAction.EDIT:
        onEditGame(parseInt(game.id), game.status === GameStatus.IN_PROGRESS, game.disableNewEditor)
        break
      case GameAction.VIEW:
        setGameBeingPreviewed(game)
        break
      case GameAction.COPY:
        if (copyGame) copyGame(parseInt(game.id), game.gameName)
        break
      case GameAction.ACTIVATE:
        if (activateLtiGame && ltiContext) activateLtiGame(parseInt(game.id), game.gameName, ltiContext)
        break
      case GameAction.DELETE:
        if (deleteGame) {
          requestConfirmation({
            title: t('games_overview.delete_confirmation.title', 'Confirm action'),
            text: safeIsNullOrEmpty(game.gameName)
              ? t(
                  'games_overview.delete_confirmation.message_game_without_name',
                  'Are you sure you want to delete game?',
                )
              : t('games_overview.delete_confirmation.message_game_with_name', {
                  defaultValue: 'Are you sure you want to delete game %{name}?',
                  name: game.gameName,
                }),
          }).then((response) => {
            if (response) {
              deleteGame(parseInt(game.id), game.gameName)
              if (gameBeingPreviewed != null) {
                setGameBeingPreviewed(undefined)
              }
            }
          })
        }
        break
      case GameAction.IMPORT:
        if (importGame) {
          importGame(parseInt(game.id), game.gameName)
        }
        break
    }
  }

  const handleClosePreviewModal = () => {
    if (gameBeingPreviewed == null) {
      return
    }
    const gameId = gameBeingPreviewed.id
    setGameBeingPreviewed(undefined)
    document.querySelector<any>(`#${getFocusableGameItemId(gameId)}`)?.focus?.()
  }

  const handleClickFilter = useCallback(() => {
    setShowFiltersForm((prev) => {
      if (prev && setFilters != null) {
        setFilters({})
        if (filters?.activationCode && setFilters) {
          setFilters({ activationCode: filters.activationCode }, true)
        }
      }
      return !prev
    })
  }, [filters, setFilters])

  return (
    <SponsoredModalWrapper gameData={gameData} handleGameAction={handleGameAction} isInstructorView={isInstructorView}>
      {(sponsoredModalProps) => (
        <>
          <div className={styles.headerContainer}>
            {!safeIsNullOrEmpty(title) && <h1>{title}</h1>}
            <div
              className={classNames(
                styles.headerActionsContainer,
                filterSection != null && styles.headerActionsContainer_headerFilters,
              )}
            >
              {((isSponsored && showFiltersForm && filterSection != null) ||
                (!isSponsored && filterSection != null)) && (
                <div className={styles.filterContainer}>{filterSection}</div>
              )}
              {(isSponsored || (!isSponsored && filterFormSettings != null)) &&
                setFilters != null &&
                !hidePaginationAndFilters && (
                  <button
                    className={classNames(styles.filtersButton, showFiltersForm && styles.filtersButton_active)}
                    onClick={handleClickFilter}
                  >
                    <span className='iconWrapperMedium'>{getIcon('filter')}</span>
                    <span>{t('games_overview.filter_button', 'Filter')}</span>
                  </button>
                )}
              {pageData?.length ? (
                <>
                  <div className={styles.displayStyleRadioContainer}>
                    <RadioItem
                      id={GameDisplay.GRID}
                      name='gameDisplayStyle'
                      label={t('games_overview.display_style_label.grid', 'GRID')}
                      iconName='grid'
                      isChecked={selectedGameDisplay}
                      onSelect={onSelectDisplay}
                    />
                    <RadioItem
                      id={GameDisplay.LIST}
                      name='gameDisplayStyle'
                      label={t('games_overview.display_style_label.list', 'LIST')}
                      iconName='list'
                      isChecked={selectedGameDisplay}
                      onSelect={onSelectDisplay}
                    />
                  </div>
                </>
              ) : null}
            </div>
          </div>
          {showFiltersForm && filterFormSettings && setFilters != null && (
            <GameFiltersForm filterSettings={filterFormSettings} onChange={setFilters} creators={gameCreators} />
          )}
          {allowSorting && sortBy != null && setSortBy != null && <GamesSort sortBy={sortBy} onChange={setSortBy} />}
          <div className={styles.gamesAndPaginationContainer}>
            {loading && (
              <LoaderWithOverlay>
                <ThreeDots />
              </LoaderWithOverlay>
            )}
            {pageData != null && pageData?.length > 0 ? (
              <>
                {selectedGameDisplay === GameDisplay.GRID ? (
                  <GamesGrid
                    games={pageData}
                    libraryMode={libraryMode}
                    showCreateGameCard={showCreateGameCard}
                    onClickCreateGame={onClickCreateGame}
                    onClickCreateWithAi={onClickCreateWithAi}
                    onGameAction={sponsoredModalProps.handleGameActionWithSponsoredModal}
                    loading={loading}
                    isSponsoredInstructorView={isInstructorView}
                    ltiContext={ltiContext}
                  />
                ) : (
                  <GamesList
                    games={pageData}
                    onGameAction={sponsoredModalProps.handleGameActionWithSponsoredModal}
                    loading={loading}
                    isSponsoredInstructorView={isInstructorView}
                  />
                )}
                {!hidePaginationAndFilters && (
                  <PaginationControls
                    currentPage={currentPage}
                    loading={loading}
                    pageSize={pageSize}
                    lastPage={lastPage}
                    allPageOptions={allPageOptions}
                    onChangePageSize={onChangePageSize}
                    onChangeCurrentPage={onChangeCurrentPage}
                    onClickNext={next}
                    onClickPrev={prev}
                  />
                )}
                {gameBeingPreviewed != null && (
                  <GamePreviewModal
                    game={gameBeingPreviewed}
                    onClose={handleClosePreviewModal}
                    onGameAction={sponsoredModalProps.handleGameActionWithSponsoredModal}
                  />
                )}
              </>
            ) : emptyContent != null && isEmpty(filters) ? (
              <EmptyContent
                title={emptyContent.title}
                subtitle={emptyContent.subtitle}
                browseButton={emptyContent.browseButton}
              />
            ) : null}
          </div>
        </>
      )}
    </SponsoredModalWrapper>
  )
}
