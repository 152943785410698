import classNames from 'classnames'
import { FORM_ERROR, FormState } from 'final-form'
import arrayMutators from 'final-form-arrays'
import { useCallback, useEffect, useRef, useState } from 'react'
import { Form, FormSpy } from 'react-final-form'
import { FieldArray } from 'react-final-form-arrays'
import { useTranslation } from 'react-i18next'
import { ExerciseType } from '../../../../../api/gameTypes'
import { PopupActionMenuItemProps } from '../../../../../common/components/PopupMenu/PopupActionMenu'
import { Button } from '../../../../../common/components/button/Button'
import { CloseButton } from '../../../../../common/components/button/CloseButton'
import { getIcon } from '../../../../../common/components/icons/utils'
import { useUser } from '../../../../../contexts/userContext'
import { useFormSubmitFailureListener } from '../../../../../hooks/useFormSubmitFailureListener'
import { FnAny, FormErrorType, Game, Subtask, Task } from '../../../../../types/commonTypes'
import { areObjectsEqual, isEmpty } from '../../../../../util/functional'
import { getTotalPointsForTask } from '../../../../../util/game'
import { isNullOrZero } from '../../../../../util/number'
import { AddSubtaskSection } from './AddSubtaskSection'
import { TaskAdvancedSettings } from './AdvancedSettings/TaskAdvancedSettings'
import { SubtaskField } from './SubtaskField'
import { TaskDevPrefill } from './TaskDevPrefill'
import { TaskFormSection } from './TaskFormSection'
import { TaskInformation } from './TaskInformation/TaskInformation'
import { TaskLibraryModal } from './TaskLibrary/TaskLibraryModal'
import styles from './TaskModal.module.css'
import { getInitialValuesForTaskType } from './helpers'
import { AudioHandler } from '../../Chat/components/Footer/AudioHandler'

const FORM_ID = 'task_form'

const getSubtaskElementId = (id: number) => `subtask_${id}`

type TaskFormProps = {
  game: Game
  initialValues: Partial<Task>
  onSubmit: (values: Task) => void
  onCancel: (hasChanges: boolean) => void
  validate: (values: Partial<Task>) => FormErrorType<Task>
  onDelete?: () => void
  onCopy?: (hasChanges: boolean) => void
  showTaskLibraryModal: boolean
  onSetShowTaskLibraryModal: (show: boolean) => void
  showAutomaticBadgeModal: boolean
  onSetShowAutomaticBadgeModal: (show: boolean) => void
  viewOnly: boolean
}

export const TaskForm: React.FC<TaskFormProps> = ({
  game,
  initialValues,
  onSubmit,
  onCancel,
  onDelete,
  onCopy,
  validate,
  showTaskLibraryModal,
  onSetShowTaskLibraryModal,
  showAutomaticBadgeModal,
  onSetShowAutomaticBadgeModal,
  viewOnly,
}) => {
  const { t } = useTranslation()
  const { user } = useUser()
  const [hasChanges, setHasChanges] = useState(false)

  const createHandleAddSubtaskClick = (push: FnAny, checkDisableBadges: FnAny) => (type: ExerciseType) => {
    push('subtasks', getInitialValuesForTaskType(type))
    checkDisableBadges()
  }

  const scrollableContainer = useRef<HTMLDivElement>(null)
  const addLibTaskButtonRef = useRef<HTMLButtonElement>(null)
  const [forceAudioReset, setForceAudioReset] = useState(false)

  const onShowTaskLibraryModal = () => onSetShowTaskLibraryModal(true)

  const onCloseTaskLibraryModal = (subtaskId?: number) => {
    onSetShowTaskLibraryModal(false)
    addLibTaskButtonRef.current?.focus()
    if (subtaskId) {
      setTimeout(() => {
        const child = document.querySelector(`#${getSubtaskElementId(subtaskId)}`)
        const parent = scrollableContainer.current
        if (child != null && parent != null) {
          const top = parent.scrollTop + child.getBoundingClientRect().top - parent.getBoundingClientRect().top - 50
          parent.scrollTo({ top: Math.max(0, top), behavior: 'smooth' })
        }
      }, 0)
    }
  }

  useEffect(() => {
    if (forceAudioReset) {
      setForceAudioReset(false)
    }
  }, [forceAudioReset])

  useEffect(() => {
    //t function needed by tinymce audio plugin
    window.t = t
    return () => {
      window.t = undefined
    }
  }, [t])

  const handleAudioRecorded = useCallback((url: string) => {
    const notifyEvent = new window.CustomEvent('seppo.notify_audio_recorded', { detail: url })
    window.dispatchEvent(notifyEvent)
  }, [])

  const handleCancelAudioRecording = useCallback(() => {
    ;(document.getElementById('AUDIO_REC') as HTMLDialogElement).close()
    const notifyEvent = new window.CustomEvent('seppo.notify_audio_cancelled', { detail: '' })
    window.dispatchEvent(notifyEvent)
  }, [])

  const hasAnyLibrary = user?.hasCommunity || user?.hasOrgLibrary || user?.hasTemplates

  const taskInfoMoreMenuItems: undefined | PopupActionMenuItemProps[] =
    viewOnly || onDelete == null || onCopy == null
      ? undefined
      : [
          { onClick: () => onCopy(hasChanges), text: t('common.duplicate', 'Duplicate'), icon: 'duplicate' },
          { onClick: onDelete, text: t('common.delete', 'Delete'), icon: 'trash' },
        ]

  const { submitFailedToken, submitListener } = useFormSubmitFailureListener()

  const checkRealChanges = useCallback(
    (props: FormState<Record<string, any>, Partial<Record<string, any>>>) => {
      if (hasChanges) return
      let changed = false
      if (props.touched && props.modified) {
        Object.keys(props.touched).forEach((key) => {
          if (key.includes('answerOptions') && props.modified && props.modified[key]) {
            changed = true
          } else if (props.touched && props.touched[key] && props.modified && props.modified[key]) {
            changed = true
          }
        })
      }
      setHasChanges(changed)
    },
    [hasChanges],
  )

  const handleCloseInternal = useCallback(() => {
    onCancel(hasChanges)
  }, [hasChanges, onCancel])

  return (
    <>
      <Form<Task>
        onSubmit={onSubmit}
        initialValues={initialValues}
        validate={validate}
        decorators={[submitListener]}
        mutators={{
          ...arrayMutators,
          checkDisableBadges: (args, state, utils) => {
            const subtasks = (state.formState.values as any).subtasks as Subtask[]
            if (subtasks && subtasks.length === 1 && subtasks[0].type === ExerciseType.ExploreExercise)
              utils.changeValue(state, 'advanced.hasAutomaticBadge', () => false)
          },
        }}
      >
        {({
          handleSubmit,
          values,
          form: {
            mutators: { push, checkDisableBadges },
          },
          submitting,
          errors,
        }) => {
          return (
            <>
              <div className={classNames(styles.taskModalHeader, styles.taskModalSection)}>
                <div className={styles.taskModalHeaderTitle}>
                  <span className={styles.taskModalHeaderIcon}>{getIcon('exercisePin')}</span>
                  <span>{t('game_editor.tasks.task_settings_title', 'Task settings')}</span>
                </div>
                <div className={styles.taskModalHeaderButtons}>
                  {process.env.REACT_APP_ENV === 'development' && initialValues.id == null && <TaskDevPrefill />}
                  <CloseButton autoFocus onClick={handleCloseInternal} />
                </div>
              </div>

              <form onSubmit={handleSubmit} id={FORM_ID}>
                <FormSpy
                  onChange={(props) => {
                    checkRealChanges(props)
                  }}
                />
                <div className={classNames(styles.taskForm, styles.taskModalMainSection)} ref={scrollableContainer}>
                  <TaskFormSection
                    title={t('game_editor.tasks.task_information_title', 'Task information')}
                    moreMenuItems={taskInfoMoreMenuItems}
                    initialIsExpanded
                    hasAnyErrors={errors?.name != null}
                    forceExpandToken={submitFailedToken}
                  >
                    <TaskInformation game={game} level={values.level} viewOnly={viewOnly} />
                  </TaskFormSection>

                  <TaskFormSection
                    title={t('game_editor.tasks.tasks_list_title', 'Tasks')}
                    containerClassName={styles.taskFormTasksContainer}
                    initialIsExpanded
                    hasAnyErrors={!isEmpty(errors?.subtasks)}
                    forceExpandToken={submitFailedToken}
                  >
                    <FieldArray name='subtasks'>
                      {({ fields }) => {
                        return !isNullOrZero(fields?.length) ? (
                          fields.map((name, index) => {
                            return (
                              <SubtaskField
                                key={name}
                                id={getSubtaskElementId(values.subtasks[index].tempId ?? index)}
                                game={game}
                                name={name}
                                value={values.subtasks[index]}
                                onDelete={
                                  viewOnly
                                    ? undefined
                                    : () => {
                                        fields.remove(index)
                                        checkDisableBadges()
                                      }
                                }
                                onMoveDown={
                                  !viewOnly && index < (fields?.length ?? 0) - 1
                                    ? () => fields.move(index, index + 1)
                                    : undefined
                                }
                                onMoveUp={!viewOnly && index > 0 ? () => fields.move(index, index - 1) : undefined}
                                hasAnyErrors={!isEmpty(errors?.subtasks?.[index])}
                                forceExpandToken={submitFailedToken}
                                viewOnly={viewOnly}
                              />
                            )
                          })
                        ) : (
                          <div className={styles.noTasks}>
                            {t('game_editor.tasks.no_tasks_placeholder', 'No tasks yet')}
                          </div>
                        )
                      }}
                    </FieldArray>
                    {!viewOnly && (
                      <>
                        <AddSubtaskSection onClick={createHandleAddSubtaskClick(push, checkDisableBadges)} />
                        {hasAnyLibrary && (
                          <div className={styles.addFromLibraryContainer}>
                            <Button
                              type='button'
                              variant='outline-normal'
                              onClick={onShowTaskLibraryModal}
                              ref={addLibTaskButtonRef}
                            >
                              {getIcon('search')}
                              {t('game_editor.tasks.add_task_from_library_button', 'Add from library')}
                            </Button>
                          </div>
                        )}
                      </>
                    )}
                  </TaskFormSection>

                  <TaskFormSection
                    title={t('game_editor.tasks.advanced_title', 'Advanced')}
                    hasAnyErrors={!isEmpty(errors?.advanced)}
                    forceExpandToken={submitFailedToken}
                  >
                    <TaskAdvancedSettings
                      game={game}
                      maxPoints={getTotalPointsForTask(values) ?? 0}
                      showAutomaticBadgeModal={showAutomaticBadgeModal}
                      onSetShowAutomaticBadgeModal={onSetShowAutomaticBadgeModal}
                      isSingleExplore={
                        values.subtasks &&
                        values.subtasks.length === 1 &&
                        values.subtasks[0].type === ExerciseType.ExploreExercise
                      }
                      viewOnly={viewOnly}
                    />
                  </TaskFormSection>
                </div>

                {errors?.[FORM_ERROR] && submitFailedToken && (
                  <div className={classNames(styles.globalFormError, 'small')}>{errors[FORM_ERROR]}</div>
                )}
              </form>
              {!forceAudioReset && (
                <dialog
                  id='AUDIO_REC'
                  className={styles.recDialog}
                  onClose={() => {
                    setForceAudioReset(true)
                  }}
                >
                  <AudioHandler
                    disableAutoStart
                    onSubmitAudio={handleAudioRecorded}
                    onCancel={handleCancelAudioRecording}
                  />
                </dialog>
              )}

              <div className={classNames(styles.taskModalFooter, styles.taskModalSection)}>
                <Button variant='outline-normal' onClick={() => onCancel(!areObjectsEqual(values, initialValues))}>
                  {t('common.cancel', 'Cancel')}
                </Button>
                {!viewOnly && (
                  <Button type='submit' disabled={submitting} form={FORM_ID}>
                    {t('common.save', 'Save')}
                  </Button>
                )}
              </div>
              {showTaskLibraryModal && <TaskLibraryModal onClose={onCloseTaskLibraryModal} />}
            </>
          )
        }}
      </Form>
    </>
  )
}
