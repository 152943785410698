import classNames from 'classnames'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { getIcon } from '../../../../common/components/icons/utils'

import shared from '../../../../common/styles/shared.module.css'
import styles from './Card.module.css'
import { useUser } from '../../../../contexts/userContext'
import { preventUserCreateGame } from '../../../../util/game'

type CreateGameCardProps = {
  onClick: () => void
  onSaigeClick?: () => void
}

export const CreateGameCard: React.FC<CreateGameCardProps> = ({ onClick, onSaigeClick }) => {
  const { t } = useTranslation()
  const { user } = useUser()
  const noSaigeRoundsLeft = (user?.engineRounds ?? 0) < 1 || preventUserCreateGame(user)

  return (
    <>
      {user?.hasSaigeAccess ? (
        <div className={styles.splitCreateNew}>
          <button
            type={'button'}
            className={classNames(
              styles.container,
              styles.outlined,
              shared.mAxisC,
              shared.sAxisC,
              styles.splitContainer,
            )}
            style={{ width: '100%' }}
            onClick={onClick}
          >
            <span className={styles.splitIconWrapper}>{getIcon('plus')}</span>
            <p className={styles.text}>{t('dashboard.create_button', 'New game')}</p>
          </button>
          <button
            type={'button'}
            className={classNames(
              styles.container,
              styles.secondaryOutlined,
              shared.mAxisC,
              shared.sAxisC,
              styles.splitContainer,
              noSaigeRoundsLeft && styles.saigeDisabled,
            )}
            style={{ width: '100%' }}
            onClick={onSaigeClick}
            disabled={noSaigeRoundsLeft}
          >
            <span className={classNames(styles.splitIconWrapper, styles.seppoAiIcon)}>{getIcon('seppoAiIcon')}</span>
            <p className={styles.secondaryText}>
              {noSaigeRoundsLeft
                ? t('dashboard.ai_no_rounds', 'Out of Saige tokens')
                : t('dashboard.ai_game_button', 'New with Saige')}
            </p>
          </button>
        </div>
      ) : (
        <div>
          <button
            type={'button'}
            className={classNames(styles.container, styles.outlined, shared.mAxisC, shared.sAxisC)}
            style={{ width: '100%' }}
            onClick={onClick}
          >
            <span className={styles.iconWrapper}>{getIcon('plus')}</span>
            <p className={styles.text}>{t('dashboard.create_button', 'New game')}</p>
          </button>
        </div>
      )}
    </>
  )
}
