import { useField, useFormState } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import { FormSubmitError } from '../../../../../../common/components/Form/FormFieldError/FormSubmitError'
import { Button } from '../../../../../../common/components/button/Button'
import { getIcon } from '../../../../../../common/components/icons/utils'
import { Badge } from '../../../../../../types/commonTypes'
import { BadgePicker } from '../../../Badges/BadgePicker/BadgePicker'
import { BadgeView } from '../../../Badges/BadgeView/BadgeView'
import { PointsInput } from '../../../Points/PointsInput'
import styles from './AutomaticBadge.module.css'

type AutomaticBadgeProps = {
  availableBadges: Badge[]
  noPointsGame?: boolean
  maxPoints: number
  showAutomaticBadgeModal: boolean
  viewOnly: boolean
  onSetShowAutomaticBadgeModal: (show: boolean) => void
}

export const AutomaticBadge: React.FC<AutomaticBadgeProps> = ({
  availableBadges,
  noPointsGame,
  maxPoints,
  showAutomaticBadgeModal,
  viewOnly,
  onSetShowAutomaticBadgeModal,
}) => {
  const { t } = useTranslation()

  const { input: badgeIdInput, meta: badgeIdMeta } = useField('advanced.automaticBadgeId')
  const { submitFailed } = useFormState()

  const currentBadge = badgeIdInput == null ? undefined : availableBadges.find((b) => b.id === badgeIdInput.value)

  const onSelectBadge = (badgeId: number) => {
    badgeIdInput.onChange(badgeId)
    onSetShowAutomaticBadgeModal(false)
  }

  const openModal = () => onSetShowAutomaticBadgeModal(true)
  const closeModal = () => onSetShowAutomaticBadgeModal(false)

  return (
    <>
      <div className={styles.automaticBadgeContainer}>
        <span className='tiny'>
          {t(
            'game_editor.tasks.advanced.automatic_badge_select_badge_description',
            'Select the badge you want to award. Add new badges in Game settings.',
          )}
        </span>
        <div className={styles.currentBadgeAndButton}>
          {currentBadge != null && <BadgeView badge={currentBadge} />}
          <Button variant='outline-tiny' onClick={openModal} disabled={viewOnly}>
            <span className='iconWrapperMedium'>{getIcon('badgeGradient')}</span>
            {currentBadge == null
              ? t('game_editor.tasks.advanced.automatic_badge_button_select', 'Select badge')
              : t('game_editor.tasks.advanced.automatic_badge_button_change', 'Change badge')}
          </Button>
        </div>
        {submitFailed && (
          <FormSubmitError error={badgeIdMeta.error} modifiedSinceSubmit={false} className={styles.badgeIdError} />
        )}
        {/* Keep the points field also in noPointsGame, but it's value is set to be 0 */}
        <div className={styles.badgePointsArea} style={noPointsGame ? { display: 'none' } : {}}>
          <span className='tiny'>
            {t(
              'game_editor.tasks.advanced.automatic_badge_required_points_description',
              'Define a point threshold. If empty, every player receives the badge when submitting their answer.',
            )}
          </span>
          <PointsInput
            name='advanced.automaticBadgePoints'
            label={t(
              'game_editor.tasks.advanced.automatic_badge_required_points_sr_label',
              'Points required for badge',
            )}
            max={maxPoints}
            disabled={viewOnly}
            forceValue={noPointsGame ? 0 : undefined}
          />
        </div>
      </div>
      {showAutomaticBadgeModal && (
        <BadgePicker
          badges={availableBadges}
          selectedBadgeId={badgeIdInput?.value}
          onSelectBadge={onSelectBadge}
          onClose={closeModal}
        />
      )}
    </>
  )
}
