import React from 'react'
import { Card } from './card/Card'
import { CreateGameCard } from './card/CreateGameCard'
import { GameAction, TGameCard } from '../../../types/commonTypes'
import styles from '../GamesOverview.module.css'
import classNames from 'classnames'
import { useFadeAnimation } from '../../../hooks/FadeAnimation/useFadeAnimation'

type GamesGridProps = {
  games: TGameCard[]
  onGameAction: (action: GameAction, game: TGameCard) => void
  libraryMode?: boolean
  showCreateGameCard?: boolean
  onClickCreateGame?: () => void
  onClickCreateWithAi?: () => void
  loading: boolean
  isSponsoredInstructorView?: boolean
  ltiContext?: string
}

export const GamesGrid: React.FC<GamesGridProps> = ({
  games,
  onGameAction,
  showCreateGameCard,
  onClickCreateGame,
  onClickCreateWithAi,
  libraryMode,
  loading,
  isSponsoredInstructorView = false,
  ltiContext,
}) => {
  const { fadeTransition } = useFadeAnimation(loading, true)

  return (
    <>
      <div className={classNames(styles.gridContainer, fadeTransition)}>
        {showCreateGameCard && onClickCreateGame != null && (
          <CreateGameCard onClick={onClickCreateGame} onSaigeClick={onClickCreateWithAi} />
        )}
        {games.map((game, index) => {
          return (
            <Card
              key={`${game.id}${index}`}
              game={game}
              onGameAction={onGameAction}
              libraryMode={libraryMode}
              isSponsored={game.librarySource === 'SPONSORED'}
              isSponsoredInstructorView={isSponsoredInstructorView}
              userLtiContext={ltiContext}
            />
          )
        })}
      </div>
    </>
  )
}
