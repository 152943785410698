import { DateTime, Maybe } from '../types/commonTypes'

export interface GetBusinessSettingsParams
  extends Readonly<{
    authorization?: string | null
    businessId: number
  }> {}

export interface GetBusinessSettingsResponse extends Readonly<BusinessSettingsData> {}

export interface UpdateMemberRoleParams
  extends Readonly<{
    authorization?: string | null
    businessId: number
    userName: string
  }> {}

export interface UpdateAllMembersSaigeParams
  extends Readonly<{
    authorization?: string | null
    businessId: number
    enabledState: boolean
  }> {}

export interface DeleteMemberParams
  extends Readonly<{
    authorization?: string | null
    businessId: number
    userName: string
  }> {}

export interface AddMembersParams
  extends Readonly<{
    authorization?: string | null
    businessId: number
    members: AddMember[]
    message?: string
  }> {}

export interface AddMember
  extends Readonly<{
    email: string
    name: string
    is_admin: boolean
    saige_access: boolean
    org_id: string
  }> {}

export interface Member
  extends Readonly<{
    id: number
    name: string // what is used as userName, in most cases it's email
    contact_name: Maybe<string> // the name field
    is_admin: boolean
    saige_access: boolean
    is_super_user: boolean
    org_id?: string
  }> {}

export enum PaymentMethod {
  INVOICE = 'INVOICE',
  CARD = 'CARD',
}
export interface PaymentData
  extends Readonly<{
    card_masked_number: Maybe<string>
    card_valid_until: Maybe<string>
    city: Maybe<string>
    contact_name: Maybe<string>
    country: Maybe<string>
    created_at: Maybe<string>
    einv_address: Maybe<string>
    einv_operator: Maybe<string>
    email: Maybe<string>
    id: number
    industry: Maybe<string>
    is_primary: boolean
    name: Maybe<string>
    organization: Maybe<string>
    payment_type: PaymentMethod
    phone: Maybe<string>
    postal_code: Maybe<string>
    street_address: Maybe<string>
    updated_at: Maybe<string>
    user_id: number
    vat: Maybe<string>
  }> {}

export interface BusinessSettingsData
  extends Readonly<{
    business_type: number // 10 -> new data model
    members: Member[]
    good_trough: DateTime // date when subscription will be renewed / billed next time
    all_time_player_count: number // player count in all games ever created by users belonging to this business
    // additional info for business type 10
    total_player_seats: Maybe<number> // max player seats (player_accounts and pin combined)
    renew_cycle: Maybe<number> // 3 or 12 months for how often subscription renews and the used seats are cleared
    good_through: Maybe<DateTime>
    auto_renew: Maybe<boolean>
    next_price: Maybe<number>
    player_account_seat_count: Maybe<number> // seats taken by player_accounts
    pin_seat_count: Maybe<number> // seats taken by PIN code players
    next_free: Maybe<DateTime>
    payment_method: Maybe<PaymentData>
    used_pin_seats: Maybe<string>
    used_player_account_seats: Maybe<string>
    max_members: Maybe<number>
    max_teams: Maybe<number>
    limit_members: Maybe<boolean>
    total_quota_usage: Maybe<number>
    total_seats: Maybe<number>
    used_seats: Maybe<number>
    free_seats: Maybe<number>
    engine_rounds: Maybe<number>
    engine_pin: Maybe<string>
    engine_all_members: boolean
  }> {}
