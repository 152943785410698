import classNames from 'classnames'
import { PropsWithChildren } from 'react'
import { useDisableBodyScroll } from '../../../hooks/useDisableBodyScroll'
import { ModalPortal } from '../Portal/Portal'
import styles from './Modal.module.css'

type ModalProps = {
  noBackdrop?: boolean
  forceTop?: boolean
}

export const Modal: React.FC<PropsWithChildren<ModalProps>> = ({ noBackdrop, children, forceTop }) => {
  useDisableBodyScroll()

  return (
    <ModalPortal>
      <div
        className={classNames(styles.backdrop, !noBackdrop && styles.backdrop_darkened, forceTop && styles.forceTop)}
      >
        {children}
      </div>
    </ModalPortal>
  )
}
