import classNames from 'classnames'
import L from 'leaflet'
import 'leaflet/dist/leaflet.css'
import { useCallback, useMemo } from 'react'
import { Marker } from 'react-leaflet'
import { TaskIconType } from '../../../../api/gameTypes'
import { useGame } from '../../../../contexts/GameContext'
import { Task } from '../../../../types/commonTypes'
import { BoardCoords } from '../BoardSection/BoardImage'
import styles from './DraggableMarker.module.css'
import { TaskPinRawSvgMap, getPinIdClass } from './TaskPinHelper'

export const PIN_WIDTH = 48
export const PIN_HEIGHT = 48
export const PIN_ANCHOR_Y_FIX = 8

const getIcon = (task: Task, isHighlighted: boolean, isCrossing: boolean, notReachable: boolean) => {
  return new L.DivIcon({
    html: TaskPinRawSvgMap[
      isCrossing
        ? TaskIconType.crossing
        : task.advanced.hasLockCode
        ? TaskIconType.codeLock
        : task.advanced.iconType || TaskIconType.seppo
    ],
    iconSize: [PIN_WIDTH, PIN_HEIGHT],
    iconAnchor: [PIN_WIDTH / 2, PIN_HEIGHT - PIN_ANCHOR_Y_FIX],
    className: classNames(
      styles.icon,
      isHighlighted && styles.icon_highlighted,
      getPinIdClass(task.id),
      notReachable && styles.notReachable,
    ),
  })
}

type DraggableMarkerProps = {
  task: Task
  boardCoords: BoardCoords
  notReachable?: boolean
  isBranching?: boolean
}

export const DraggableMarker: React.FC<DraggableMarkerProps> = ({
  task,
  boardCoords,
  notReachable = false,
  isBranching = false,
}) => {
  const { highlightedTaskId, moveTask, taskConnections } = useGame()

  const isCrossing = isBranching && taskConnections.filter((conn) => conn.fromId === task.id).length > 1

  const getX = useCallback(
    (x: number) => {
      return boardCoords.xZero + boardCoords.xMultiplier * x
    },
    [boardCoords.xMultiplier, boardCoords.xZero],
  )

  const getY = useCallback(
    (y: number) => {
      return boardCoords.yMax - (boardCoords.yZero + boardCoords.yMultiplier * y) + boardCoords.yZero
    },
    [boardCoords.yMax, boardCoords.yMultiplier, boardCoords.yZero],
  )

  const reverseX = useCallback(
    (x: number) => {
      return (x - boardCoords.xZero) / boardCoords.xMultiplier
    },
    [boardCoords.xMultiplier, boardCoords.xZero],
  )

  const reverseY = useCallback(
    (y: number) => {
      return 100 - (y - boardCoords.yZero) / boardCoords.yMultiplier
    },
    [boardCoords.yMultiplier, boardCoords.yZero],
  )

  const eventHandlers = useMemo(
    () => ({
      dragend(evt: any) {
        moveTask(evt.target.options.alt, reverseX(evt.target._latlng.lng), reverseY(evt.target._latlng.lat))
      },
    }),
    [moveTask, reverseX, reverseY],
  )

  return (
    <Marker
      alt={task.id + ''}
      key={task.id}
      draggable={true}
      icon={getIcon(task, task.id === highlightedTaskId, isCrossing, notReachable || task.order3D === -1)}
      position={[getY(task.y), getX(task.x)]}
      eventHandlers={eventHandlers}
    />
  )
}
