import classNames from 'classnames'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { getIcon } from '../../../../../../../common/components/icons/utils'
import { getTaskIcon } from '../../../../../../../util/game'
import { getTaskCardFocusableElementId } from '../../../../../helpers'
import { getTitleForTask } from '../../../../Tasks/TaskModal/helpers'
import { TaskSummary } from '../../types'
import styles from './SelectableTaskCard.module.css'
import { TaskAnswerCount } from './TaskAnswerCount'

type SelectableTaskProps = {
  taskSummary: TaskSummary
  isSelected: boolean
  compact: boolean
  noPointsGame?: boolean
  showLevel?: boolean
  onClickTask: (id: number) => void
  onEdit?: (id: number) => void
  onDelete?: (id: number) => void
}

export const SelectableTaskCard: React.FC<SelectableTaskProps> = ({
  taskSummary,
  compact,
  isSelected,
  noPointsGame,
  showLevel,
  onClickTask,
}) => {
  const { t } = useTranslation()

  const { task, gradedAnswers, needsReview, totalAnswers } = taskSummary

  const onClickTaskInternal = useCallback(() => {
    onClickTask(task.id)
  }, [task.id, onClickTask])

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLLIElement>) => {
      if (e.key === 'Enter') {
        onClickTaskInternal()
      }
    },
    [onClickTaskInternal],
  )

  return (
    <li
      className={classNames(
        styles.container,
        isSelected && styles.isSelected_container,
        needsReview && styles.needsReview_container,
        compact && styles.containerCompact,
      )}
      onClick={onClickTaskInternal}
      tabIndex={0}
      onKeyDown={handleKeyDown}
      id={getTaskCardFocusableElementId(task.id)}
    >
      <div className={styles.leftContainer}>{getTaskIcon(task, showLevel)}</div>
      <div className={classNames(styles.middleContainer, isSelected && styles.isSelected_middleContainer)}>
        <h5 className={styles.taskName}>{task.name}</h5>
        <div className={styles.middleBottomRow}>
          <div className={styles.statusAndTypePill}>
            {needsReview ? t('game_editor.sidebar.tasks.status_needs_review', 'Review') : getTitleForTask(task, t)}
          </div>
          <TaskAnswerCount total={totalAnswers} graded={gradedAnswers} className={styles.answerCount} />
          {!noPointsGame && !task.isSingleExplore && (
            <div className={styles.iconAndPointsContainer}>
              {getIcon('scoreGradient')}
              {task.pointsSum}
            </div>
          )}
        </div>
      </div>
    </li>
  )
}
