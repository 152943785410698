import { IconName } from '../../../../../../../../../common/components/icons/utils'
import { AnswerStateEnum, Evaluation } from '../../../../../../../../../types/commonTypes'
import { PlayerCard } from '../../../Player/PlayerCard'
import { TaskStateAccordion } from './TaskStateAccordion'

type EvaluationListByStateProps = {
  icon: IconName
  title: string
  evaluations: Evaluation[]
  state: AnswerStateEnum
  selectedPlayerId?: number
  noPointsGame?: boolean
  isCompact: boolean
  onClickPlayer: (id: number) => void
}

export const EvaluationListByState: React.FC<EvaluationListByStateProps> = ({
  icon,
  title,
  evaluations,
  state,
  selectedPlayerId,
  noPointsGame,
  isCompact,
  onClickPlayer,
}) => {
  if (evaluations.length === 0) {
    return null
  }
  return (
    <TaskStateAccordion taskStateIcon={icon} taskStateTitle={title} state={state}>
      {evaluations.map(({ player, state, totalPoints, task }) => {
        return (
          <PlayerCard
            key={`playerEvaluation_${player.id}`}
            id={player.id}
            isSelected={player.id === selectedPlayerId}
            playerName={player.nickName}
            points={totalPoints}
            state={state}
            onClickPlayer={onClickPlayer}
            noPointsGame={noPointsGame}
            isExplore={task.isSingleExplore}
            exploreAckRequired={task.exploreAckRequired}
            isCompact={isCompact}
          />
        )
      })}
    </TaskStateAccordion>
  )
}
