import React, { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import io from 'socket.io-client'
import {
  awardBadgeApi,
  createNewGame,
  createOrUpdateBadgeApi,
  deleteBadgeApi,
  deleteCannedGameMessageApi,
  deleteCannedUserMessageApi,
  deleteFromGameLibrary as deleteFromGameLibraryApi,
  deleteInstructor,
  deletePlayer,
  genericUpdateGame,
  getCannedGameMessagesApi,
  getCannedUserMessagesApi,
  getExerciseAnswersCSV,
  getGame,
  getGameBranchConnectionsCSV,
  getGameExercisesCSV,
  getGamePeopleCSV,
  getLibraryGameBySeppoId,
  manageConnectionLine as manageConnectionLineApi,
  openGame,
  postToCannedGameMessagesApi,
  postToCannedUserMessagesApi,
  postToGameLibrary,
  updateFlashTasksOrderApi,
  updateGameMapStructure,
  updateTasksOrderApi,
} from '../api/gameApiService'
import { Answer, Exercise, LevelCriteriaData, LevelTasksOrderData, TaskIconType } from '../api/gameTypes'
import { getMessaging } from '../api/messagingApiService'
import { MessageTypeEnum, RawMessage } from '../api/messagingTypes'
import {
  createOrUpdateTaskApi,
  deleteTask,
  genericUpdateTaskAttributes,
  moveTaskApi,
  requestAnswerRefresh,
  sendAnswerForRevision,
  sendAnswerGrading,
} from '../api/taskApiService'
import {
  parseBadgeResponseToBadge,
  parseGameResponseToGameVm,
  parseMessagesResponseToMessages,
  parseOneRawMessageToMessage,
} from '../api/typeConverters'
import { MAX_LOCATION_INFO_AGE } from '../pages/GameEditor/components/Marker/LivePlayerMarkerHelper'
import { BadgeForm, Door, GameBoard, GameForm, ShareGameForm } from '../pages/GameEditor/types'
import {
  ApiResponse,
  Badge,
  CannedMessage,
  EditorPermissions,
  Game,
  GamePeople,
  LevelCriteria,
  LibraryGameDetails,
  ReceivedAnswer,
  RoleType,
  TMessage,
  TUser,
  Task,
  TaskConnection,
} from '../types/commonTypes'
import { getRandomTempId, sleep } from '../util/functional'
import { safeParseInt } from '../util/number'
import {
  AnswerEvaluation,
  ApiLocationInfo,
  LocationInfo,
  UpdateItem,
  UpdateType,
  csvToObjHash,
  csvjsonToObj,
  fixCreativeAnswerContent,
  getEditorPermissions,
  getFullTasks,
  getGameLibraryShareParams,
  getGameParams,
  getGamePeople,
  getOldParentIdIfParentTaskChanged,
  parseTaskOrderResponse,
  taskConnectionIdSwitch,
  toBackendMapStructure,
  toBackendMapStructureWithDefaultDoors,
  toCannedMessages,
  toLibraryGameDetails,
  toParentChildrenStructure,
  toPushUser,
} from './GameContextHelper'
import { DEFAULT_THEME_SETTINGS, useTheme } from './ThemeContext'

const PUSH_UPDATE_INTERVAL = 2000

// currently used by components with tasks dnd, for ignoring changes due to manual reordering
interface GameUpdateMetaData {
  lastUpdateType: 'socket' | 'manual-reordering' | 'manual-other'
}

export type GameContextType = {
  gameData: Game | undefined
  libraryGameDetails: LibraryGameDetails | undefined
  tasks: Task[]
  allMessages: TMessage[]
  people: GamePeople
  taskConnections: TaskConnection[]
  updateStoryAndRules: (story: string, rules: string, storyEnd: string, showToPlayers: boolean) => Promise<boolean>
  updateTaskOpenStatus: (taskId: number, open: boolean) => void
  updateTaskMoveToBoard: (taskId: number, boardIndex: number) => Promise<boolean>
  updateGame: (data: GameForm, moveTasks?: boolean, createDefaultDoors?: boolean) => Promise<boolean>
  removeTask: (taskId: number) => void
  removePlayer: (id: number) => Promise<ApiResponse>
  removeInstructor: (name: string) => Promise<ApiResponse>
  refreshGame: () => void
  handleGameOpen: () => void
  isOpen: boolean
  loadingGetGame: boolean
  loadingAnswers: boolean
  gradeAnswer: (gameId: number, answerEvaluation: AnswerEvaluation[]) => Promise<string>
  revisionForAnswer: (gameId: number, answerEvaluation: AnswerEvaluation[], moreTimeSeconds: number) => Promise<string>
  errorGetGame: string | undefined
  createGame: (data: GameForm) => Promise<boolean>
  loadingCreateGame: boolean
  loadingUpdateGame: boolean
  createOrUpdateTask: (task: Task, subtasksToDelete?: number[]) => Promise<boolean>
  moveTask: (taskId: number, x: number, y: number) => Promise<void>
  highlightedTaskId: number | undefined
  isEdit: boolean
  updateLevels: (levelsCriteria: LevelCriteria[]) => Promise<boolean>
  updateTasksOrder: (tasksOrderData: LevelTasksOrderData) => Promise<boolean>
  updateFlashTasksOrder: (orderedTasks: Task[]) => Promise<boolean>
  playerLocations: LocationInfo[]
  triggerAnswerRefresh: (answer: ReceivedAnswer) => void
  shareGameToLibrary: (data: ShareGameForm) => Promise<boolean>
  deleteFromGameLibrary: () => Promise<boolean>
  manageConnectionLine: (fromId: number, toId: number, remove?: boolean) => Promise<ApiResponse>
  updateGameRootTask: (rootTaskId: any) => Promise<boolean>
  updateBranchGoalStatus: (taskId: number, isGoalTask: boolean) => void
  isReachable: (taskId: number) => boolean
  moveExplorationBoardThumbnail: (board: GameBoard, relativeX: number, relativeY: number) => Promise<boolean>
  gameUpdateMeta?: GameUpdateMetaData
  createOrUpdateBadge: (badgeForm: BadgeForm) => Promise<boolean>
  deleteBadge: (badgeId: number) => Promise<boolean>
  awardBadge: (playerId: number, badgeId: number) => Promise<boolean>
  updateExplorationDoor: (board: GameBoard | undefined, door: Door) => Promise<boolean>
  addExplorationDoor: (boardIndex: number, x: number, y: number, leadsToBoardIndex?: number) => Promise<boolean>
  deleteExplorationDoor: (door: Door) => Promise<boolean>
  editorPermissions: EditorPermissions
  cannedUserMessages: CannedMessage[]
  postCannedUserMessage: (messageId: number | null, messageText: string) => Promise<boolean>
  deleteCannedUserMessage: (messageId: number) => Promise<boolean>
  cannedGameMessages: CannedMessage[]
  postCannedGameMessage: (messageId: number | null, messageText: string) => Promise<boolean>
  deleteCannedGameMessage: (messageId: number) => Promise<boolean>
}

export const GameContext = createContext<GameContextType | null>(null)

interface Props {
  gameId: number | null
  user: TUser
  children?: React.ReactNode
}

export const GameProvider: React.FC<Props> = ({ gameId, user, children }) => {
  //gameData contains the basic data of the game + preview info of the tasks
  const [gameData, setGameData] = React.useState<Game>()
  const [libraryGameDetails, setLibraryGameDetails] = useState<LibraryGameDetails>()
  //flatExercises is to be able to easily assign / modify exercises & answers by id
  const flatExercises = useRef<{ [key: number]: Exercise }>({})
  //tasks contain the full data of the tasks in client side Task format
  //Might not be necessary to maintain separately of gameData (which includes tasks as well),
  // but could be useful if we choose to have different amounts of data in them
  const [tasks, setTasks] = useState<Task[]>([])
  //people contains the players and instructors of the game TODO: initial fetch missing
  const [allMessages, setAllMessages] = useState<TMessage[]>([])
  const [people, setPeople] = useState<GamePeople>({
    players: [],
    instructors: [],
    evaluators: [],
    studentInstructors: [],
  })
  const [playerLocations, setPlayerLocations] = useState<LocationInfo[]>([])
  const [taskConnections, setTaskConnections] = useState<TaskConnection[]>([])
  const [cannedUserMessages, setCannedUserMessages] = useState<CannedMessage[]>([])
  const [cannedGameMessages, setCannedGameMessages] = useState<CannedMessage[]>([])
  const [refreshCounter, setRefreshCounter] = useState(0)
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [loadingGetGame, setLoadingGetGame] = useState<boolean>(false)
  const [loadingAnswers, setLoadingAnswers] = useState<boolean>(false)
  const [loadingCreateGame, setLoadingCreateGame] = useState<boolean>(false)
  const [loadingUpdateGame, setLoadingUpdateGame] = useState<boolean>(false)
  const [errorGetGame, setErrorGetGame] = useState<string>()
  const [highlightedTaskId, setHighlightedTaskId] = useState<number>()
  const newlyAddedTaskId = useRef<number>()

  const { updateThemeSettings } = useTheme()
  const socket = useRef<SocketIOClient.Socket | null>(null)
  const refreshNeeded = useRef(false)
  const isLoadingTimeout = useRef(0)

  const gameUpdateMetaRef = useRef<GameUpdateMetaData>()

  const editorPermissions = useMemo(
    () =>
      getEditorPermissions(user, gameData?.editRestricted ?? gameId ? true : false, gameData?.gameBoardSettings.is3D),
    [user, gameData?.editRestricted, gameId, gameData?.gameBoardSettings.is3D],
  )

  const moveExplorationBoardThumbnail = useCallback(
    async (board: GameBoard, relativeX: number, relativeY: number): Promise<boolean> => {
      if (gameId && gameData) {
        const newBoards = gameData.gameBoardSettings.gameBoards
          .filter((b) => b.mapIndex !== board.mapIndex)
          .concat({ ...board, worldPosition: [relativeX, relativeY] })
          .sort((a, b) => (a.mapIndex ?? 0) - (b.mapIndex ?? 0))
        setGameData((prev) => {
          if (!prev) return undefined
          return {
            ...prev,
            gameBoardSettings: {
              ...prev.gameBoardSettings,
              gameBoards: newBoards,
            },
          }
        })

        const updateResponse = await updateGameMapStructure({ gameId, mapStructure: toBackendMapStructure(newBoards) })
        return updateResponse.success
      }
      return false
    },
    [gameData, gameId],
  )

  const updateExplorationDoor = useCallback(
    async (board: GameBoard | undefined, door: Door): Promise<boolean> => {
      if (gameId && gameData && board) {
        const oldDoor = board.doors?.find((d) => d.id === door.id)
        let pairDoor: Door | undefined = undefined
        let destroyLink = false
        delete (door as any).twoWay

        // If oldDoor had unset target, then create a pair door to the other end
        if (oldDoor) {
          if (oldDoor?.leadsToBoardIndex === -1 && door.pair) {
            pairDoor = {
              id: door.pair,
              boardIndex: door.leadsToBoardIndex,
              latitude: door.latitude,
              longitude: door.longitude,
              leadsToBoardIndex: door.boardIndex,
              pair: door.id,
            }
          }
          //If door target map changes from existing -> the pairing link to returning door should be cleared
          else if (oldDoor?.leadsToBoardIndex !== door.leadsToBoardIndex) {
            destroyLink = true
            pairDoor = gameData.gameBoardSettings.gameBoards
              .find((b) => b.mapIndex === door.leadsToBoardIndex)
              ?.doors?.find((d) => d.id === door.pair)
            if (pairDoor) delete (pairDoor as any).twoWay
          }
        }

        //Do the changes to the door being directly modified, clear pair if needed
        let newBoards = gameData.gameBoardSettings.gameBoards
          .filter((b) => b.mapIndex !== board.mapIndex)
          .concat({
            ...board,
            doors: (board.doors ?? [])
              .filter((d) => d.id !== door.id)
              .concat({ ...door, pair: destroyLink ? undefined : door.pair }),
          })
          .sort((a, b) => (a.mapIndex ?? 0) - (b.mapIndex ?? 0))

        //Check if we need to additionally modify the paired door, to remove pairing link from that as well
        const pairBoard = gameData.gameBoardSettings.gameBoards.find((b) => b.mapIndex === door.leadsToBoardIndex)
        if (pairDoor && pairBoard) {
          newBoards = newBoards
            .filter((b) => b.mapIndex !== pairBoard?.mapIndex)
            .concat({
              ...pairBoard,
              doors: (pairBoard.doors?.filter((d) => d.id !== pairDoor?.id) ?? []).concat({
                ...pairDoor,
                pair: destroyLink ? undefined : pairDoor.pair,
              }),
            })
            .sort((a, b) => (a.mapIndex ?? 0) - (b.mapIndex ?? 0))
        }

        //Set the new structure
        setGameData((prev) => {
          if (!prev) return undefined
          return {
            ...prev,
            gameBoardSettings: {
              ...prev.gameBoardSettings,
              gameBoards: newBoards,
            },
          }
        })

        const updateResponse = await updateGameMapStructure({ gameId, mapStructure: toBackendMapStructure(newBoards) })
        return updateResponse.success
      }
      return false
    },
    [gameData, gameId],
  )

  const addExplorationDoor = useCallback(
    async (boardIndex: number, x: number, y: number, leadsToBoardIndex = -1) => {
      if (gameId && gameData) {
        let offset = gameData.gameBoardSettings.gameBoards.find((b) => b.mapIndex === boardIndex)?.doors?.length ?? 0
        const leavingDoorId = getRandomTempId()
        const returningDoorId = getRandomTempId()
        const leavingDoor: Door = {
          id: leavingDoorId,
          boardIndex: boardIndex,
          latitude: y + offset,
          longitude: x + offset,
          leadsToBoardIndex,
          pair: returningDoorId,
        }
        const leavingDoorRet = await updateExplorationDoor(
          gameData.gameBoardSettings.gameBoards.find((b) => b.mapIndex === boardIndex),
          leavingDoor,
        )
        return leavingDoorRet
      }
      return false
    },
    [gameData, gameId, updateExplorationDoor],
  )

  const deleteExplorationDoor = useCallback(
    async (door: Door): Promise<boolean> => {
      if (gameId && gameData) {
        const board = gameData.gameBoardSettings.gameBoards.find((b) => b.mapIndex === door.boardIndex)
        if (!board) return false
        const newBoards = gameData.gameBoardSettings.gameBoards
          .filter((b) => b.mapIndex !== board.mapIndex)
          .concat({
            ...board,
            doors: board.doors?.filter((d) => d.id !== door.id),
          })
          .sort((a, b) => (a.mapIndex ?? 0) - (b.mapIndex ?? 0))

        setGameData((prev) => {
          if (!prev) return undefined
          return {
            ...prev,
            gameBoardSettings: {
              ...prev.gameBoardSettings,
              gameBoards: newBoards,
            },
          }
        })

        const updateResponse = await updateGameMapStructure({ gameId, mapStructure: toBackendMapStructure(newBoards) })
        return updateResponse.success
      }
      return false
    },
    [gameData, gameId],
  )

  const manageConnectionLine = useCallback(
    async (fromId: number, toId: number, remove?: boolean): Promise<ApiResponse> => {
      if (gameId) {
        const connectionLineResponse = await manageConnectionLineApi({ gameId, fromId, toId, isRemove: remove })
        if (connectionLineResponse.success && connectionLineResponse.value.msg === 'OK') {
          if (connectionLineResponse.value.connections) {
            setTaskConnections(
              connectionLineResponse.value.connections?.map((pair) => {
                return { fromId: pair[0], toId: pair[1] }
              }),
            )
          }
          return { success: true }
        } else {
          return {
            success: false,
            errorMessage: connectionLineResponse.success ? connectionLineResponse.value.msg : '',
          }
        }
      }
      return { success: false }
    },
    [gameId],
  )

  const isReachable = useCallback(
    (taskId: number): boolean => {
      if (!gameData?.gameBoardSettings.isBranching || taskId === gameData?.rootTaskId) return true
      const connectionsLeadingTo = taskConnections.filter((tc) => tc.toId === taskId)
      if (connectionsLeadingTo.length) {
        return connectionsLeadingTo.map((tc) => tc.fromId).some(isReachable)
      }
      return false
    },
    [gameData?.gameBoardSettings.isBranching, gameData?.rootTaskId, taskConnections],
  )

  useEffect(() => {
    if (gameId && gameData?.exported) {
      getLibraryGameBySeppoId({ gameId }).then((gameResponse) => {
        if (gameResponse.success) {
          setLibraryGameDetails(toLibraryGameDetails(gameResponse.value))
        } else {
          console.error('Failed to fetch conlib game data for ' + gameId)
        }
      })
    }
  }, [gameData?.exported, gameId])

  useEffect(() => {
    if (gameId) {
      getCannedUserMessagesApi({ gameId }).then((cannedMessagesResponse) => {
        if (cannedMessagesResponse.success) {
          setCannedUserMessages(toCannedMessages(cannedMessagesResponse.value))
        } else {
          console.error('Failed to fetch canned message data for ' + gameId)
        }
      })
      getCannedGameMessagesApi({ gameId }).then((cannedMessagesResponse) => {
        if (cannedMessagesResponse.success) {
          setCannedGameMessages(toCannedMessages(cannedMessagesResponse.value))
        } else {
          console.error('Failed to fetch canned message data for ' + gameId)
        }
      })
    }
  }, [gameData?.exported, gameId])

  const postCannedUserMessage = useCallback(
    async (messageId: number | null, messageText: string) => {
      if (gameId) {
        try {
          postToCannedUserMessagesApi({ gameId, messageId, messageText }).then((cannedMessagesResponse) => {
            if (cannedMessagesResponse.success) {
              setCannedUserMessages(toCannedMessages(cannedMessagesResponse.value))
              return true
            } else {
              console.error('Failed to post canned message data for ' + gameId)
              return false
            }
          })
        } catch (e) {
          console.error('Failed to delete from library ' + gameId)
          return false
        }
      }
      return false
    },
    [gameId],
  )

  const deleteCannedUserMessage = useCallback(
    async (messageId: number) => {
      if (gameId) {
        try {
          const deleteResponse = await deleteCannedUserMessageApi({ gameId, messageId })
          if (deleteResponse.success) {
            setCannedUserMessages(toCannedMessages(deleteResponse.value))
            return true
          }
          return false
        } catch (e) {
          console.error('Failed to delete from library ' + gameId)
          return false
        }
      }
      return false
    },
    [gameId],
  )

  const postCannedGameMessage = useCallback(
    async (messageId: number | null, messageText: string) => {
      if (gameId) {
        try {
          postToCannedGameMessagesApi({ gameId, messageId, messageText }).then((cannedMessagesResponse) => {
            if (cannedMessagesResponse.success) {
              setCannedGameMessages(toCannedMessages(cannedMessagesResponse.value))
              return true
            } else {
              console.error('Failed to post canned message data for ' + gameId)
              return false
            }
          })
        } catch (e) {
          console.error('Failed to delete from library ' + gameId)
          return false
        }
      }
      return false
    },
    [gameId],
  )

  const deleteCannedGameMessage = useCallback(
    async (messageId: number) => {
      if (gameId) {
        try {
          const deleteResponse = await deleteCannedGameMessageApi({ gameId, messageId })
          if (deleteResponse.success) {
            setCannedGameMessages(toCannedMessages(deleteResponse.value))
            return true
          }
          return false
        } catch (e) {
          console.error('Failed to delete from library ' + gameId)
          return false
        }
      }
      return false
    },
    [gameId],
  )

  const shareGameToLibrary = useCallback(
    async (data: ShareGameForm) => {
      if (gameData) {
        try {
          const gameResponse = await postToGameLibrary(getGameLibraryShareParams(gameData.gameId, data))
          if (gameResponse.success) {
            gameUpdateMetaRef.current = { lastUpdateType: 'manual-other' }
            setGameData((prevState) => {
              if (prevState) {
                const { name, description, ages, topics, language, keywords, exported } = parseGameResponseToGameVm(
                  gameResponse.value,
                )
                return {
                  ...prevState,
                  name,
                  description,
                  ages,
                  topics,
                  language,
                  keywords,
                  tasks: prevState.tasks,
                  exported,
                }
              }
            })
            getLibraryGameBySeppoId({ gameId: gameData.gameId }).then((gameResponse) => {
              if (gameResponse.success) {
                setLibraryGameDetails(toLibraryGameDetails(gameResponse.value))
              } else {
                console.error('Failed to fetch conlib game data for ' + gameData.gameId)
              }
            })
            return true
          }
          return false
        } catch (e) {
          console.error(e)
          return false
        }
      }
      return false
    },
    [gameData],
  )

  const deleteFromGameLibrary = useCallback(async () => {
    if (gameId) {
      try {
        const deleteResponse = await deleteFromGameLibraryApi(gameId)
        if (deleteResponse.success && deleteResponse.value.result) {
          gameUpdateMetaRef.current = { lastUpdateType: 'manual-other' }
          setGameData((prevState) => {
            if (prevState) {
              return {
                ...prevState,
                exported: false,
              }
            }
          })
          setLibraryGameDetails(undefined)
          return true
        }
        return false
      } catch (e) {
        console.error('Failed to delete from library ' + gameId)
        return false
      }
    }
    return false
  }, [gameId])

  const updateGame = useCallback(
    async (data: GameForm, moveTasks?: boolean, createDefaultDoors?: boolean) => {
      const game = getGameParams(data)
      if (createDefaultDoors)
        game.map_structure = JSON.stringify(
          toBackendMapStructureWithDefaultDoors(
            data.gameBoardSettings.gameBoardType,
            data.gameBoardSettings.gameBoards,
          ),
        )
      if (data.advancedSettings && !!data.advancedSettings.strongAuthRequired) {
        game.strong_auth_url = user.playerAuthUrl
        game.pin_code_player_enabled = false
      } else {
        game.pin_code_player_enabled = true
        game.strong_auth_url = ''
      }
      if (gameData?.gameBoardSettings.isBranching && !data.advancedSettings.allowBranching) {
        setTaskConnections([])
      }
      setLoadingUpdateGame(true)
      try {
        const gameResponse = await genericUpdateGame({
          game: { ...game, id: gameData?.gameId },
          moveTasks,
        })
        if (gameResponse.success) {
          gameUpdateMetaRef.current = { lastUpdateType: 'manual-other' }
          setGameData((prevState) =>
            prevState ? parseGameResponseToGameVm(gameResponse.value, prevState.tasks) : prevState,
          )
          return true
        } else {
          throw gameResponse.error
        }
      } catch (e) {
        console.error(e)
        return false
      } finally {
        setLoadingUpdateGame(false)
      }
    },
    [gameData?.gameBoardSettings.isBranching, gameData?.gameId, user.playerAuthUrl],
  )

  const updateGameRootTask = useCallback(
    async (rootTaskId: number | null) => {
      setLoadingUpdateGame(true)
      try {
        const gameResponse = await genericUpdateGame({
          game: { root_id: rootTaskId, id: gameData?.gameId },
        })
        if (gameResponse.success) {
          gameUpdateMetaRef.current = { lastUpdateType: 'manual-other' }
          setGameData((prevState) => {
            if (prevState)
              return {
                ...parseGameResponseToGameVm(gameResponse.value),
                tasks: prevState.tasks,
              }
          })
          return true
        } else {
          throw gameResponse.error
        }
      } catch (e) {
        console.error(e)
        return false
      } finally {
        setLoadingUpdateGame(false)
      }
    },
    [gameData?.gameId],
  )

  const updateStoryAndRules = useCallback(
    async (story: string, storyEnd: string, rules: string, showToPlayers: boolean) => {
      gameUpdateMetaRef.current = { lastUpdateType: 'manual-other' }
      setGameData((data) => {
        if (data)
          return {
            ...data,
            storyHtml: story,
            storyEndHtml: storyEnd,
            rulesHtml: rules,
            showStoryAndRules: showToPlayers,
          }
      })
      const gameUpdateResponse = await genericUpdateGame({
        game: { id: gameData?.gameId, description: story, story_end: storyEnd, rules, show_story_rules: showToPlayers },
      })
      return gameUpdateResponse.success
    },
    [gameData?.gameId],
  )

  //Pushed answers are updated realtime to flatExercises ref and refreshNeeded flag is raised
  //Then at certain intervals the state is updated with buffered changes
  //This is to reduce state updates if there are like tens/hundreds of answers answers coming every second
  //Currently interval set to 2000 ms
  useEffect(() => {
    const pushInterval = setInterval(() => {
      if (refreshNeeded.current === false) {
        return
      }
      const t = toParentChildrenStructure(flatExercises.current)
      setTasks(getFullTasks(Object.values(t)))
      refreshNeeded.current = false
    }, PUSH_UPDATE_INTERVAL)
    return () => {
      clearInterval(pushInterval)
    }
  }, [])

  const removeTask = useCallback(
    (taskId: number) => {
      deleteTask({ gameId: gameData?.gameId || 0, exerciseId: taskId })
    },
    [gameData?.gameId],
  )

  const removePlayer = useCallback(
    async (id: number) => {
      const result = await deletePlayer({ gameId: gameData?.gameId || 0, playerId: id })
      return {
        success: result.success && !result.value.error,
        errorMessage: result.success ? result.value.msg : undefined,
      }
    },
    [gameData?.gameId],
  )

  const removeInstructor = useCallback(
    async (name: string) => {
      const result = await deleteInstructor({ gameId: gameData?.gameId || 0, name: name })
      return {
        success: result.success && !result.value.error,
        errorMessage: result.success ? result.value.msg : undefined,
      }
    },
    [gameData?.gameId],
  )

  const refreshGame = useCallback(() => {
    setRefreshCounter((c) => {
      return c + 1
    })
  }, [])

  useEffect(() => {
    const handleRefreshRequestFromFrame = (event: any) => {
      if (event.data?.includes?.('refreshFrameContent')) {
        refreshGame()
      }
    }

    window.addEventListener('message', handleRefreshRequestFromFrame)

    return () => window.removeEventListener('message', handleRefreshRequestFromFrame)
  }, [refreshGame])

  const createGame = useCallback(
    async (data: GameForm) => {
      setLoadingCreateGame(true)
      try {
        const newGameParams = getGameParams(data)
        if (data.advancedSettings?.explorationMode)
          newGameParams.map_structure = JSON.stringify(
            toBackendMapStructureWithDefaultDoors(
              data.gameBoardSettings.gameBoardType,
              data.gameBoardSettings.gameBoards,
            ),
          )
        if (data.advancedSettings && !!data.advancedSettings.strongAuthRequired) {
          newGameParams.strong_auth_url = user.playerAuthUrl
          newGameParams.pin_code_player_enabled = false
        } else {
          newGameParams.pin_code_player_enabled = true
        }
        const result = await createNewGame({ game: newGameParams })
        if (result.success) {
          gameUpdateMetaRef.current = { lastUpdateType: 'manual-other' }
          setGameData(parseGameResponseToGameVm(result.value))
          refreshGame()
          return true
        } else {
          throw result.error
        }
      } catch (error) {
        console.error(error)
        return false
      } finally {
        setLoadingCreateGame(false)
      }
    },
    [refreshGame, user.playerAuthUrl],
  )

  const updateTaskOpenStatus = useCallback(
    (taskId: number, open: boolean) => {
      genericUpdateTaskAttributes({
        gameId: gameData?.gameId || 0,
        exercise: { id: taskId, open },
      })
    },
    [gameData?.gameId],
  )

  const updateBranchGoalStatus = useCallback(
    (taskId: number, isGoalTask: boolean) => {
      genericUpdateTaskAttributes({
        gameId: gameData?.gameId || 0,
        exercise: {
          id: taskId,
          is_branch_end: isGoalTask,
          icon_type: isGoalTask ? TaskIconType.flag : TaskIconType.seppo,
        },
      })
    },
    [gameData?.gameId],
  )

  const createOrUpdateTask = useCallback(
    async (task: Task, subtasksToDelete: number[] = []) => {
      if (!gameData?.gameId) {
        console.warn('Tried to create or update task without game id')
        return Promise.resolve(false)
      }
      const result = await createOrUpdateTaskApi({
        authorization: null,
        gameId: gameData.gameId,
        task,
        noPointsGame: gameData.advancedSettings.noPointsGame,
        subtasksToDelete,
      })
      if (result.success && (task.id == null || task.id === -1)) {
        newlyAddedTaskId.current = result.value.id
      }
      if (result.success && gameData?.advancedSettings.allowBranching) {
        //NOTE If some other instructor does a modification that the parent changes and we get update via push, that does not get handled
        //Should be rare enough to ignore for now
        const newParentId = result.value.id
        const oldParentId = getOldParentIdIfParentTaskChanged(gameData.tasks, task, subtasksToDelete)
        if (oldParentId) setTaskConnections(taskConnectionIdSwitch(taskConnections, oldParentId, newParentId))
      }
      return result.success
    },
    [
      gameData?.gameId,
      gameData?.advancedSettings.noPointsGame,
      gameData?.advancedSettings.allowBranching,
      gameData?.tasks,
      taskConnections,
    ],
  )

  const moveTask = useCallback(
    async (taskId: number, x: number, y: number) => {
      if (!gameData?.gameId) {
        console.warn('Tried to move task without game id')
        return
      }
      const result = await moveTaskApi({
        authorization: null,
        gameId: gameData.gameId,
        exerciseId: taskId,
        x,
        y,
      })
      //Update positions directly UI side if branching to make the lines follow faster
      if (gameData.gameBoardSettings.isBranching) {
        flatExercises.current[taskId] = { ...flatExercises.current[taskId], x, y }
        setTasks(getFullTasks(Object.values(toParentChildrenStructure(flatExercises.current))))
      }
      if (result.success) {
        setTimeout(() => {
          setHighlightedTaskId((prev) => (prev === safeParseInt(taskId) ? undefined : prev))
        }, 1_000)
      }
    },
    [gameData?.gameBoardSettings.isBranching, gameData?.gameId],
  )

  const handleGameOpen = useCallback(() => {
    openGame({ gameId, isOpen: !isOpen })
    setIsOpen((prevState) => !prevState)
    gameUpdateMetaRef.current = { lastUpdateType: 'manual-other' }
    setGameData((prevState) => {
      if (prevState)
        return {
          ...prevState,
          open: !prevState.open,
        }
    })
  }, [gameId, isOpen])

  useEffect(() => {
    if (gameData?.theme != null) {
      updateThemeSettings({
        logoUrl: gameData.theme?.themeLogoUrl ?? DEFAULT_THEME_SETTINGS.logoUrl,
        faviconUrl: gameData.theme?.themeFaviconUrl ?? DEFAULT_THEME_SETTINGS.faviconUrl,
        colorPrimary: gameData.theme?.themeMainColor ?? DEFAULT_THEME_SETTINGS.colorPrimary,
        colorPrimaryLight: gameData.theme?.themeMainLightColor,
        colorSecondary: gameData.theme?.themeSecondaryColor ?? DEFAULT_THEME_SETTINGS.colorSecondary,
        tabName: gameData.theme?.themeTabName ?? DEFAULT_THEME_SETTINGS.tabName,
      })
    }
  }, [gameData?.theme, updateThemeSettings])

  //Push handler for People
  const handlePeople = useCallback((msg: string) => {
    const pushedUser = toPushUser(msg)

    setPeople((p) => {
      if (pushedUser.role === RoleType.Player) {
        return {
          ...p,
          players: p.players.filter((u) => u.id !== pushedUser.id).concat(pushedUser),
        }
      } else if (pushedUser.role === RoleType.Instructor) {
        return {
          ...p,
          instructors: p.instructors.filter((u) => u.id !== pushedUser.id).concat(pushedUser),
        }
      } else if (pushedUser.role === RoleType.Evaluator) {
        return {
          ...p,
          evaluators: p.evaluators.filter((u) => u.id !== pushedUser.id).concat(pushedUser),
        }
      } else if (pushedUser.role === RoleType.StudentInstructor) {
        return {
          ...p,
          studentInstructors: p.studentInstructors.filter((u) => u.id !== pushedUser.id).concat(pushedUser),
        }
      } else {
        return {
          ...p,
        }
      }
    })
  }, [])

  //Push handler for deleting non-player type users
  const handleDeletePeople = useCallback((msg: string) => {
    const pushedUser = toPushUser(msg)

    setPeople((p) => {
      return {
        ...p,
        instructors: p.instructors.filter((u) => u.id !== pushedUser.id),
        evaluators: p.evaluators.filter((u) => u.id !== pushedUser.id),
        studentInstructors: p.studentInstructors.filter((u) => u.id !== pushedUser.id),
      }
    })
  }, [])

  //Push handler for array of players
  //Note that it is not the full list of players, just many players added at the same time
  const handlePlayerArray = useCallback((msgArrayAsString: string) => {
    const pushedMsgs = JSON.parse(msgArrayAsString)
    pushedMsgs.forEach((msg: any) => {
      //Stringify back as the toPushUser want's it like that
      const pushedUser = toPushUser(JSON.stringify(msg))

      setPeople((p) => {
        if (pushedUser.role === RoleType.Player) {
          return {
            ...p,
            players: p.players.filter((u) => u.id !== pushedUser.id && u.name !== pushedUser.name).concat(pushedUser),
          }
        } else if (pushedUser.role === RoleType.Instructor) {
          return {
            ...p,
            instructors: p.instructors.filter((u) => u.id !== pushedUser.id).concat(pushedUser),
          }
        } else if (pushedUser.role === RoleType.Evaluator) {
          return {
            ...p,
            evaluators: p.evaluators.filter((u) => u.id !== pushedUser.id).concat(pushedUser),
          }
        } else if (pushedUser.role === RoleType.StudentInstructor) {
          return {
            ...p,
            studentInstructors: p.studentInstructors.filter((u) => u.id !== pushedUser.id).concat(pushedUser),
          }
        } else {
          return {
            ...p,
          }
        }
      })
    })
  }, [])

  //Push handler for tasks = exercises
  //Possibly changed answers data is not included here, just structural changes to exercises
  const handleTasks = useCallback((msg: string) => {
    const ex: Exercise = JSON.parse(msg)
    flatExercises.current = {
      ...flatExercises.current,
      [ex.id]: {
        ...flatExercises.current[ex.id],
        ...ex,
        answers: flatExercises.current[ex.id] ? flatExercises.current[ex.id].answers : {},
      },
    }
    //When an exercise is initially created with multiple tasks, we receive push message only for the parent, wihch has childrem
    //So, handle children here as well
    if (ex.children)
      ex.children.forEach((childEx) => {
        flatExercises.current = {
          ...flatExercises.current,
          [childEx.id]: {
            ...flatExercises.current[childEx.id],
            ...childEx,
            answers: flatExercises.current[childEx.id] ? flatExercises.current[childEx.id].answers : {},
          },
        }
      })

    const tasksWithAllData = getFullTasks(Object.values(toParentChildrenStructure(flatExercises.current)))
    setTasks(tasksWithAllData)
    // TODO: Consider if we should update the exercises push style or not in basic game data
    // If we do, we should drop the answers from the data
    // For now, we are updating with full data, thus you can see the changes pushed for example by changing an exercise name in legacy editor while keeping ux3 editor open to same game
    gameUpdateMetaRef.current = { lastUpdateType: 'socket' }
    setGameData((gd) => {
      if (gd)
        return {
          ...gd,
          tasks: tasksWithAllData,
        }
      else return undefined
    })
    if (newlyAddedTaskId.current != null) {
      setHighlightedTaskId(newlyAddedTaskId.current)
      newlyAddedTaskId.current = undefined
    }
  }, [])

  //Push handler for removing delete exercises
  const handleDeleteTask = useCallback((msg: string) => {
    const ex: Exercise = JSON.parse(msg)
    delete flatExercises.current[ex.id]
    const tasksWithAllData = getFullTasks(Object.values(toParentChildrenStructure(flatExercises.current)))
    setTasks(tasksWithAllData)
    gameUpdateMetaRef.current = { lastUpdateType: 'socket' }
    setGameData((gd) => {
      if (gd)
        return {
          ...gd,
          tasks: tasksWithAllData,
        }
      else return undefined
    })
  }, [])

  //Push handler for answers
  const handleAnswer = useCallback((msg: string) => {
    const _answer = JSON.parse(msg)
    const answer: Answer = {
      ..._answer,
      answer: _answer.pathname === 'creative_answers' ? fixCreativeAnswerContent(_answer.answer) : _answer.answer,
    }

    flatExercises.current = {
      ...flatExercises.current,
      [answer.exercise_id]: {
        ...flatExercises.current[answer.exercise_id],
        answers: {
          ...flatExercises.current[answer.exercise_id].answers,
          [answer.id]: answer,
        },
      },
    }
    // State not updated here, but flag raised for interval updater
    refreshNeeded.current = true
  }, [])

  //Push handler for chat messages
  const handleChat = useCallback((msg: string) => {
    const message: RawMessage = JSON.parse(msg)
    //Add to allMessages if correct type, filter is there as sometimes same message might come twice, so ensure no duplicates
    //Some legacy chat UI messages will have msg_type as null
    if (message.msg_type === MessageTypeEnum.CHAT || !message.msg_type)
      setAllMessages((prev) => prev.filter((m) => m.id !== message.id).concat(parseOneRawMessageToMessage(message)))
  }, [])

  const handleDeleteChat = useCallback((msg: string) => {
    const message: { msg_id: string } = JSON.parse(msg)
    setAllMessages((prev) => prev.filter((m) => m.id !== parseInt(message.msg_id)))
  }, [])

  const handleLocationInfo = useCallback((locationInfos: ApiLocationInfo[]) => {
    locationInfos.forEach((locationInfo) => {
      const { game_id, lat, lng, user_id, user_name } = locationInfo
      setPlayerLocations((prev) => {
        return (
          prev
            //While filtering away the old info for this player, also filter away too old ones
            .filter(
              (item) =>
                item.userId !== user_id && item.updatedAt.getTime() > new Date().getTime() - MAX_LOCATION_INFO_AGE,
            )
            .concat({
              gameId: game_id,
              lat,
              lng,
              userId: user_id,
              nickName: user_name,
              updatedAt: new Date(),
            })
        )
      })
    })
  }, [])

  //This will trigger backend to push update to this answer (including previous answers)
  const triggerAnswerRefresh = useCallback(
    (answer: ReceivedAnswer) => {
      if (gameId) {
        requestAnswerRefresh({ gameId, answerId: answer.id }).then((previousAnswerResponse) => {
          if (!previousAnswerResponse.success) {
            console.error('Error occurred in fetching previous answers')
          }
        })
      }
    },
    [gameId],
  )

  //evaluationData should contain an entry per each Creative Exercise in the list of subtasks, (but only for creatives)
  //Example usage:
  //   const evalData: AnswerEvaluation[] = [{answerId: 19374, comment: 'Super answer', points: 5}]
  //   gradeAnswer(3874, evalData).then(msg => {
  //     console.log('Status: ' + msg)
  //   })
  const gradeAnswer = useCallback(
    (gameId: number, evaluationData: AnswerEvaluation[]): Promise<string> => {
      return new Promise((resolve, reject) => {
        let status = ''
        evaluationData.forEach(async (answerEvaluation, ind) => {
          await sendAnswerGrading({
            gameId,
            answerId: answerEvaluation.answerId,
            comment: answerEvaluation.comment ?? '',
            points: gameData?.advancedSettings.noPointsGame ? 1 : answerEvaluation.points ?? 0,
            badge: answerEvaluation.badge,
          })
            .then((gradeResponse) => {
              if (gradeResponse.success) {
                status = gradeResponse.value.msg
                if (gradeResponse.value.payload) handleAnswer(gradeResponse.value.payload)
              } else {
                status = 'Failed to send grading'
              }
            })
            .catch((err) => {
              status = 'Failed to send grading'
            })
          if (ind === evaluationData.length - 1) {
            resolve(status)
          }
        })
      })
    },
    [gameData?.advancedSettings.noPointsGame, handleAnswer],
  )

  //evaluationData should contain an entry per each task in the list of subtasks (even auto-graded ones)
  //Example usage
  //   const evalData: AnswerEvaluation[] = [{answerId: 19374, comment: 'Come on guys, you can do better', points: 0},{answerId: 19375, comment: '', points: 0}]
  //   revisionForAnswer(3874, evalData, 0).then(msg => {
  //      console.log('Status: ' + msg)
  //   })
  const revisionForAnswer = useCallback(
    (gameId: number, evaluationData: AnswerEvaluation[], moreTimeSeconds: number): Promise<string> => {
      //TODO Consider moving the comments and points structure building to sendAnswerForRevision and just pass the evaluationData there
      const comments: { [key: string]: string } = {}
      const points: { [key: string]: number } = {}
      let parentAnswerId = evaluationData[0].answerId
      evaluationData.forEach((evalData) => {
        comments['' + evalData.answerId] = evalData.comment ?? ''
        points['' + evalData.answerId] = evalData.points ?? 0
      })

      return sendAnswerForRevision({ gameId, parentAnswerId, comments, points, moreTimeSeconds })
        .then((gradeResponse) => {
          if (gradeResponse.success) {
            return gradeResponse.value.msg
          }
          return 'Failed to send grading'
        })
        .catch((err) => {
          return 'Failed to send grading'
        })
    },
    [],
  )

  const updateTaskMoveToBoard = useCallback(
    async (taskId: number, boardIndex: number) => {
      try {
        const result = await genericUpdateTaskAttributes({
          gameId: gameData?.gameId || 0,
          exercise: { id: taskId, map_id: boardIndex - 1 },
        })
        if (result.success) {
          handleTasks(JSON.stringify({ id: taskId, map_id: boardIndex - 1, x: 50, y: 50 }))
          return true
        } else throw result.error
      } catch (error) {
        console.error(error)
        return false
      }
    },
    [gameData?.gameId, handleTasks],
  )

  //Connect to websocket backend and join needed channels and start listening to events
  useEffect(() => {
    if (gameId !== null) {
      const host = `${process.env.REACT_APP_WS_HOST}`

      socket.current = io(host, {
        transports: ['websocket'],
        reconnection: true,
        reconnectionDelay: 1000,
        reconnectionDelayMax: 5000,
        reconnectionAttempts: Infinity,
      })

      socket.current.connect()
      const publicChannel = ['public', gameId].join('_')
      const teacherChannel = ['teacher', gameId].join('_')
      const privateChannel = ['private', gameId, user.id].join('_')

      socket.current.on('connect', () => {
        if (!socket.current) return
        socket.current.removeListener('update_item')
        socket.current.removeListener('connected to room')

        socket.current.emit('join room', publicChannel)
        socket.current.emit('join room', teacherChannel)
        socket.current.emit('join room', privateChannel)

        socket.current.on('connected to room', (room: string) => {
          //if (DEBUG) console.log(`Connected to room: ${room}`)
        })

        socket.current.on('update_item', (updateItem: UpdateItem) => {
          const { type, msg } = updateItem
          if (JSON.parse(msg).ux3_ignore === true) {
            return
          }
          if (type === UpdateType.User) {
            handlePeople(msg)
          } else if (type === UpdateType.DeleteUser) {
            handleDeletePeople(msg)
          } else if (type === UpdateType.PlayerArray) {
            handlePlayerArray(msg)
          } else if (type === UpdateType.Exercise) {
            handleTasks(msg)
          } else if (type === UpdateType.DeleteExercise) {
            handleDeleteTask(msg)
          } else if (type === UpdateType.Answer) {
            handleAnswer(msg)
          } else if (type === UpdateType.Chat) {
            handleChat(msg)
          } else if (type === UpdateType.DeleteChat) {
            handleDeleteChat(msg)
          }
        })

        socket.current.on('received location info', handleLocationInfo)

        socket.current.on('disconnect', function () {
          if (!socket.current) return
          socket.current.removeListener('update_item')
          socket.current.removeListener('connected to room')
          socket.current.removeListener('disconnect')
        })
      })
    }
    return () => {
      socket.current?.removeAllListeners()
    }
  }, [
    gameId,
    handleAnswer,
    handleChat,
    handleDeletePeople,
    handleDeleteTask,
    handleLocationInfo,
    handlePeople,
    handlePlayerArray,
    handleTasks,
    handleDeleteChat,
    socket,
    user.id,
  ])

  useEffect(() => {
    if (gameId != null) {
      getMessaging({ gameId }).then((result) => {
        if (result.success) {
          setAllMessages(parseMessagesResponseToMessages(result.value.messages))
        }
      })
    }
  }, [gameId])

  //Initial fetching of the game data
  //** NOTE!
  //** With backend providing raw csv data with raw sql queries we can load even huge games in full without timeouts
  //** But if we find out that the UI (once readier) starts to lag with large data amounts we might need to fetch only partials
  useEffect(() => {
    if (gameId != null && !isLoadingTimeout.current) {
      //Force loading 'lock' to be removed after 30 sec if not completed by then
      isLoadingTimeout.current = window.setTimeout(() => {
        isLoadingTimeout.current = 0
      }, 30000)
      setErrorGetGame(undefined)
      setLoadingGetGame(true)
      getGame({ gameId, includeExercises: false })
        .then((getGameResult) => {
          if (getGameResult.success) {
            //We have received the basic game data
            //EXERCISES FETCHING
            setLoadingAnswers(true)
            if (getGameResult.value.branch_type === 'TREE') {
              getGameBranchConnectionsCSV({ gameId }).then((connectionsResult) => {
                if (connectionsResult.success) {
                  const temp: TaskConnection[] = []
                  connectionsResult.value.split(',').forEach((connection, index) => {
                    if (index) {
                      temp.push({
                        fromId: parseInt(connection.split('$')[0]),
                        toId: parseInt(connection.split('$')[1]),
                      })
                    }
                  })
                  setTaskConnections(temp)
                }
              })
            } else {
              setTaskConnections([])
            }
            getGameExercisesCSV({ gameId }).then((exercisesResult) => {
              if (exercisesResult.success) {
                //We have received the exercises in csv format (flat)
                //Parse to object hash
                const tasksTemp = csvjsonToObj(exercisesResult.value)
                //Note! Tasks don't have the answers here yet
                const parsedGameData = parseGameResponseToGameVm({
                  ...getGameResult.value,
                  exercises: Object.values(toParentChildrenStructure(tasksTemp)),
                })
                setIsOpen(parsedGameData.open)
                gameUpdateMetaRef.current = { lastUpdateType: 'manual-other' }
                setGameData(parsedGameData)
                setLoadingGetGame(false)

                // ANSWER FETCHING FOR EACH EXERCISE
                let toLoadCount = Object.values(tasksTemp).length
                if (toLoadCount < 1) {
                  //If there are no tasks, need to clear the timeout as it would prevent refresh calls
                  clearTimeout(isLoadingTimeout.current)
                  isLoadingTimeout.current = 0
                  setLoadingAnswers(false)
                }
                Object.values(tasksTemp).forEach(async (myEx, eIndex) => {
                  // A bit delay for regs not to flood the backend after 10 exs
                  if (eIndex > 10) await sleep(eIndex * 100)
                  const exerciseId = myEx.id
                  const answersResult = await getExerciseAnswersCSV({ gameId, exerciseId })
                  if (answersResult.success) {
                    //Answer data is here in raw csv format, parse to object hash
                    const tempAnswers: { [key: number]: any } = csvToObjHash(answersResult.value)
                    tasksTemp[myEx.id]['answers'] = tempAnswers
                  } else {
                    setErrorGetGame('Failed to fetch answers')
                  }
                  if (--toLoadCount < 1) {
                    //If this is last, update the tasks state
                    flatExercises.current = tasksTemp
                    const t = toParentChildrenStructure(tasksTemp)
                    setTasks(getFullTasks(Object.values(t)))
                    clearTimeout(isLoadingTimeout.current)
                    isLoadingTimeout.current = 0
                    setLoadingAnswers(false)
                  }
                })
              } else {
                setLoadingAnswers(false)
                setErrorGetGame('Failed to fetch exercises')
              }
            })
          } else {
            setErrorGetGame(getGameResult.error.message)
          }
        })
        .catch((e: any) => {
          console.warn(e?.message)
          setErrorGetGame(e?.message ?? 'An error occurred')
        })
        .finally(() => {
          setLoadingGetGame(false)
        })
    }
  }, [gameId, refreshCounter])

  //Separately fetch the game people (players and instructors
  useEffect(() => {
    if (gameId != null) {
      getGamePeopleCSV({ gameId }).then((peopleResult) => {
        if (peopleResult.success) {
          const peopleTemp = csvToObjHash(peopleResult.value)
          setPeople(getGamePeople(peopleTemp))
        }
      })
    }
  }, [gameId])

  const isEdit = useMemo(() => gameId != null, [gameId])

  const updateLevels = useCallback(
    async (levelsCriteria: LevelCriteria[]) => {
      try {
        setLoadingUpdateGame(true)
        const criteriaData: LevelCriteriaData[] = levelsCriteria.map((item) => ({
          name: item.name,
          points: item.points,
          completed_exercises: item.completedTasks,
          defaultMap: gameData?.advancedSettings.explorationMode
            ? null
            : ((item.defaultBoardIndex ?? 0) - 1)?.toString(),
        }))
        const gameResponse = await genericUpdateGame({
          game: { id: gameData?.gameId, levels_criteria: JSON.stringify(criteriaData) },
        })
        if (gameResponse.success) {
          gameUpdateMetaRef.current = { lastUpdateType: 'manual-other' }
          setGameData((prevState) =>
            prevState ? parseGameResponseToGameVm(gameResponse.value, prevState.tasks) : prevState,
          )
          return true
        } else {
          throw gameResponse.error
        }
      } catch (error) {
        console.error(error)
        return false
      } finally {
        setLoadingUpdateGame(false)
      }
    },
    [gameData?.gameId, gameData?.advancedSettings.explorationMode],
  )

  const updateTasksOrder = useCallback(
    async (tasksOrderData: LevelTasksOrderData) => {
      try {
        setLoadingUpdateGame(true)
        const taskOrderResponse = await updateTasksOrderApi({
          gameId: gameData?.gameId ?? 0,
          tasksData: tasksOrderData,
          timestamp: Date.now(),
        })
        if (taskOrderResponse.success) {
          setTasks((prev) => {
            return prev.map((task) => {
              return taskOrderResponse.value.exercise_data[task.id] == null
                ? task
                : {
                    ...task,
                    ...parseTaskOrderResponse(taskOrderResponse.value.exercise_data[task.id]),
                  }
            })
          })
          gameUpdateMetaRef.current = { lastUpdateType: 'manual-reordering' }
          setGameData((prev) =>
            prev?.gameId == null
              ? prev
              : {
                  ...prev,
                  tasks: prev.tasks.map((task) => {
                    return taskOrderResponse.value.exercise_data[task.id] == null
                      ? task
                      : {
                          ...task,
                          ...parseTaskOrderResponse(taskOrderResponse.value.exercise_data[task.id]),
                        }
                  }),
                },
          )
          Object.keys(taskOrderResponse.value.exercise_data).forEach((taskIdKey) => {
            const taskId = parseInt(taskIdKey)
            if (flatExercises.current[taskId]) {
              flatExercises.current[taskId] = {
                ...flatExercises.current[taskId],
                ...taskOrderResponse.value.exercise_data[taskId],
              }
            }
          })
          return true
        } else {
          throw taskOrderResponse.error
        }
      } catch (error) {
        console.error(error)
        return false
      } finally {
        setLoadingUpdateGame(false)
      }
    },
    [gameData?.gameId],
  )

  const updateFlashTasksOrder = useCallback(
    async (orderedFlashTasks: Task[]) => {
      try {
        const orders = orderedFlashTasks.map((task, index) => ({ [task.id]: index }))
        const response = await updateFlashTasksOrderApi({ gameId: gameId ?? 0, orders })
        if (response.success && response.value.success) {
          const tasksMapFn = (task: Task): Task => {
            const flashTaskIndex = orderedFlashTasks.findIndex((other: Task) => other.id === task.id)
            return flashTaskIndex !== -1 ? { ...task, flashOrder: flashTaskIndex + 1 } : task
          }
          setTasks((prev) => prev.map(tasksMapFn))
          gameUpdateMetaRef.current = { lastUpdateType: 'manual-reordering' }
          setGameData((prev) =>
            prev == null
              ? prev
              : {
                  ...prev,
                  tasks: prev.tasks.map(tasksMapFn),
                },
          )
          orderedFlashTasks.forEach((task, index) => {
            if (flatExercises.current[task.id]) {
              flatExercises.current[task.id] = {
                ...flatExercises.current[task.id],
                flash_sort_order: index + 1,
              }
            }
          })
        }
        return response.success && response.value.success
      } catch (error) {
        console.error(error)
        return false
      }
    },
    [gameId],
  )

  const createOrUpdateBadge = useCallback(
    async (badgeForm: BadgeForm) => {
      if (!gameData?.gameId) {
        console.error('cannot create or update badge without game id')
        return false
      }
      const response = await createOrUpdateBadgeApi({
        gameId: gameData.gameId,
        badgeName: badgeForm.badgeName,
        badgeId: badgeForm.id,
        badgeFile: badgeForm.imageFile,
        badgeUrl: badgeForm.imageUrl,
      })
      if (response.success) {
        const newBadge: Badge = {
          id: response.value.id,
          name: response.value.name,
          imageUrl: response.value.image.url,
        }
        gameUpdateMetaRef.current = { lastUpdateType: 'manual-other' }
        setGameData((prev) =>
          prev == null
            ? prev
            : {
                ...prev,
                badges:
                  badgeForm.id == null
                    ? [...prev.badges, newBadge]
                    : prev.badges.map((b) => (b.id === newBadge.id ? newBadge : b)),
              },
        )
      }
      return response.success
    },
    [gameData?.gameId],
  )

  const deleteBadge = useCallback(async (badgeId: number) => {
    const response = await deleteBadgeApi(badgeId)
    if (response.success) {
      gameUpdateMetaRef.current = { lastUpdateType: 'manual-other' }
      setGameData((prev) =>
        prev == null
          ? prev
          : {
              ...prev,
              badges: response.value.badges.map(parseBadgeResponseToBadge),
            },
      )
    }
    return response.success
  }, [])

  const awardBadge = useCallback(
    async (playerId: number, badgeId: number) => {
      const response = await awardBadgeApi(playerId, badgeId, gameData?.gameId ?? 0)
      return response.success && response.value.success
    },
    [gameData?.gameId],
  )

  return (
    <GameContext.Provider
      value={{
        gameData,
        libraryGameDetails,
        refreshGame,
        updateStoryAndRules,
        updateGame,
        updateTaskOpenStatus,
        updateTaskMoveToBoard,
        removeTask,
        removePlayer,
        removeInstructor,
        handleGameOpen,
        isOpen,
        gradeAnswer,
        revisionForAnswer,
        tasks,
        allMessages,
        people,
        taskConnections,
        loadingGetGame,
        loadingAnswers,
        errorGetGame,
        createGame,
        loadingCreateGame,
        loadingUpdateGame,
        createOrUpdateTask,
        moveTask,
        highlightedTaskId,
        isEdit,
        updateLevels,
        updateTasksOrder,
        updateFlashTasksOrder,
        playerLocations,
        triggerAnswerRefresh,
        shareGameToLibrary,
        deleteFromGameLibrary,
        manageConnectionLine,
        updateGameRootTask,
        updateBranchGoalStatus,
        isReachable,
        moveExplorationBoardThumbnail,
        gameUpdateMeta: gameUpdateMetaRef.current,
        createOrUpdateBadge,
        deleteBadge,
        awardBadge,
        updateExplorationDoor,
        addExplorationDoor,
        deleteExplorationDoor,
        editorPermissions,
        cannedUserMessages,
        postCannedUserMessage,
        deleteCannedUserMessage,
        cannedGameMessages,
        postCannedGameMessage,
        deleteCannedGameMessage,
      }}
    >
      {children}
    </GameContext.Provider>
  )
}

export const useGame = () => {
  const context = useContext(GameContext)
  if (!context) {
    throw new Error('Expected to be wrapped in GameContext!')
  }
  return context
}
