import { TFunction } from 'i18next'
import { getIcon } from '../../../../../../../common/components/icons/utils'
import { AnswerStateEnum } from '../../../../../../../types/commonTypes'

export const getInitials = (playerName: string) => {
  return (playerName + '').slice(0, 2)
}

export const getPillState = (taskState: AnswerStateEnum, points: number): string => {
  switch (taskState) {
    case AnswerStateEnum.READY:
    case AnswerStateEnum.REVISION:
    case AnswerStateEnum.IN_PROGRESS:
      return '?'
    case AnswerStateEnum.GRADED:
      return points.toString()
    default:
      return '\u2014'
  }
}

export const getPillLabel = (
  taskState: AnswerStateEnum,
  t: TFunction,
  isExplore = false,
  exploreAckRequired = false,
): string => {
  switch (taskState) {
    case AnswerStateEnum.GRADED:
      return isExplore
        ? exploreAckRequired
          ? t('game_editor.sidebar.player_card.marked_viewed', 'Marked as Viewed')
          : t('game_editor.sidebar.player_card.viewed', 'Viewed')
        : t('game_editor.sidebar.player_card.evaluated', 'Evaluated')
    case AnswerStateEnum.READY:
      return t('game_editor.sidebar.player_card.waiting_for_evaluation', 'Waiting for evaluation')
    case AnswerStateEnum.IN_PROGRESS:
      return t('game_editor.sidebar.player_card.in_progress', 'In progress')
    case AnswerStateEnum.REVISION:
      return t('game_editor.sidebar.player_card.revision_requested', 'Revision requested')
    case AnswerStateEnum.TIME_FINISHED:
      return t('game_editor.sidebar.player_card.time_expired', 'Time exceeded')
    default:
      return t('game_editor.sidebar.player_card.no_answer', 'No answer')
  }
}

export const getEvaluationStateIcon = (taskState: AnswerStateEnum): JSX.Element | null => {
  switch (taskState) {
    case AnswerStateEnum.GRADED:
      return getIcon('circleCheckmark')
    case AnswerStateEnum.REVISION:
      return getIcon('timerGradient')
    case AnswerStateEnum.READY:
      return getIcon('view')
    default:
      return null
  }
}
