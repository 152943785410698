import { useEffect, useRef } from 'react'
import { Field, useField } from 'react-final-form'
import { OnChange } from 'react-final-form-listeners'
import { useTranslation } from 'react-i18next'
import { MapType } from '../../../../../api/gameTypes'
import { FormFieldSpy } from '../../../../../common/components/Form/FormFieldSpy/FormFieldSpy'
import { useConfirmation } from '../../../../../contexts/ConfirmationContext'
import { useUser } from '../../../../../contexts/userContext'
import { AllowedOption, Badge, EditorPermissions, GameAdvancedSettings } from '../../../../../types/commonTypes'
import { hasPermission } from '../../../../../util/permissions'
import { GameBoardSettings } from '../../../types'
import { Badges } from './Badges'
import styles from './GameSettingsComponents.module.css'
import { GameSettingsToggle } from './GameSettingsToggle'
import { PublicGameLink } from './PublicGameLink'

const shouldDisableField = (permissions: EditorPermissions, advancedField: keyof Partial<GameAdvancedSettings>) => {
  if (typeof permissions.gameSettings === 'boolean') {
    return !permissions.gameSettings
  }
  if (typeof permissions.gameSettings.advancedSettings === 'boolean') {
    return !permissions.gameSettings.advancedSettings
  }
  return !permissions.gameSettings?.advancedSettings?.[advancedField]
}

type AdvancedProps = {
  gameId?: number
  mapType: MapType | undefined
  boardCount: number
  advancedSettings: Partial<GameAdvancedSettings>
  hasTasksWithPositionLock: boolean
  badges: Badge[]
  onClickManageBadges: () => void
  permissions: EditorPermissions
}

export const ADVANCED_PIN_SECTION_ID = 'ADVANCED_PIN_SECTION_ID'

//const isDev = process.env.REACT_APP_ENV === 'development'

const getRandomShareSecret = () => (Math.random() + 1).toString(36).substring(4)

export const Advanced: React.FC<AdvancedProps> = ({
  gameId,
  mapType,
  boardCount,
  advancedSettings,
  hasTasksWithPositionLock,
  badges,
  permissions,
  onClickManageBadges,
}) => {
  const { t } = useTranslation()
  const { requestConfirmation } = useConfirmation()
  const { user } = useUser()

  const { input: gpsEnabledInput } = useField('advancedSettings.gpsEnabled')
  const { input: explorationModeInput } = useField('advancedSettings.explorationMode')
  const { input: gameBoardSettingsInput } = useField<GameBoardSettings>('gameBoardSettings')

  const ignoreExplorationSpy = useRef<boolean>(false)
  const ignoreExplorationSpyTimeoutRef = useRef<NodeJS.Timeout>()
  useEffect(() => {
    if (ignoreExplorationSpyTimeoutRef.current != null) {
      clearTimeout(ignoreExplorationSpyTimeoutRef.current)
    }
  }, [])

  return (
    <div className={styles.settingsContainer}>
      {/* GAME MECHANICS */}
      <div className={styles.sectionContainer}>
        <span className={styles.sectionTitle}>
          {t('game_editor.game_settings.advanced.mechanics', 'Game Mechanics')}
        </span>

        {/* Follow task order */}
        <GameSettingsToggle
          name='advancedSettings.orderingEnabled'
          disabled={advancedSettings.allowBranching || shouldDisableField(permissions, 'orderingEnabled')}
          label={t('game_editor.game_settings.advanced.follow_order_label', 'Follow task order')}
          description={t(
            'game_editor.game_settings.advanced.follow_order_description_disabled',
            'This will mandate players to go through the game in the predetermined order.',
          )}
          descriptionChecked={t(
            'game_editor.game_settings.advanced.follow_order_description_enabled',
            'This will mandate players to go through the game in the predetermined order.',
          )}
        />

        {/* Game levels */}
        <GameSettingsToggle
          name='advancedSettings.levelsEnabled'
          disabled={advancedSettings.allowBranching || shouldDisableField(permissions, 'levelsEnabled')}
          label={t('game_editor.game_settings.advanced.levels_label', 'Game levels')}
          description={t(
            'game_editor.game_settings.advanced.levels_description_disabled',
            'This will allow grouping tasks into levels.',
          )}
          descriptionChecked={t(
            'game_editor.game_settings.advanced.levels_description_enabled',
            'This will allow grouping tasks into levels.',
          )}
        />

        {/* Exploration mode */}
        {mapType !== MapType.LIVE && (
          <>
            <GameSettingsToggle
              name='advancedSettings.explorationMode'
              disabled={advancedSettings.allowBranching || shouldDisableField(permissions, 'explorationMode')}
              label={t('game_editor.game_settings.advanced.exploration_mode_label', 'Exploration mode')}
              description={t(
                'game_editor.game_settings.advanced.exploration_mode_description_disabled',
                'Game uses exploration mode for level & map settings.',
              )}
              descriptionChecked={t(
                'game_editor.game_settings.advanced.exploration_mode_description_enabled',
                'Game uses exploration mode for level & map settings.',
              )}
            />
            <OnChange name='advancedSettings.explorationMode'>
              {(value, previous) => {
                if (previous && !value && boardCount > 4) {
                  requestConfirmation({
                    title: t(
                      'game_editor.game_settings.advanced.disabling_exploration_with_over_four_boards_confirmation_title',
                      'Number of boards not supported without exploration mode',
                    ),
                    text: t(
                      'game_editor.game_settings.advanced.disabling_exploration_with_over_four_boards_confirmation_text',
                      {
                        defaultValue:
                          'Without exploration mode, you can have up to 4 boards. Your game has %{boards_count}. If you disable exploration mode, only the first four boards will be kept. Do you wish to proceed?',
                        boards_count: boardCount,
                      },
                    ),
                  }).then((response) => {
                    if (!response) {
                      ignoreExplorationSpy.current = true
                      explorationModeInput.onChange(true)
                      ignoreExplorationSpyTimeoutRef.current = setTimeout(() => {
                        ignoreExplorationSpy.current = false
                      }, 2000)
                    } else {
                      gameBoardSettingsInput.onChange({
                        ...gameBoardSettingsInput.value,
                        gameBoards: gameBoardSettingsInput.value.gameBoards.slice(0, 4),
                      })
                    }
                  })
                }
              }}
            </OnChange>
          </>
        )}

        {/* Branching game paths */}
        {(mapType === MapType.LIVE || (mapType === MapType.STATIC && boardCount < 2)) && (
          <GameSettingsToggle
            name='advancedSettings.allowBranching'
            disabled={
              advancedSettings.explorationMode ||
              advancedSettings.levelsEnabled ||
              advancedSettings.orderingEnabled ||
              shouldDisableField(permissions, 'allowBranching')
            }
            label={t('game_editor.game_settings.advanced.allow_branching_label', 'Branching game paths')}
            description={t(
              'game_editor.game_settings.advanced.allow_branching_description_disabled',
              'Connect tasks to paths that the players must follow.',
            )}
            descriptionChecked={t(
              'game_editor.game_settings.advanced.allow_branching_description_enabled',
              'Connect tasks to paths that the players must follow.',
            )}
          />
        )}

        {/* Allow players to improve their answers */}
        <GameSettingsToggle
          name='advancedSettings.allowPlayersToImproveAnswers'
          label={t(
            'game_editor.game_settings.advanced.allow_improving_answers_label',
            'Allow players to improve their answers',
          )}
          description={t(
            'game_editor.game_settings.advanced.allow_improving_answers_description_disabled',
            'This will allow players to go back and change their answers after sending.',
          )}
          descriptionChecked={t(
            'game_editor.game_settings.advanced.allow_improving_answers_description_enabled',
            'This will allow players to go back and change their answers after sending.',
          )}
          disabled={shouldDisableField(permissions, 'allowPlayersToImproveAnswers')}
        />

        {/* Enable in-game chat */}
        <GameSettingsToggle
          name='advancedSettings.chatEnabled'
          label={t('game_editor.game_settings.advanced.in_game_chat_label', 'Enable in-game chat')}
          description={t(
            'game_editor.game_settings.advanced.in_game_chat_description_disabled',
            'Players can chat during the game.',
          )}
          descriptionChecked={t(
            'game_editor.game_settings.advanced.in_game_chat_description_enabled',
            'Players can chat during the game.',
          )}
          disabled={shouldDisableField(permissions, 'chatEnabled')}
        />
      </div>

      {/* PIN CODE LOGIN OPTIONS */}
      <div className={styles.sectionContainer} id={ADVANCED_PIN_SECTION_ID}>
        <span className={styles.sectionTitle}>
          {t('game_editor.game_settings.advanced.game_login', 'Share game login to players')}
        </span>

        {/* Require players to provide their email */}
        <GameSettingsToggle
          name='advancedSettings.emailRequired'
          label={t('game_editor.game_settings.advanced.require_email_label', 'Require players to provide their email')}
          description={t(
            'game_editor.game_settings.advanced.require_email_description_disabled',
            'Players will be asked to write their e-mail when joining',
          )}
          descriptionChecked={t(
            'game_editor.game_settings.advanced.require_email_description_enabled',
            'Players will be asked to write their e-mail when joining',
          )}
          disabled={shouldDisableField(permissions, 'emailRequired')}
        />

        {/* Require defining team member names */}
        <GameSettingsToggle
          name='advancedSettings.teamMemberNamesRequired'
          label={t(
            'game_editor.game_settings.advanced.require_team_member_names_label',
            'Require defining team member names',
          )}
          description={t(
            'game_editor.game_settings.advanced.require_team_member_names_description_disabled',
            'Players will be asked to enter team member names when joining',
          )}
          descriptionChecked={t(
            'game_editor.game_settings.advanced.require_team_member_names_description_enabled',
            'Players will be asked to enter team member names when joining',
          )}
          disabled={shouldDisableField(permissions, 'teamMemberNamesRequired')}
        />

        {user?.playerAuthUrl && (
          <>
            <GameSettingsToggle
              name='advancedSettings.strongAuthRequired'
              label={t(
                'game_editor.game_settings.advanced.require_strong_authentication',
                'Require strong authentication from players',
              )}
              description={t(
                'game_editor.game_settings.advanced.require_strong_authentication_disabled',
                'Players are not required to authenticate with SSO',
              )}
              descriptionChecked={t(
                'game_editor.game_settings.advanced.require_strong_authentication_enabled',
                'Players must authenticate with SSO to enter the game',
              )}
              disabled={shouldDisableField(permissions, 'strongAuthRequired')}
            />
            {/*<GameSettingsToggle
              name='advancedSettings.pinCodeEnabled'
              label={t('game_editor.game_settings.advanced.allow_pin_code_login_label', 'Player login with pin code')}
              description={t(
                'game_editor.game_settings.advanced.allow_pin_code_login_disabled',
                'Pin code login is not allowed',
              )}
              descriptionChecked={t(
                'game_editor.game_settings.advanced.allow_pin_code_loginn_enabled',
                'Players can login with the pin code',
              )}
              disabled={advancedSettings.strongAuthRequired || shouldDisableField(permissions, 'pinCodeEnabled')}
            />*/}
          </>
        )}
      </div>

      {/* SCORING */}
      <div className={styles.sectionContainer}>
        <span className={styles.sectionTitle}>{t('game_editor.game_settings.advanced.scoring', 'Scoring')}</span>

        {/* Play game without points */}
        <GameSettingsToggle
          name='advancedSettings.noPointsGame'
          label={t('game_editor.game_settings.advanced.no_points_game_label', 'Play game without points')}
          description={t(
            'game_editor.game_settings.advanced.no_points_game_description_disabled',
            'This will remove points from the game entirely.',
          )}
          descriptionChecked={t(
            'game_editor.game_settings.advanced.no_points_game_description_enabled',
            'This will remove points from the game entirely.',
          )}
          disabled={shouldDisableField(permissions, 'noPointsGame')}
        />

        {/* Hide scoreboard */}
        <GameSettingsToggle
          name='advancedSettings.hideScoreboard'
          label={t('game_editor.game_settings.advanced.hide_scoreboard_label', 'Hide scoreboard')}
          description={t(
            'game_editor.game_settings.advanced.hide_scoreboard_description_disabled',
            'This will hide the scoreboard from the players.',
          )}
          descriptionChecked={t(
            'game_editor.game_settings.advanced.hide_scoreboard_description_enabled',
            'This will hide the scoreboard from the players.',
          )}
          disabled={shouldDisableField(permissions, 'hideScoreboard')}
        />

        {/* Enable badges */}
        <GameSettingsToggle
          name='advancedSettings.badgesEnabled'
          label={t('game_editor.game_settings.advanced.badges_label', 'Enable badges')}
          description={t(
            'game_editor.game_settings.advanced.badges_description_disabled',
            'Players can receive badges for their participation',
          )}
          descriptionChecked={t(
            'game_editor.game_settings.advanced.badges_description_enabled',
            'Players can receive badges for their participation',
          )}
          disabled={shouldDisableField(permissions, 'badgesEnabled')}
        />
        {advancedSettings.badgesEnabled && (
          <Badges
            badges={badges}
            onClickManageBadges={onClickManageBadges}
            viewOnly={shouldDisableField(permissions, 'badgesEnabled')}
          />
        )}
      </div>

      {/* TECHNICAL */}
      <div className={styles.sectionContainer}>
        <span className={styles.sectionTitle}>{t('game_editor.game_settings.advanced.technical', 'Technical')}</span>

        {/*<GameSettingsToggle
          name='advancedSettings.askPlayerFeedback'
          label={t('game_editor.game_settings.advanced.player_feedback_label', 'Ask players for feedback')}
          description={t(
            'game_editor.game_settings.advanced.player_feedback_description_disabled',
            'This will ask players three automated questions in the middle of the game.',
          )}
          descriptionChecked={t(
            'game_editor.game_settings.advanced.player_feedback_description_enabled',
            'This will ask players three automated questions in the middle of the game.',
          )}
          disabled={shouldDisableField(permissions, 'askPlayerFeedback')}
        />*/}

        {/* Enable GPS positioning */}
        {mapType === MapType.LIVE && (
          <>
            <GameSettingsToggle
              name='advancedSettings.gpsEnabled'
              label={t('game_editor.game_settings.advanced.enable_gps_label', 'Enable GPS positioning')}
              description={t(
                'game_editor.game_settings.advanced.enable_gps_description_disabled',
                'GPS positioning of players will be used in this game.',
              )}
              descriptionChecked={t(
                'game_editor.game_settings.advanced.enable_gps_description_enabled',
                'GPS positioning of players will be used in this game.',
              )}
              disabled={shouldDisableField(permissions, 'gpsEnabled')}
            />
            <OnChange name='advancedSettings.gpsEnabled'>
              {(value, previous) => {
                if (previous && !value && hasTasksWithPositionLock) {
                  requestConfirmation({
                    title: t(
                      'game_editor.game_settings.advanced.disabling_gps_with_locked_task_positions_confirmation_title',
                      'GPS positioning needed for locking tasks by location',
                    ),
                    text: t(
                      'game_editor.game_settings.advanced.disabling_gps_with_locked_task_positions_confirmation_text',
                      'You have one or more tasks which are locked until the player reaches a certain location proximity. If you disable GPS positioning, any task which had locked position will stop having locked position. Are you sure you want to disable GPS positioning?',
                    ),
                  }).then((response) => {
                    if (!response) {
                      gpsEnabledInput.onChange(true)
                    }
                  })
                }
              }}
            </OnChange>
          </>
        )}

        {/* Hide Illustrations */}
        <GameSettingsToggle
          name='advancedSettings.hideIllustrations'
          label={t('game_editor.game_settings.advanced.hide_illustrations', 'Hide Illustrations')}
          description={t(
            'game_editor.game_settings.advanced.hide_illustrations_description_disabled',
            'This will hide visual overlays of the game. ',
          )}
          descriptionChecked={t(
            'game_editor.game_settings.advanced.hide_illustrations_description_enabled',
            'The visual overlays of the game are hidden from the players',
          )}
          disabled={shouldDisableField(permissions, 'hideIllustrations')}
        />

        {/* Hybrid offline mode */}
        <GameSettingsToggle
          name='advancedSettings.offlineMode'
          label={t('game_editor.game_settings.advanced.offline_mode', 'Hybrid offline mode')}
          description={t(
            'game_editor.game_settings.advanced.offline_mode_description_disabled',
            "Hybrid offline mode allows players to continue playing in unstable networks. Players need to reconnect to the internet at some point to sync sent answers to Seppo. The players' devices need to have sufficient memory space available as the device will preload all images at the beginning of the game",
          )}
          descriptionChecked={t(
            'game_editor.game_settings.advanced.offline_mode_description_enabled',
            "Hybrid offline mode allows players to continue playing in unstable networks. Players need to reconnect to the internet at some point to sync sent answers to Seppo. The players' devices need to have sufficient memory space available as the device will preload all images at the beginning of the game",
          )}
          disabled={shouldDisableField(permissions, 'offlineMode')}
        />

        {/* Enable public game summary link */}
        {hasPermission(user, AllowedOption.PUBLICSUMMARY) && (
          <>
            <GameSettingsToggle
              name='advancedSettings.publicLinkEnabled'
              label={t(
                'game_editor.game_settings.advanced.public_game_summary_label',
                'Enable public game summary link',
              )}
              description={t(
                'game_editor.game_settings.advanced.public_game_summary_description_disabled',
                'Generate a link you can share with others.',
              )}
              descriptionChecked={t(
                'game_editor.game_settings.advanced.public_game_summary_description_enabled',
                "Anyone with the link below can access this game's summary without having to log in to Seppo.",
              )}
              disabled={shouldDisableField(permissions, 'publicLinkEnabled')}
            />
            <Field name='advancedSettings.publicLinkSecret' component='input' type='hidden' />
            {advancedSettings.publicLinkEnabled && (
              <PublicGameLink gameId={gameId} secret={advancedSettings.publicLinkSecret} />
            )}

            <FormFieldSpy
              spyFieldName='advancedSettings.publicLinkEnabled'
              dependantFieldName='advancedSettings.publicLinkSecret'
              getShouldUpdate={() => true}
              getUpdateToValue={(fieldValue) => (fieldValue ? getRandomShareSecret() : null)}
            />
          </>
        )}
      </div>

      <FormFieldSpy
        spyFieldName='advancedSettings.explorationMode'
        dependantFieldName='advancedSettings.levelsEnabled'
        getShouldUpdate={(fieldValue) => !ignoreExplorationSpy.current && fieldValue}
        getUpdateToValue={(fieldValue) => fieldValue}
      />

      <FormFieldSpy
        spyFieldName='advancedSettings.levelsEnabled'
        dependantFieldName='advancedSettings.branchingGamePaths'
        getShouldUpdate={(fieldValue) => fieldValue}
        getUpdateToValue={(fieldValue) => (fieldValue ? !fieldValue : undefined)}
      />

      <FormFieldSpy
        spyFieldName='advancedSettings.orderingEnabled'
        dependantFieldName='advancedSettings.branchingGamePaths'
        getShouldUpdate={(fieldValue) => fieldValue}
        getUpdateToValue={(fieldValue) => (fieldValue ? !fieldValue : undefined)}
      />
    </div>
  )
}
