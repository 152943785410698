import { Editor } from '@tinymce/tinymce-react'
import { useRef } from 'react'
import { useFieldArray } from 'react-final-form-arrays'
import { useTranslation } from 'react-i18next'
import { AddEntityFieldButton } from '../../../../../../../common/components/Form/AddEntityFieldButton/AddEntityFieldButton'
import { EditorFormField } from '../../../../../../../common/components/Form/EditorFormField/EditorFormField'
import { useNotification } from '../../../../../../../contexts/NotificationContext'
import { MissingWordAnswerOption, MissingWordSubtask } from '../../../../../../../types/commonTypes'
import { noop } from '../../../../../../../util/functional'
import {
  anyMissingWordFieldRegex,
  getMissingWordFieldHtml,
  getRandomAnswerId,
  removeMissingWordFieldsFromContent,
} from '../../../../../../../util/missingWord'
import { isNullOrZero } from '../../../../../../../util/number'
import { AllAnswersSamePoints } from '../components/AllAnswersSamePoints'
import answerOptionListStyles from '../components/AnswerOptionsList.module.css'
import { MissingWordAnswerOption as MissingWordAnswerOptionComponent } from './MissingWordAnswerOption'
import styles from './MissingWordSubtaskFields.module.css'

type MissingWordSubtaskProps = {
  value: MissingWordSubtask
  name: string
  noPoints?: boolean
  viewOnly: boolean
}

const MAX_FIELDS = 10

export const MissingWordSubtaskFields: React.FC<MissingWordSubtaskProps> = ({ value, name, noPoints, viewOnly }) => {
  const { t } = useTranslation()
  const { notifyWarning } = useNotification()

  const editorRef = useRef<Editor>(null)
  const { fields } = useFieldArray<MissingWordAnswerOption>(`${name}.data.answers`)

  const refreshFields = () => {
    editorRef.current?.editor?.dom
      .select('input[class="form-control added_field_in_tinymice_editor"]')
      ?.forEach((node, index) => {
        if (node.getAttribute('id') !== `field_${index + 1}`) {
          node.setAttribute('id', `field_${index + 1}`)
        }
        if (!node.getAttribute('placeholder')?.endsWith(`-${index + 1}`)) {
          const prefix = node.getAttribute('placeholder')?.split('-')[0]
          node.setAttribute('placeholder', `${prefix}-${index + 1}`)
        }
      })
  }

  const removeAnyManuallyDeletedFields = () => {
    const editorContent = editorRef.current?.editor
      ?.getContent()
      .replace(editorRef.current?.editor?.selection.getContent(), '')
    const fieldsInEditor = (editorContent ?? '').match(anyMissingWordFieldRegex) || []
    const fieldsInEditorString = fieldsInEditor.join(':')
    if (fields.length != null && fields.length > fieldsInEditor.length) {
      const extraIndices =
        fieldsInEditor.length === 0
          ? fields.map((_, index) => index)
          : fields.map((_, index) => index).filter((index) => !fieldsInEditorString.includes(`field_${index + 1}`))
      if (extraIndices.length > 0) {
        if (extraIndices.length !== fields.length) {
          refreshFields()
        }
        //removeBatch exists but is not typed
        ;(fields as any).removeBatch(extraIndices)
      }
    }
  }

  const onKeyUp = (e: any) => {
    if (e.code === 'Backspace' || e.code === 'Delete') {
      removeAnyManuallyDeletedFields()
    }
  }

  const syncFieldsStateWithEditor = () => {
    const fieldNodes =
      editorRef.current?.editor?.dom.select('input[class="form-control added_field_in_tinymice_editor"]') ?? []
    if (isNullOrZero(fieldNodes.length) && !isNullOrZero(fields.length)) {
      ;(fields as any).removeBatch(fields.map((_, index) => index))
    } else if (fields.length != null && fields.length !== fieldNodes?.length) {
      const newAnswers = fieldNodes.reduce((answers: MissingWordAnswerOption[], node) => {
        const ux3id = node.getAttribute('data-ux3id')!
        return [
          ...answers,
          {
            word: (fields.value || []).find((val) => val.ux3id === ux3id)?.word || '',
            ux3id: ux3id,
          },
        ]
      }, [])
      ;(fields as any).removeBatch(fields.map((_, index) => index))
      newAnswers.forEach((newAnswer) => fields.push(newAnswer))
      refreshFields()
    }
  }

  const notifyFieldsExceeded = () => {
    notifyWarning({
      title: t('game_editor.tasks.missing_word.max_fields_reached_warning.title', 'Can not add more fields'),
      content: t('game_editor.tasks.missing_word.max_fields_reached_warning.content', {
        max_fields: MAX_FIELDS,
        defaultValue: 'The maximum allowed number of fields is %{max_fields}',
      }),
    })
  }

  const handleAddMissingWordField = () => {
    editorRef.current?.editor?.selection.collapse()
    const nextIndex = (editorRef.current?.editor?.getContent().match(anyMissingWordFieldRegex) || []).length + 1
    if (nextIndex > MAX_FIELDS) {
      notifyFieldsExceeded()
    } else {
      const ux3id = getRandomAnswerId()
      const newFieldHtml = getMissingWordFieldHtml(
        nextIndex,
        ux3id,
        t('game_editor.tasks.missing_word.missing_word_field_placeholder', 'Word'),
      )
      editorRef.current?.editor?.insertContent(newFieldHtml)
      const positionOfId = editorRef.current?.editor?.getContent().indexOf(ux3id)
      const fieldsCountBeforeNewField = (
        editorRef.current?.editor?.getContent().slice(0, positionOfId)?.match(anyMissingWordFieldRegex) || []
      ).length
      fields.insert(fieldsCountBeforeNewField, { ux3id, word: '' })
      if (nextIndex - 1 !== fieldsCountBeforeNewField) {
        refreshFields()
      }
    }
  }

  const handleRemoveMissingWordField = (index: number) => {
    editorRef.current?.editor?.dom.select(`input[id='field_${index + 1}']`)?.[0]?.remove()
    if ((fields.length ?? 0) - 1 > index) {
      refreshFields()
    }
    fields.remove(index)
  }

  return (
    <>
      <EditorFormField
        ref={editorRef}
        key={`${name}_editor`}
        name={`${name}.description`}
        label={t('game_editor.tasks.task_description_accessibility_label', 'Describe the task')}
        srOnlyLabel
        allowInputElements
        onKeyUp={onKeyUp}
        onCut={removeAnyManuallyDeletedFields}
        onUndo={syncFieldsStateWithEditor}
        onRedo={syncFieldsStateWithEditor}
        initProps={{
          paste_preprocess(_, args) {
            args.content = removeMissingWordFieldsFromContent(args.content) // prevent pasting missing word fields
          },
          content_style:
            'input { border-radius: 9px; border: 1px solid #D4D4D4; padding: 4px 16px; font-family: "Montserrat", sans-serif; }',
        }}
        disabled={viewOnly}
      />
      <div className={answerOptionListStyles.answersContainer}>
        <AddEntityFieldButton disabled={viewOnly} onClick={viewOnly ? noop : handleAddMissingWordField}>
          {t('game_editor.tasks.missing_word.add_missing_word_button', 'Add missing word')}
        </AddEntityFieldButton>
        <div className={styles.defineAnswersInfo}>
          <h5>{t('game_editor.tasks.missing_word.define_answers_title', 'Define answers')}</h5>
          <p>
            {t(
              'game_editor.tasks.missing_word.define_answers_info',
              'Separate accepted answers with a semicolon ; e.g. 1918; -18; 18 To allow anything to be an accepted answer, write: ***',
            )}
          </p>
        </div>
        {isNullOrZero(fields.length) && (
          <div className={styles.noMissingWordsContainer}>
            {t('game_editor.tasks.missing_word.no_missing_words_yet', 'No missing words yet')}
          </div>
        )}
        {!noPoints && !isNullOrZero(fields.length) && (
          <AllAnswersSamePoints viewOnly={viewOnly} name={name} isChecked={value.data.allAnswersHaveSamePoints} />
        )}
        {fields.map((name, index) => (
          <MissingWordAnswerOptionComponent
            key={`${name}_${fields.value[index].ux3id}`}
            index={index}
            onDelete={viewOnly ? noop : () => handleRemoveMissingWordField(index)}
            name={name}
            noPoints={noPoints}
            hasFixedPoints={value.data.allAnswersHaveSamePoints}
            fixedPoints={value.data.allAnswersPoints}
            viewOnly={viewOnly}
          />
        ))}
      </div>
    </>
  )
}
