import classNames from 'classnames'
import { getIcon, IconName } from '../../../../../common/components/icons/utils'
import { IconTooltip } from '../../../../../common/components/tooltip/IconTooltip'
import { noop } from '../../../../../util/functional'
import { safeIsNullOrEmpty } from '../../../../../util/string'
import styles from './TaskModal.module.css'
import { PointsPill } from '../../../../../common/components/PointsPill/PointsPill'

type AddSubtaskItemProps = {
  icon: IconName
  title: string
  description: string
  tooltipContent?: string
  onClick: () => void
  className?: string
  disabled?: boolean
  isNew?: boolean
}

export const AddSubtaskItem: React.FC<AddSubtaskItemProps> = ({
  icon,
  title,
  description,
  tooltipContent,
  onClick,
  className,
  disabled,
  isNew,
}) => {
  return (
    <button
      type='button'
      disabled={disabled}
      onClick={disabled ? noop : onClick}
      className={classNames(styles.addSubtaskButton, className)}
    >
      {isNew && (
        <div className={styles.addSubtaskNew}>
          <PointsPill noIcon size='small' label='NEW' />
        </div>
      )}
      {!safeIsNullOrEmpty(tooltipContent) && (
        <div className={styles.addSubtaskTooltip}>
          <IconTooltip tooltipContent={tooltipContent!} iconName='info' closeOnScroll />
        </div>
      )}
      <span className={styles.addSubtaskButtonIcon}>{getIcon(icon)}</span>
      <div>{title}</div>
      <div className={styles.addSubtaskButtonDescription}>{description}</div>
    </button>
  )
}
