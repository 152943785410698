export enum BrowserType {
  Firefox = 'firefox',
  Safari = 'safari',
  Chrome = 'chrome',
}

export const isFirefox = () => {
  const { userAgent } = window.navigator
  return userAgent.toLowerCase().includes(BrowserType.Firefox)
}

export const isSafari = () => {
  const { userAgent } = window.navigator
  return userAgent.toLowerCase().includes(BrowserType.Safari) && !userAgent.toLowerCase().includes(BrowserType.Chrome)
}

export const isMobileDevice = () => {
  return ['iPhone', 'iPad', 'Android'].indexOf(navigator.platform) > -1 || navigator.userAgent.indexOf('Android') > -1
}
