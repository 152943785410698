import 'leaflet/dist/leaflet.css'
import { ReactElement } from 'react'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import { ErrorPage } from './common/components/error/ErrorPage'
import { BusinessSettingsWrapper } from './composites/layout/BusinessSettingsWrapper'
import { HelpMenuProvider } from './contexts/HelpMenuContextProvider'
import { NotificationProvider } from './contexts/NotificationContext'
import { ThemeProvider } from './contexts/ThemeContext'
import { UserProvider } from './contexts/userContext'
import { NoNavbarLayout } from './navigation/NoNavbarLayout'
import { Protected } from './navigation/Protected'
import { RootLayout } from './navigation/RootLayout'
import { Community } from './pages/community/Community'
import { Dashboard } from './pages/dashboard/Dashboard'
import { GameEditorPage } from './pages/GameEditor/GameEditorPage'
import { Login } from './pages/login/Login'
import { TrialLogin } from './pages/login/TrialLogin'
import { OnboardingWizard } from './pages/OnboardingWizard/OnboardingWizard'
import { OrgLibrary } from './pages/OrgLibrary/OrgLibrary'
import { Reports } from './pages/reports/Reports'
import { Search } from './pages/search/Search'
import { BillingSettings } from './pages/settings/billing/BillingSettings'
import { GeneralSettings } from './pages/settings/general/GeneralSettings'
import { LicenseHolderSettings } from './pages/settings/organization/LicenseHolderSettings'
import { MembersSettings } from './pages/settings/organization/MembersSettings'
import { OrganizationSettings } from './pages/settings/organization/OrganizationSettings'
import { PlayersSettings } from './pages/settings/organization/PlayersSettings'
import { Templates } from './pages/templates/Templates'
import { Engine } from './pages/engine/Engine'
import { GamePackagesPage } from './pages/GamePackages/GamePackagesPage'
import { routes } from './routes'
import { CombineComponents } from './util/combineComponents'
import { PasswordReset } from './pages/login/PasswordReset'
import { SponsoredShopPage } from './pages/sponsored/SponsoredShopPage'
import { ShareLoginInfoPage } from './pages/ShareLoginInfo/ShareLoginInfoPage'
import { InstructorSponsoredPage } from './pages/sponsored/InstructorSponsoredPage'
import { ConfirmationContextProvider } from './contexts/ConfirmationContext'
import { SponsoredTop10Page } from './pages/sponsoredTop10/SponsoredTop10Page'
import { SponsoredGamesDataProvider } from './contexts/SponsoredGamesContextProvider'
import { UnsupportedBrowser } from './pages/unsupported/UnsupportedBrowser'

const protect = (component: ReactElement) => <Protected>{component}</Protected>

const router = createBrowserRouter([
  {
    path: '/',
    //errorElement: <ErrorPage />,
    children: [
      {
        element: protect(<NoNavbarLayout />),
        children: [
          {
            element: <RootLayout />,
            children: [
              { index: true, element: <Dashboard /> },
              { path: routes.reports, element: <Reports /> },
              { path: routes.orgLibrary, element: <OrgLibrary /> },
              { path: routes.community, element: <Community /> },
              {
                path: routes.sponsoredTop10,
                element: (
                  <SponsoredGamesDataProvider top10Mode={true}>
                    <SponsoredTop10Page />
                  </SponsoredGamesDataProvider>
                ),
              },
              { path: routes.sponsored, element: <InstructorSponsoredPage /> },
              { path: routes.templates, element: <Templates /> },
              { path: routes.gamePackages, element: <GamePackagesPage /> },
              { path: routes.gamePackageWithId, element: <GamePackagesPage /> },
              { path: routes.search, element: <Search /> },
              { path: routes.generalSettings, element: <GeneralSettings /> },
              {
                element: <BusinessSettingsWrapper />,
                children: [
                  { path: routes.billingSettings, element: <BillingSettings /> },
                  { path: routes.organizationSettings, element: <OrganizationSettings /> },
                  { path: routes.membersSettings, element: <MembersSettings /> },
                  { path: routes.playersSettings, element: <PlayersSettings /> },
                  { path: routes.licenseHolderSettings, element: <LicenseHolderSettings /> },
                ],
              },
            ],
          },
          { path: routes.onboardingWizard, element: <OnboardingWizard /> },
          { path: routes.gameEditor, element: <GameEditorPage /> },
          { path: routes.gameEditorWithId, element: <GameEditorPage /> },
          { path: routes.shareLoginInfo, element: <ShareLoginInfoPage /> },
        ],
      },
    ],
  },
  {
    path: routes.login,
    errorElement: <ErrorPage />,
    element: (
      <NotificationProvider>
        <Login />
      </NotificationProvider>
    ),
  },
  {
    path: routes.trialLogin,
    errorElement: <ErrorPage />,
    element: (
      <NotificationProvider>
        <TrialLogin />
      </NotificationProvider>
    ),
  },
  {
    path: routes.passwordChange,
    element: (
      <NotificationProvider>
        <PasswordReset />
      </NotificationProvider>
    ),
  },
  {
    path: routes.passwordChangeWithToken,
    element: (
      <NotificationProvider>
        <PasswordReset />
      </NotificationProvider>
    ),
  },
  {
    path: routes.webshopSponsoredGames,
    errorElement: <div>error</div>,
    element: (
      <UserProvider>
        <SponsoredShopPage />
      </UserProvider>
    ),
  },
  {
    path: routes.engine,
    element: (
      <UserProvider>
        <ConfirmationContextProvider>
          <NotificationProvider>
            <Engine />
          </NotificationProvider>
        </ConfirmationContextProvider>
      </UserProvider>
    ),
  },
  {
    path: routes.unsupportedBrowser,
    element: <UnsupportedBrowser />,
  },
])

function App() {
  return (
    <>
      <CombineComponents components={[ThemeProvider, UserProvider, HelpMenuProvider]}>
        <RouterProvider router={router} />
      </CombineComponents>
    </>
  )
}

export default App
