import React, { useEffect, useState } from 'react'

import { useTranslation } from 'react-i18next'
import { BrowseGamesCard } from '../../../composites/GamesOverview/components/card/BrowseGamesCard'
import { Card } from '../../../composites/GamesOverview/components/card/Card'
import { SkeletonGameCard } from '../../../composites/GamesOverview/components/card/SkeletonGameCard'
import { GamePreviewModal } from '../../../composites/GamePreviewModal/GamePreviewModal'
import { getFocusableGameItemId } from '../../../composites/GamesOverview/helpers'
import { useGameData } from '../../../contexts/CommunityGamesContextProvider'
import { routes } from '../../../routes'
import { GameAction, TGameCard } from '../../../types/commonTypes'
import styles from '../OnboardingWizard.module.css'
import { EditorPreference, setEditorPreference } from '../../../util/editorPreference'

export type SuggestionStepProps = {
  games: TGameCard[]
  hasTemplates?: boolean
}

export const SuggestionStep: React.FC<SuggestionStepProps> = ({ games, hasTemplates }) => {
  const { importGame } = useGameData()
  const { t } = useTranslation()

  const [gameBeingPreviewed, setGameBeingPreviewed] = useState<TGameCard>()

  const handleGameAction = (action: GameAction, game: TGameCard) => {
    if (action === GameAction.IMPORT) {
      importGame(parseInt(game.id), game.gameName)
    } else if (action === GameAction.VIEW) {
      setGameBeingPreviewed(game)
    } else {
      console.warn(`Unsupported action in suggestion step: ${action}`)
    }
  }

  useEffect(() => {
    setEditorPreference(EditorPreference.NEW)
  }, [])

  const handleClosePreviewModal = () => {
    if (gameBeingPreviewed == null) {
      return
    }
    const gameId = gameBeingPreviewed.id
    setGameBeingPreviewed(undefined)
    document.querySelector<any>(`#${getFocusableGameItemId(gameId)}`)?.focus?.()
  }

  return (
    <div className={styles.suggestionStepContainer}>
      <h2>{t('onboarding_wizard.suggestion_step.title', 'Create your first game')}</h2>
      <h5>
        {t(
          'onboarding_wizard.suggestion_step.subtitle',
          'Based on your answers, we suggest the following games to start with',
        )}
      </h5>
      <div className={styles.suggestionStepGridContainer}>
        {games.map((game) => (
          <Card key={game.id} game={game} onGameAction={handleGameAction} libraryMode={true} />
        ))}
        {gameBeingPreviewed != null && (
          <GamePreviewModal
            game={gameBeingPreviewed}
            onClose={handleClosePreviewModal}
            onGameAction={handleGameAction}
          />
        )}
        {games.length < 1 && <SkeletonGameCard />}
        <BrowseGamesCard
          label={
            hasTemplates
              ? t('onboarding_wizard.suggestion_step.browse_template_games', 'Browse templates for more games')
              : t('onboarding_wizard.suggestion_step.browse_community_games', 'Browse community for more games')
          }
          route={hasTemplates ? routes.templates : routes.community}
        />
      </div>
      <br />
      <div className={styles.suggestionStepContainerOr}>{t('common.or', 'or')}</div>
    </div>
  )
}
