import classNames from 'classnames'
import { TFunction } from 'i18next'
import React, { useCallback, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Button } from '../../common/components/button/Button'
import { getIcon } from '../../common/components/icons/utils'
import shared from '../../common/styles/shared.module.css'
import { HELP_MENU_CONTROL_ID, useHelpMenu } from '../../contexts/HelpMenuContextProvider'
import { useTheme } from '../../contexts/ThemeContext'
import { useUser } from '../../contexts/userContext'
import { useGameEditorNavigation } from '../../hooks/useGameEditorNavigation'
import { routes } from '../../routes'
import { TUser } from '../../types/commonTypes'
import styles from './Sidebar.module.css'
import { SidebarLink } from './components/SidebarLink'
import { SidebarSubLink, SidebarSubLinks } from './components/SidebarSubLinks'
import { GamePackageSubLinks } from './components/GamePackageSubLinks'
import { SEPPO_GROUPING_BUSINESS } from '../../api/typeConverters'
import { SponsoredSubLinks } from './components/SponsoredSubLinks'
import { preventUserCreateGame } from '../../util/game'
import { PopupActionMenuItem } from '../../common/components/PopupMenu/PopupActionMenu'
import useOnClickOutside from 'use-onclickoutside'

const getSettingsSubLinks = (user: TUser | null, t: TFunction) => {
  const sublinks = [{ title: t('side_bar.settings_general', 'General'), to: routes.generalSettings }]
  if (user && !user.isSponsoredUser && user?.activeBusiness.industry !== SEPPO_GROUPING_BUSINESS) {
    sublinks.push({ title: t('side_bar.settings_organization', 'Organisation'), to: routes.organizationSettings })
  }
  if (user?.isBusinessAdmin && user.hasNewPaymentInfo) {
    sublinks.push({ title: t('side_bar.settings_billing', 'Billing'), to: routes.billingSettings })
  }
  return sublinks
}

const buttonId = 'more-button-id'

export const Sidebar: React.FC = () => {
  const { user } = useUser()
  const { t } = useTranslation()
  const { toggleDisplayMenu } = useHelpMenu()
  const navigate = useNavigate()
  const { logoUrl } = useTheme()
  const { goToCreateGame, createGameWithAi } = useGameEditorNavigation()
  const [isMoreMenuOpen, setIsMoreMenuOpen] = useState(false)
  const noSaigeRoundsLeft = (user?.engineRounds ?? 0) < 1 || preventUserCreateGame(user)

  const handleClickCreateGame = async () => {
    await goToCreateGame()
  }

  const handleClickCreateWithAi = async () => {
    await createGameWithAi()
  }

  const handleToggleNewMoreMenuOpen = useCallback(() => {
    setIsMoreMenuOpen((prev) => !prev)
  }, [])

  const handleClickReadMore = useCallback(() => {
    window.open('https://seppo.io/manual/', '_blank')
  }, [])

  const menuRef = useRef<HTMLDivElement>(null)
  useOnClickOutside(menuRef, (e) => {
    if ((e.target as any)?.getAttribute?.('id') !== buttonId) {
      setIsMoreMenuOpen(false)
    }
  })

  const settingsSubLinks = getSettingsSubLinks(user, t)

  const gamePackagesSubLinks: SidebarSubLink[] =
    user?.gamePackages?.map((gamePackage) => {
      return {
        title: gamePackage.name ?? t('game_package_page.title_if_missing', 'Unnamed package'),
        to: routes.createGamePackageWithId(gamePackage.id),
      }
    }) ?? []

  const sponsoredGamesSubLinks: SidebarSubLink[] = [
    { title: t('side_bar.all_sponsored', 'All Sponsored'), to: routes.sponsored },
    { title: t('side_bar.sponsored_top_10', 'Top 10 Games'), to: routes.sponsoredTop10 },
  ]
  return (
    <nav className={styles.container}>
      <div className={styles.stickyContainer}>
        <div className={styles.topBorderContainer}>
          <div className={styles.topBorder} />
        </div>
        <div className={styles.sidebarContent}>
          <div className={styles.seppoImg} onClick={() => navigate(routes.homepage)}>
            <img width={90} src={logoUrl} alt='logo' />
          </div>
          {user &&
            user.termsAccepted &&
            !user.isSponsoredUser &&
            user.activeBusiness.industry !== SEPPO_GROUPING_BUSINESS && (
              <div className={styles.createButtons}>
                <div className={classNames(shared.flex, shared.mAxisC)}>
                  <Button variant='outline-tiny' className={styles.splitButtonLeftSide} onClick={handleClickCreateGame}>
                    <span className='iconWrapperMedium'>{getIcon('plus')}</span>
                    {t('new_game_button', 'New game')}
                  </Button>
                  <Button variant='tiny' className={styles.splitButtonRightSide} onClick={handleToggleNewMoreMenuOpen}>
                    <div className={classNames('iconWrapperMedium', styles.whiteIcon)}>{getIcon('more')}</div>
                  </Button>
                </div>
                {isMoreMenuOpen && (
                  <div className={styles.moreMenuContainer} ref={menuRef}>
                    {user.hasSaigeAccess && (
                      <PopupActionMenuItem
                        icon='seppoAiIcon'
                        text={t('ai_game_button', 'Create with AI')}
                        onClick={handleClickCreateWithAi}
                        disabled={noSaigeRoundsLeft}
                      />
                    )}
                    <PopupActionMenuItem icon='help' text={t('open_seppo_guide', 'Seppo guide')} onClick={handleClickReadMore} />
                  </div>
                )}
              </div>
            )}
          <div className={styles.sidebarLinksContainer}>
            <ul className={styles.linksList}>
              {user && user.termsAccepted && (
                <>
                  <SidebarLink to={routes.homepage} title={t('side_bar.dashboard', 'Dashboard')} icon={'exercisePin'} />
                  <SidebarLink to={routes.reports} title={t('side_bar.reports', 'Reports')} icon={'reports'} />
                  {user.hasOrgLibrary && (
                    <SidebarLink
                      to={routes.orgLibrary}
                      title={t('side_bar.org_library', 'Org Library')}
                      icon={'orgLibrary'}
                    />
                  )}
                  {user.hasCommunity && (
                    <SidebarLink
                      to={routes.community}
                      title={t('side_bar.community', 'Community')}
                      icon={'community'}
                    />
                  )}
                  {(user.hasCommunity || user.isSponsoredUser) && (
                    <SponsoredSubLinks
                      to={routes.sponsored}
                      title={t('side_bar.sponsored', 'Sponsored')}
                      icon={'sponsored'}
                      subLinks={sponsoredGamesSubLinks}
                    />
                  )}
                  {user.hasTemplates && (
                    <SidebarLink
                      to={routes.templates}
                      title={t('side_bar.templates', 'Templates')}
                      icon={'community'}
                    />
                  )}
                  {user.gamePackages && (
                    <GamePackageSubLinks
                      to={routes.createGamePackageWithId(user?.gamePackages?.[0].id)}
                      title={t('side_bar.game_packages', 'Library')}
                      icon={'community'}
                      subLinks={gamePackagesSubLinks}
                    />
                  )}
                  {settingsSubLinks.length === 1 ? (
                    <SidebarLink
                      to={routes.generalSettings}
                      title={t('side_bar.settings', 'Settings')}
                      icon='settings'
                    />
                  ) : (
                    <SidebarSubLinks
                      icon='settings'
                      to={routes.generalSettings}
                      title={t('side_bar.settings', 'Settings')}
                      subLinks={settingsSubLinks}
                    />
                  )}
                  <li>
                    <button
                      className={classNames(styles.sidebarHelpButton, styles.sidebarLink)}
                      onClick={toggleDisplayMenu}
                      id={`${HELP_MENU_CONTROL_ID}_sidebarButton`}
                    >
                      <span className={styles.iconWrapper}>{getIcon('help')}</span>
                      <span className={styles.linkTitle}>{t('side_bar.help', 'Help')}</span>
                    </button>
                  </li>
                </>
              )}
            </ul>
          </div>
        </div>
      </div>
    </nav>
  )
}
