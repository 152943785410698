import { ApiResponse, Try, toFailure, toSuccess } from '../types/commonTypes'
import { getRandomTempId } from '../util/functional'
import { createAxios } from './axios'
import {
  ActivateSponsoredGameParams,
  AddPlayerResponse,
  AddResponse,
  AddStatusResponse,
  CannedMessagesResponse,
  CheckGameOrPlayerResponse,
  CloneLibraryGameParams,
  CopyOwnGameParams,
  CreateBadgeParams,
  CreateBadgeResponse,
  CreateGameParams,
  DeleteBadgeResponse,
  DeleteCannedMessageParams,
  DeleteOwnGameParams,
  DeletePersonResponse,
  EmailParams,
  EvaluatorAddParams,
  Exercise,
  GameLibraryShareParams,
  GamePackageResponse,
  GetCombinedSearchParams,
  GetCombinedSearchResponse,
  GetExerciseAnswerParams,
  GetGamePackageParams,
  GetGameParams,
  GetGamePartialCSVResponse,
  GetGameResponse,
  GetGamesResponse,
  GetLibraryGamesParams,
  GetLibraryGamesResponse,
  GetOwnGameCreatorsResponse,
  GetOwnGamesParams,
  GetScoreboardParams,
  GetScoreboardResponse,
  GetSponsoredActivation,
  GetSponsoredGamesParams,
  GetTaskParams,
  GetTaskResponse,
  GetWordPressDataResponse,
  InstructorAddParams,
  LibraryGameDeleteResponse,
  LibraryGameDetailsResponse,
  LibraryLanguage,
  ManageLineResponse,
  MapStructure,
  OpenGameParams,
  PostCannedMessageParams,
  StudentAddParams,
  UpdateFlashTasksOrderParams,
  UpdateGameParams,
  UpdateTasksOrderParams,
  UpdateTasksOrderResponse,
} from './gameTypes'
import { TEST_USER_AUTHORIZATION } from './userApiService'

export const PAGE_SIZE = 10
export async function getGame({
  authorization = TEST_USER_AUTHORIZATION,
  gameId,
  isLibrary,
  includeExercises = true,
}: GetGameParams): Promise<Try<GetGameResponse>> {
  try {
    const url = isLibrary ? `/admin/games/conlib_game.json?conlib_game_id=${gameId}` : `/games/${gameId}`
    const response = await createAxios(authorization).get<GetGameResponse>(url, {
      headers: {
        //Backend knows by this to include Explore tasks
        'X-Seppo-Browser': true,
      },
      params: {
        include_exercises: includeExercises,
        no_hidden_answers: true,
      },
    })

    const { data } = response

    return toSuccess(data)
  } catch (e: any) {
    return toFailure(e)
  }
}

export async function getLibraryGameBySeppoId({
  authorization = TEST_USER_AUTHORIZATION,
  gameId,
}: GetGameParams): Promise<Try<LibraryGameDetailsResponse>> {
  try {
    const url = `/admin/games/find_library_game_by_seppo_game_id.json?game_id=${gameId}`
    const response = await createAxios(authorization).get<LibraryGameDetailsResponse>(url, {
      params: {
        _: getRandomTempId(),
      },
    })

    const { data } = response

    return toSuccess(data)
  } catch (e: any) {
    return toFailure(e)
  }
}

export async function getCannedUserMessagesApi({
  authorization = TEST_USER_AUTHORIZATION,
  gameId,
}: GetGameParams): Promise<Try<CannedMessagesResponse>> {
  try {
    const url = `/admin/games/${gameId}/user_canned_messages.json`
    const response = await createAxios(authorization).get<CannedMessagesResponse>(url, {})

    const { data } = response

    return toSuccess(data)
  } catch (e: any) {
    return toFailure(e)
  }
}

export async function deleteCannedUserMessageApi({
  gameId,
  messageId,
  authorization = TEST_USER_AUTHORIZATION,
}: DeleteCannedMessageParams): Promise<Try<CannedMessagesResponse>> {
  try {
    const url = `/admin/games/${gameId}/delete_user_canned_message.json`
    const response = await createAxios(authorization).post<CannedMessagesResponse>(url, {
      id: messageId,
    })

    const { data } = response

    return toSuccess(data)
  } catch (e: any) {
    return toFailure(e)
  }
}

export async function postToCannedUserMessagesApi({
  gameId,
  messageId,
  messageText,
  authorization = TEST_USER_AUTHORIZATION,
}: PostCannedMessageParams): Promise<Try<CannedMessagesResponse>> {
  try {
    const url = `/admin/games/${gameId}/save_user_canned_messages.json`
    const response = await createAxios(authorization).post<CannedMessagesResponse>(url, {
      id: messageId,
      text: messageText,
    })

    const { data } = response

    return toSuccess(data)
  } catch (e: any) {
    return toFailure(e)
  }
}

export async function getCannedGameMessagesApi({
  authorization = TEST_USER_AUTHORIZATION,
  gameId,
}: GetGameParams): Promise<Try<CannedMessagesResponse>> {
  try {
    const url = `/admin/games/${gameId}/game_canned_messages.json`
    const response = await createAxios(authorization).get<CannedMessagesResponse>(url, {})

    const { data } = response

    return toSuccess(data)
  } catch (e: any) {
    return toFailure(e)
  }
}

export async function deleteCannedGameMessageApi({
  gameId,
  messageId,
  authorization = TEST_USER_AUTHORIZATION,
}: DeleteCannedMessageParams): Promise<Try<CannedMessagesResponse>> {
  try {
    const url = `/admin/games/${gameId}/delete_game_canned_message.json`
    const response = await createAxios(authorization).post<CannedMessagesResponse>(url, {
      id: messageId,
    })

    const { data } = response

    return toSuccess(data)
  } catch (e: any) {
    return toFailure(e)
  }
}

export async function postToCannedGameMessagesApi({
  gameId,
  messageId,
  messageText,
  authorization = TEST_USER_AUTHORIZATION,
}: PostCannedMessageParams): Promise<Try<CannedMessagesResponse>> {
  try {
    const url = `/admin/games/${gameId}/save_game_canned_messages.json`
    const response = await createAxios(authorization).post<CannedMessagesResponse>(url, {
      id: messageId,
      text: messageText,
    })

    const { data } = response

    return toSuccess(data)
  } catch (e: any) {
    return toFailure(e)
  }
}

export async function getGamePackage({
  authorization = TEST_USER_AUTHORIZATION,
  packageId,
}: GetGamePackageParams): Promise<Try<GamePackageResponse>> {
  try {
    const url = `/admin/games/library_search_game_package.json?ux3=true&game_package_id=${packageId}`
    const response = await createAxios(authorization).get<GamePackageResponse>(url, {})

    const { data } = response

    return toSuccess(data)
  } catch (e: any) {
    return toFailure(e)
  }
}

export async function postToGameLibrary(
  shareParams: GameLibraryShareParams,
  authorization = TEST_USER_AUTHORIZATION,
): Promise<Try<GetGameResponse>> {
  const { game_id } = shareParams
  try {
    const url = `/admin/games/${game_id}/post_to_library.json`
    const response = await createAxios(authorization).post<GetGameResponse>(url, {
      ...shareParams,
    })

    const { data } = response

    return toSuccess(data)
  } catch (e: any) {
    return toFailure(e)
  }
}

export async function deleteFromGameLibrary(
  gameId: number,
  authorization = TEST_USER_AUTHORIZATION,
): Promise<Try<LibraryGameDeleteResponse>> {
  try {
    const url = `/admin/games/${gameId}/delete_library_game.json`
    const response = await createAxios(authorization).post<LibraryGameDeleteResponse>(url, {
      delete_exercises: false,
    })

    const { data } = response

    return toSuccess(data)
  } catch (e: any) {
    return toFailure(e)
  }
}

export async function getGamePeopleCSV({
  authorization = TEST_USER_AUTHORIZATION,
  gameId,
}: GetGameParams): Promise<Try<GetGamePartialCSVResponse>> {
  try {
    const url = `/games/${gameId}/get_people_csv`
    const response = await createAxios(authorization).get<GetGamePartialCSVResponse>(url, {
      params: {
        id: gameId,
      },
    })

    const { data } = response

    return toSuccess(data)
  } catch (e: any) {
    return toFailure(e)
  }
}

export async function getGameExercisesCSV({
  authorization = TEST_USER_AUTHORIZATION,
  gameId,
}: GetGameParams): Promise<Try<GetGamePartialCSVResponse>> {
  try {
    const url = `/admin/games/${gameId}/get_exercises_csvjson`
    const response = await createAxios(authorization).get<GetGamePartialCSVResponse>(url, {
      params: {
        id: gameId,
      },
    })

    const { data } = response

    return toSuccess(data)
  } catch (e: any) {
    return toFailure(e)
  }
}

export async function getGameBranchConnectionsCSV({
  authorization = TEST_USER_AUTHORIZATION,
  gameId,
}: GetGameParams): Promise<Try<GetGamePartialCSVResponse>> {
  try {
    const url = `/admin/games/${gameId}/get_connections_csv`
    const response = await createAxios(authorization).get<GetGamePartialCSVResponse>(url, {
      params: {
        id: gameId,
      },
    })

    const { data } = response

    return toSuccess(data)
  } catch (e: any) {
    return toFailure(e)
  }
}

export async function getExerciseAnswersCSV({
  authorization = TEST_USER_AUTHORIZATION,
  gameId,
  exerciseId,
}: GetExerciseAnswerParams): Promise<Try<GetGamePartialCSVResponse>> {
  try {
    const url = `/admin/games/${gameId}/exercises/${exerciseId}/get_answers_csv`

    const response = await createAxios(authorization).get<GetGamePartialCSVResponse>(url, {
      params: {
        id: exerciseId,
      },
    })

    const { data } = response

    return toSuccess(data)
  } catch (e: any) {
    return toFailure(e)
  }
}

export async function getCombinedSearch({
  authorization = TEST_USER_AUTHORIZATION,
  search,
  subject,
  language,
  age,
  updatedAt,
  approvedBySeppo,
  source,
  perPage = 10,
  page = 1,
  orderBy = 'updated_at desc',
}: GetCombinedSearchParams): Promise<Try<GetCombinedSearchResponse>> {
  try {
    const response = await createAxios(authorization).get<GetCombinedSearchResponse>(
      '/admin/games/combined_search.json',
      {
        params: {
          search: search,
          order_by: orderBy,
          per_page: perPage,
          page: page,
          not_in_package: true,
          ...(subject && { subject }),
          ...(language && { language }),
          ...(age && { age }),
          ...(updatedAt && { updated_at: updatedAt }),
          ...(approvedBySeppo && { approved_by_seppo: true }),
          ...(source && { source }),
        },
      },
    )

    const { data } = response

    return toSuccess(data)
  } catch (e: any) {
    return toFailure(e)
  }
}

export const getOwnGamesOwners = async (): Promise<Try<GetOwnGameCreatorsResponse>> => {
  try {
    const response = await createAxios().get<GetOwnGameCreatorsResponse>('/users/own_games_owners.json')
    const { data } = response
    return toSuccess(data)
  } catch (e: any) {
    return toFailure(e)
  }
}

export async function getOwnGames({
  authorization = TEST_USER_AUTHORIZATION,
  userId,
  perPage = 10,
  page = 1,
  orderBy = 'updated_at desc',
  filters,
}: GetOwnGamesParams): Promise<Try<GetGamesResponse>> {
  try {
    const response = await createAxios(authorization).get<GetGamesResponse>(`/users/${userId}`, {
      params: {
        order_by: orderBy,
        per_page: perPage,
        page: page,
        ux3: true,
        ...(filters?.age && { age: filters.age }),
        ...(filters?.creator && { creator_id: filters.creator }),
        ...(filters?.language && { language: filters.language }),
        ...(filters?.status && { status: filters.status }),
        ...(filters?.subject && { subject: filters.subject }),
        ...(filters?.updatedAtFrom && { date_from: filters.updatedAtFrom }),
        ...(filters?.updatedAtUntil && { date_until: filters.updatedAtUntil }),
      },
    })

    const { data } = response

    return toSuccess(data)
  } catch (e: any) {
    return toFailure(e)
  }
}

export async function getPrivateLibraryGames({
  authorization = TEST_USER_AUTHORIZATION,
  perPage = 10,
  page = 1,
  filters,
}: GetOwnGamesParams): Promise<Try<GetLibraryGamesResponse>> {
  try {
    const response = await createAxios(authorization).get<GetLibraryGamesResponse>('/admin/games/library_data.json', {
      params: {
        business_id: 'PRIVATE_MODE',
        per_page: perPage,
        page: page,
        sort: 'created_at',
        direction: 'desc',
        ...(filters?.language && { language: filters.language }),
        ...(filters?.subject && { subject: filters.subject }),
        ...(filters?.age && { age: filters.age }),
      },
    })

    const { data } = response

    return toSuccess(data)
  } catch (e: any) {
    return toFailure(e)
  }
}

export async function getWordPressData(): Promise<Try<GetWordPressDataResponse>> {
  try {
    const response = await createAxios(
      null,
      'https://seppo-proxy.herokuapp.com/https://seppo.io',
    ).get<GetWordPressDataResponse>('/wp-json/wp/v2/app-content/')
    const { data } = response
    return toSuccess(data)
  } catch (e: any) {
    return toFailure(e)
  }
}

export async function getSponsoredLibraryGames({
  authorization = TEST_USER_AUTHORIZATION,
  perPage = 10,
  page = 1,
  filters,
  top10Mode = false,
}: GetSponsoredGamesParams): Promise<Try<GetLibraryGamesResponse>> {
  try {
    const response = await createAxios(authorization).get<GetLibraryGamesResponse>('/admin/games/library_data.json', {
      params: {
        non_free_or_sponsored: true,
        shop_library: true,
        per_page: perPage,
        page: page,
        sort: top10Mode ? 'downloaded' : 'created_at',
        direction: 'desc',
        ...(filters?.activationCode && { activation_code: filters.activationCode }),
        ...(filters?.language && { language: filters.language }),
        ...(filters?.subject && { subject: filters.subject }),
        ...(filters?.age && { age: filters.age }),
      },
    })

    const { data } = response

    return toSuccess(data)
  } catch (e: any) {
    return toFailure(e)
  }
}

export async function activateSponsoredGame({
  authorization = TEST_USER_AUTHORIZATION,
  email,
  schoolName,
  sponsoredCode,
  gameId,
}: ActivateSponsoredGameParams): Promise<Try<GetSponsoredActivation>> {
  try {
    const response = await createAxios(authorization).post<any>('/orders.json', {
      email: email,
      school_name: schoolName,
      activation_code: sponsoredCode,
      is_sponsored: true,
      game_id: gameId,
      ux3: true,
    })

    const { data } = response

    return toSuccess(data)
  } catch (e: any) {
    return toFailure(e)
  }
}

export async function getCommunityPopularGames({
  authorization = TEST_USER_AUTHORIZATION,
  language = 'en',
  page = 1,
}: GetLibraryGamesParams): Promise<Try<GetLibraryGamesResponse>> {
  try {
    const response = await createAxios(authorization).get<GetLibraryGamesResponse>(
      '/admin/games/library_popular_games.json',
      {
        params: {
          per_page: 10,
          page: page,
          language: getLanguage((language as keyof typeof LibraryLanguage) ?? 'en'),
          only_freemium_enabled: false,
        },
      },
    )

    const { data } = response

    return toSuccess(data)
  } catch (e: any) {
    return toFailure(e)
  }
}

export async function getLibraryGames({
  authorization = TEST_USER_AUTHORIZATION,
  language,
  perPage = 10,
  page = 1,
  corporate = false,
  excludeSponsored = true,
  filters,
}: GetLibraryGamesParams): Promise<Try<GetLibraryGamesResponse>> {
  try {
    const response = await createAxios(authorization).get<GetLibraryGamesResponse>('/admin/games/library_data.json', {
      params: {
        per_page: perPage,
        page: page,
        //TODO: We ignore the language for now, but will probably bring it back later
        //language: language ? getLanguage(language as keyof typeof LibraryLanguage) : null,
        exclude_sponsored: excludeSponsored, //This will excelude sponsored game from reply - First release does not support importing them
        not_in_package: true, //This will excelude games belonging to a game package from reply
        force_sort: true, //Uses "(case when approved_by_seppo then 1 else 2 end), created_at desc" at conlib side for sorting
        ...(corporate && { corporate: true }),
        ...(filters?.language && { language: filters.language }),
        ...(filters?.age && { age: filters.age }),
        ...(filters?.subject && { subject: filters.subject }),
        ...(filters?.approvedBySeppo && { approved_by_seppo: true }),
      },
    })

    const { data } = response

    return toSuccess(data)
  } catch (e: any) {
    return toFailure(e)
  }
}

export async function copyOwnGame({
  authorization = TEST_USER_AUTHORIZATION,
  gameId,
}: CopyOwnGameParams): Promise<Try<GetGamesResponse>> {
  try {
    const response = await createAxios(authorization).post<GetGameResponse>(`/admin/games/${gameId}/copy.json`, {
      return_game: true,
    })

    const { data } = response

    return toSuccess({ active_count: 0, count: 0, my_games: [data] })
  } catch (e: any) {
    return toFailure(e)
  }
}

export async function deleteOwnGame({
  authorization = TEST_USER_AUTHORIZATION,
  gameId,
  force = false,
}: DeleteOwnGameParams): Promise<Try<GetGamesResponse>> {
  try {
    const response = await createAxios(authorization).delete<GetGameResponse>(`/admin/games/${gameId}.json`, {
      params: {
        return_game: true,
        force: force,
      },
    })

    const { data } = response

    return toSuccess({ active_count: 0, count: 0, my_games: [data] })
  } catch (e: any) {
    return toFailure(e)
  }
}

export async function cloneLibraryGame({
  authorization = TEST_USER_AUTHORIZATION,
  gameId,
}: CloneLibraryGameParams): Promise<Try<GetGamesResponse>> {
  try {
    const response = await createAxios(authorization).post<GetGameResponse>('/admin/games/clone_import.json', {
      game_id: gameId,
    })

    const { data } = response

    return toSuccess({ active_count: 0, count: 0, my_games: [data] })
  } catch (e: any) {
    return toFailure(e)
  }
}

export const getTask = async ({
  authorization = TEST_USER_AUTHORIZATION,
  gameId,
  taskId,
}: GetTaskParams): Promise<Try<GetTaskResponse>> => {
  try {
    const { data } = await createAxios(authorization).get<Exercise>(
      `/games/${gameId}/exercises/${taskId}.json?raw=true`,
    )
    return toSuccess({ data })
  } catch (e: any) {
    return toFailure(e)
  }
}

export async function genericUpdateGame({
  authorization = TEST_USER_AUTHORIZATION,
  game,
  moveTasks,
}: UpdateGameParams): Promise<Try<GetGameResponse>> {
  try {
    const response = await createAxios(authorization).put<GetGameResponse>(`/admin/games/${game.id}.json`, {
      ux3: true, //Statically true to inform backend that request comes from UX3
      ...game,
      ...(moveTasks && { move_map: true }),
    })

    const { data } = response

    return toSuccess(data)
  } catch (e: any) {
    return toFailure(e)
  }
}

export const updateTasksOrderApi = async ({
  authorization = TEST_USER_AUTHORIZATION,
  timestamp,
  gameId,
  tasksData,
}: UpdateTasksOrderParams): Promise<Try<UpdateTasksOrderResponse>> => {
  try {
    const response = await createAxios(authorization).post<UpdateTasksOrderResponse>(
      `/admin/games/${gameId}/update_exercise_levels_and_maps.json`,
      {
        timestamp,
        data: tasksData,
      },
    )

    const { data } = response

    return toSuccess(data)
  } catch (e: any) {
    return toFailure(e)
  }
}

export const updateFlashTasksOrderApi = async ({
  authorization = TEST_USER_AUTHORIZATION,
  gameId,
  orders,
}: UpdateFlashTasksOrderParams): Promise<Try<ApiResponse>> => {
  try {
    const response = await createAxios(authorization).post<ApiResponse>(
      `/admin/games/${gameId}/exercises/0/update_flash_ordering.json`,
      {
        orders: JSON.stringify(orders),
      },
    )

    const { data } = response

    return toSuccess(data)
  } catch (e: any) {
    return toFailure(e)
  }
}

export async function createNewGame({
  authorization = TEST_USER_AUTHORIZATION,
  game,
}: CreateGameParams): Promise<Try<GetGameResponse>> {
  try {
    const response = await createAxios(authorization).post<GetGameResponse>('/admin/games/new.json', {
      ux3: true, //Statically true to inform backend that request comes from UX3
      ...game,
    })

    const { data } = response

    return toSuccess(data)
  } catch (e: any) {
    return toFailure(e)
  }
}

export async function createNewEmptyGame(authorization = TEST_USER_AUTHORIZATION): Promise<Try<number>> {
  try {
    const response = await createAxios(authorization).get<GetGameResponse>('/admin/games/new.json')

    const { data } = response

    return toSuccess(data.id)
  } catch (e: any) {
    return toFailure(e)
  }
}

const getLanguage = (languageKey: keyof typeof LibraryLanguage): string => {
  return LibraryLanguage[languageKey]
}

export async function postEmail({
  authorization = TEST_USER_AUTHORIZATION,
  gameId,
  added_by_email_users,
  startingTaskId,
}: EmailParams): Promise<Try<AddStatusResponse>> {
  try {
    const url = '/add_player_by_email.json'
    const response = await createAxios(authorization).post<AddStatusResponse>(url, {
      game_id: gameId,
      email_adresses: added_by_email_users,
      ...(startingTaskId && { start_task: startingTaskId }),
    })

    const { data } = response

    return toSuccess(data)
  } catch (e: any) {
    return toFailure(e)
  }
}

const toBackendTeamMemberNames = (email: string, teamMemberNames: string) => {
  if (email.length > 0 || teamMemberNames.length > 0) return [email.trim(), teamMemberNames.trim()].join(' | ')
  return null
}

export async function addPlayerManual({
  authorization = TEST_USER_AUTHORIZATION,
  gameId,
  startTask,
  nickName,
  teamMemberNames,
}: {
  authorization?: string | null
  gameId: number
  startTask?: number
  nickName: string //Displayname
  teamMemberNames?: string
}): Promise<Try<AddPlayerResponse>> {
  try {
    const response = await createAxios(authorization).post<AddPlayerResponse>(`/admin/games/${gameId}/user.json`, {
      game_id: gameId,
      start_task: startTask,
      username: nickName,
      team_member_names: toBackendTeamMemberNames('', teamMemberNames + ''),
    })

    const { data } = response

    return toSuccess(data)
  } catch (e: any) {
    return toFailure(e)
  }
}

export async function updatePlayerManual({
  authorization = TEST_USER_AUTHORIZATION,
  gameId,
  userId,
  startTask,
  nickName,
  email,
  teamMemberNames,
}: {
  authorization?: string | null
  gameId: number
  userId: number
  startTask?: number
  nickName: string //Displayname
  email: string
  teamMemberNames: string
}): Promise<Try<AddPlayerResponse>> {
  try {
    const response = await createAxios(authorization).post<AddPlayerResponse>(`/admin/games/${gameId}/user.json`, {
      game_id: gameId,
      userid: userId, //Note, BE param name really is like that
      start_task: startTask,
      username: nickName,
      team_member_names: toBackendTeamMemberNames(email + '', teamMemberNames + ''),
      editing: true,
    })

    const { data } = response

    return toSuccess(data)
  } catch (e: any) {
    return toFailure(e)
  }
}

export async function addPlayersWithTagIdsOrPlayerAccountIds({
  authorization = TEST_USER_AUTHORIZATION,
  gameId,
  startingTask = 0,
  tagIds,
  playerAccountIds,
}: {
  authorization?: string | null
  gameId: number
  startingTask?: number
  tagIds: number[] | null
  playerAccountIds: number[] | null
}): Promise<Try<AddPlayerResponse>> {
  try {
    const response = await createAxios(authorization).post<AddPlayerResponse>(
      `/admin/games/${gameId}/players_add_by_tag_or_array.json`,
      {
        game_id: gameId,
        start_task: startingTask,
        ...(tagIds && { tag_ids: tagIds }),
        ...(playerAccountIds && { pa_ids: playerAccountIds }),
      },
    )

    const { data } = response

    return toSuccess(data)
  } catch (e: any) {
    return toFailure(e)
  }
}

export async function deletePlayer({
  authorization = TEST_USER_AUTHORIZATION,
  gameId,
  playerId,
}: {
  authorization?: string | null
  gameId: number
  playerId: number
}): Promise<Try<DeletePersonResponse>> {
  try {
    const response = await createAxios(authorization).post<DeletePersonResponse>(`/admin/games/${gameId}/user.json`, {
      userid: playerId,
      remove: true,
      game_id: gameId,
    })

    const { data } = response

    return toSuccess(data)
  } catch (e: any) {
    return toFailure(e)
  }
}

export async function deleteInstructor({
  authorization = TEST_USER_AUTHORIZATION,
  gameId,
  name,
}: {
  authorization?: string | null
  gameId: number
  name: string
}): Promise<Try<DeletePersonResponse>> {
  try {
    const response = await createAxios(authorization).post<DeletePersonResponse>(`/admin/games/${gameId}/owner.json`, {
      username: name,
      remove: true,
      game_id: gameId,
    })

    const { data } = response

    return toSuccess(data)
  } catch (e: any) {
    return toFailure(e)
  }
}

export async function openGame({
  authorization = TEST_USER_AUTHORIZATION,
  gameId,
  isOpen,
}: OpenGameParams): Promise<Try<GetGameResponse>> {
  try {
    const url = `/admin/games/${gameId}.json`
    const response = await createAxios(authorization).put<GetGameResponse>(url, {
      id: gameId,
      game: { open: isOpen },
    })

    const { data } = response

    return toSuccess(data)
  } catch (e: any) {
    return toFailure(e)
  }
}

export async function checkGameOrPlayerPinExists(
  authorization = TEST_USER_AUTHORIZATION,
  pin_code: string,
): Promise<Try<CheckGameOrPlayerResponse>> {
  try {
    const response = await createAxios(authorization).get<CheckGameOrPlayerResponse>(
      `/ux3_check_game_or_player.json?pin_code=${encodeURIComponent(pin_code)}`,
    )

    const { data } = response

    return toSuccess(data)
  } catch (e: any) {
    return toFailure(e)
  }
}

export async function postInstructor({
  authorization = TEST_USER_AUTHORIZATION,
  gameId,
  userName,
}: InstructorAddParams): Promise<Try<AddResponse>> {
  try {
    const url = `/admin/games/${gameId}/owner.json`
    const response = await createAxios(authorization).post<AddResponse>(url, {
      game_id: gameId,
      username: userName,
    })

    const { data } = response

    return toSuccess(data)
  } catch (e: any) {
    return toFailure(e)
  }
}

export async function postEvaluator({
  authorization = TEST_USER_AUTHORIZATION,
  gameId,
  userName,
  isGrader,
  isStudent,
}: EvaluatorAddParams): Promise<Try<AddResponse>> {
  try {
    const url = `/admin/games/${gameId}/owner.json`
    const response = await createAxios(authorization).post<AddResponse>(url, {
      game_id: gameId,
      username: userName,
      is_student: isStudent.toString(),
      is_grader: isGrader.toString(),
    })

    const { data } = response

    return toSuccess(data)
  } catch (e: any) {
    return toFailure(e)
  }
}

export async function postStudentInstructor({
  authorization = TEST_USER_AUTHORIZATION,
  gameId,
  userName,
  isStudent,
}: StudentAddParams): Promise<Try<AddResponse>> {
  try {
    const url = `/admin/games/${gameId}/owner.json`
    const response = await createAxios(authorization).post<AddResponse>(url, {
      game_id: gameId,
      username: userName,
      is_student: isStudent.toString(),
    })

    const { data } = response

    return toSuccess(data)
  } catch (e: any) {
    return toFailure(e)
  }
}

export async function getScoreboard({
  authorization = TEST_USER_AUTHORIZATION,
  gameId,
}: GetScoreboardParams): Promise<Try<GetScoreboardResponse>> {
  try {
    const response = await createAxios(authorization).get<GetScoreboardResponse>(`/games/${gameId}/points.json`)

    const { data } = response

    return toSuccess(data)
  } catch (e: any) {
    return toFailure(e)
  }
}

export async function manageConnectionLine({
  authorization = TEST_USER_AUTHORIZATION,
  gameId,
  fromId,
  toId,
  isRemove = false,
}: {
  authorization?: null
  gameId: number
  fromId: number
  toId: number
  isRemove?: boolean
}): Promise<Try<ManageLineResponse>> {
  try {
    const response = await createAxios(authorization).post<ManageLineResponse>(
      `/admin/games/${gameId}/path_connection.json`,
      {
        remove: isRemove.toString(),
        path: { from: fromId, to: toId },
        ux3: true,
      },
    )

    const { data } = response

    return toSuccess(data)
  } catch (e: any) {
    console.error(e)
    return toFailure(e)
  }
}

export const createOrUpdateBadgeApi = async ({
  authorization = TEST_USER_AUTHORIZATION,
  gameId,
  badgeId,
  badgeName,
  badgeFile,
  badgeUrl,
}: CreateBadgeParams): Promise<Try<CreateBadgeResponse>> => {
  try {
    const formData = new FormData()
    formData.append('game_id', gameId.toString())

    if (badgeId == null && badgeUrl == null && badgeFile == null) {
      throw new Error('Cannot create badge without image file or url')
    }

    formData.append('id', (badgeId || '').toString())
    formData.append('name', badgeName)
    formData.append('url', '')

    // file parameter can be either the file, or an url
    if (badgeFile != null) {
      formData.append('file', badgeFile)
    } else if (badgeUrl != null) {
      formData.append('file', badgeUrl)
    }

    const response = await createAxios(authorization).post<CreateBadgeResponse>('/badges', formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })

    const { data } = response

    return toSuccess(data)
  } catch (e: any) {
    console.error(e)
    return toFailure(e)
  }
}

export const deleteBadgeApi = async (id: number): Promise<Try<DeleteBadgeResponse>> => {
  try {
    const response = await createAxios(TEST_USER_AUTHORIZATION).delete<DeleteBadgeResponse>(`/badges/${id}.json`)
    const { data } = response
    return toSuccess(data)
  } catch (e: any) {
    return toFailure(e)
  }
}

export const awardBadgeApi = async (playerId: number, badgeId: number, gameId: number): Promise<Try<ApiResponse>> => {
  try {
    const response = await createAxios(TEST_USER_AUTHORIZATION).post<ApiResponse>(`/admin/games/${gameId}/user.json`, {
      userid: playerId,
      badge_id: badgeId,
      game_id: gameId,
    })
    const { data } = response
    return toSuccess(data)
  } catch (e: any) {
    console.error(e)
    return toFailure(e)
  }
}

export async function updateGameMapStructure({
  authorization = TEST_USER_AUTHORIZATION,
  gameId,
  mapStructure,
}: {
  authorization?: null
  gameId: number
  mapStructure: MapStructure
}): Promise<Try<ApiResponse>> {
  try {
    const response = await createAxios(authorization).post<ApiResponse>(
      `/admin/games/${gameId}/update_map_structure.json`,
      {
        map_structure: JSON.stringify(mapStructure),
      },
    )

    const { data } = response

    return toSuccess(data)
  } catch (e: any) {
    console.error(e)
    return toFailure(e)
  }
}
