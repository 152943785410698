import yaml from 'js-yaml'
import {
  APIGamePerson,
  BadgeData,
  BaseGameSubset,
  CannedMessageResponse,
  CannedMessagesResponse,
  Exercise,
  GameLibraryShareParams,
  GetScoreboardResponse,
  LibraryGameDetailsResponse,
  MapType,
  NotificationSettingsData,
  ShareType,
} from '../api/gameTypes'
import { getDefaultMapStructure, parseBadgeResponseToBadge, parseTaskResponseToTaskVm } from '../api/typeConverters'
import { LEGACY_EXPLORATION_OVERVIEW_SIZE_X, LEGACY_EXPLORATION_OVERVIEW_SIZE_Y } from '../common/constants'
import { GameBoard, GameForm, ShareGameForm } from '../pages/GameEditor/types'
import {
  Badge,
  CannedMessage,
  EditorPermissions,
  Evaluator,
  GamePeople,
  Instructor,
  LibraryGameDetails,
  NotificationSettings,
  ObjectAny,
  Player,
  PlayerScore,
  RoleType,
  StudentInstructor,
  TUser,
  Task,
  TaskConnection,
} from '../types/commonTypes'
import { safeIsNullOrEmpty, safeIsTrimmedNumeric, trimDoubleQuotes } from '../util/string'

export enum UpdateType {
  User = 'user',
  DeleteUser = 'user_delete',
  Exercise = 'exercise',
  DeleteExercise = 'exercise_delete',
  Answer = 'answer',
  Chat = 'message',
  DeleteChat = 'message_delete',
  PlayerArray = 'multiple_players',
}

export type PushUserType = {
  id: string
  name: string
  status: string | null
  role: RoleType
  playerCode: string
}

export type AnswerEvaluation = {
  answerId: number
  comment?: string
  points?: number
  badge?: Badge
}

export interface UpdateItem {
  channel_name: string
  channel_serial: number
  type: UpdateType
  msg: string // The actual payload
}

export interface ApiLocationInfo {
  game_id: number
  lat: number
  lng: number
  user_id: number
  user_name: string
}

export interface LocationInfo {
  gameId: number
  lat: number
  lng: number
  userId: number
  nickName: string
  updatedAt: Date
}

export const getFullTasks = (rawParentChildrenExercises: Exercise[]): Task[] => {
  return rawParentChildrenExercises.map(parseTaskResponseToTaskVm)
}

export const toLibraryGameDetails = (rawLibraryGameDetails: LibraryGameDetailsResponse): LibraryGameDetails => {
  return {
    ...rawLibraryGameDetails,
    description: rawLibraryGameDetails.short_description,
    shareType: rawLibraryGameDetails.public_and_private,
    masterOwnerId: rawLibraryGameDetails.master_owner_id,
    businessId: rawLibraryGameDetails.business_id,
  }
}

export const toCannedMessages = (cannedMessageContent: CannedMessagesResponse): CannedMessage[] => {
  return cannedMessageContent.canned_messages.map((message: CannedMessageResponse) => {
    return {
      default: message.default,
      id: message.id,
      text: message.text,
    }
  })
}

export const getGameLibraryShareParams = (gameId: number, data: ShareGameForm): GameLibraryShareParams => {
  return {
    game_id: gameId,
    name: data.name,
    short_description: data.description ?? '',
    email: data.userEmail,
    creator: data.userName,
    phone: data.userPhone,
    subject: data.topics ?? [],
    age: data.ages ?? [],
    language: data.language ?? '',
    keywords: (data.keywords || []).join(','),
    public_and_private:
      data.shareToPrivate && data.shareToPublic
        ? ShareType.BOTH
        : data.shareToPrivate
        ? ShareType.PRIVATE
        : ShareType.PUBLIC,
  }
}

export const toParentChildrenStructure = (flatExercises: { [key: number]: Exercise }): { [key: number]: Exercise } => {
  const parentsWithChildren: { [key: number]: Exercise } = {}
  const exArray = Object.values(flatExercises)
  exArray
    .filter((e) => isNaN(parseInt('' + e.parent_id)))
    .forEach((e) => {
      parentsWithChildren[e.id] = { ...e, children: exArray.filter((c) => c.parent_id === e.id) }
    })
  return parentsWithChildren
}

const parseCsvStringValue = (rawData: string): string => {
  return rawData?.[0] === '"' ? rawData.substring(1, rawData.length - 1).replaceAll('\\', '') : rawData
}

export const fixCreativeAnswerContent = (doc: string, onlyEmojis = false): string => {
  try {
    if (doc.match(/<*.>/) || onlyEmojis) {
      //Manage emojis
      let eIndex = doc.indexOf('\\U') + 2
      let roundsLeft = 30
      while (eIndex > 2 && roundsLeft--) {
        let end = eIndex
        let found = false
        while (end < doc.length && !found) {
          if (![' ', '<', '\\', '"', "'"].includes(doc[end])) end++
          else found = true
        }
        const charCode = doc.substring(eIndex, end)
        doc = doc.replace('\\U' + charCode, String.fromCodePoint(parseInt('0x' + charCode)))
        eIndex = doc.indexOf('\\U') + 2
      }
      doc = doc.replaceAll(/\\"/g, '__"').replaceAll('\\', '').replaceAll('__"', '\\"')
      //Manage linebreaks
      if (!onlyEmojis) {
        doc = doc.replaceAll('\\n', '<br>').replaceAll('\n', '<br>')
      }
    }
  } catch (e) {
    console.log('errorr')
    console.log(e)
  }
  return doc
}

export const csvjsonToObj = (_csvData: string | object): { [key: number]: any } => {
  const objHash: { [key: number]: any } = {}
  const yamlRemoveFirst = [
    '!ruby/hash:ActiveSupport::HashWithIndifferentAccess',
    '!ruby/hash-with-ivars:ActionController::Parameters',
  ]
  const csvData = typeof _csvData === 'object' ? JSON.stringify(_csvData) : _csvData
  const jsonStrings = !!csvData ? csvData.split('---___---') : []
  jsonStrings.forEach((exerciseJsonString) => {
    const exerciseRawObject = JSON.parse(exerciseJsonString)
    if (typeof exerciseRawObject.data === 'string') {
      let yamlDataField = yamlRemoveFirst.reduce((acc, curr) => acc.replaceAll(curr, ''), exerciseRawObject.data)
      yamlDataField = fixCreativeAnswerContent(yamlDataField, true)
      let doc: ObjectAny = yaml.load(yamlDataField) as object
      if (doc)
        Object.keys(doc).forEach((subkey) => {
          if (subkey.charAt(0) === ':') {
            doc[subkey.substring(1)] = doc[subkey]
            delete doc[subkey]
          }
        })
      exerciseRawObject.data = doc
    }
    objHash[exerciseRawObject.id] = exerciseRawObject
  })
  return objHash
}

export const csvToObjHash = (csvData: string): { [key: number]: any } => {
  const objHash: { [key: number]: any } = {}
  const valArray = csvData.split('---___---')
  const keyNames = valArray[0].split('$')
  const yamlFields = ['data', 'answer']
  const yamlRemoveFirst = [
    '"',
    '!ruby/hash:ActiveSupport::HashWithIndifferentAccess',
    '!ruby/hash-with-ivars:ActionController::Parameters',
  ]
  const forcedStringFields = ['postcard_file_name', 'name', 'real_name']

  valArray.forEach((exerciseRow, ind) => {
    if (ind > 0) {
      const tempValues = exerciseRow.split('$')
      const rootValKey = parseInt(tempValues[0])
      objHash[rootValKey] = {}
      keyNames.forEach((_k, i) => {
        const currKey = _k.trim()
        const currVal = tempValues[i]
        try {
          if (forcedStringFields.includes(currKey)) objHash[rootValKey][currKey] = parseCsvStringValue(currVal)
          else if (['t', 'f'].includes(currVal.trim())) objHash[rootValKey][currKey] = currVal.trim() === 't'
          else if (yamlFields.includes(currKey)) {
            const stringToParse = yamlRemoveFirst.reduce((acc, curr) => acc.replaceAll(curr, ''), currVal)
            let doc: ObjectAny = yaml.load(stringToParse) as object
            if (doc)
              Object.keys(doc).forEach((subkey) => {
                if (subkey.charAt(0) === ':') {
                  doc[subkey.substring(1)] = doc[subkey]
                  delete doc[subkey]
                }
              })
            if (currKey === 'answer' && doc.match) {
              doc = fixCreativeAnswerContent(doc as unknown as string) as unknown as object
            }
            objHash[rootValKey][currKey] = doc
          } else if (/^-?\d+(\.\d+)?$/.test(currVal)) objHash[rootValKey][currKey] = parseFloat(currVal)
          else objHash[rootValKey][currKey] = parseCsvStringValue(currVal)
        } catch (e) {
          // console.log('----ToObjHash failed ' + currKey)
          // console.log('tempValues[i] ' + currVal)
        }
      })
    }
  })
  return objHash
}

const getPlayerTeamMemberNames = (teamMemberNames: string): string => {
  if (!teamMemberNames || teamMemberNames === 'null') return ''
  return (teamMemberNames + '').includes('|') ? teamMemberNames.split('|')[1] : teamMemberNames + ''
}

const getPlayerEmail = (email: string, teamMemberNames: string): string => {
  const parsedEmail = (email ?? '').toString().includes('@')
    ? email
    : !safeIsNullOrEmpty(teamMemberNames) && teamMemberNames.split('|')[0] !== 'null'
    ? teamMemberNames.split('|')[0]
    : ''
  //If teacher has added a player in legacy with team member names, then the names are in the field without the | separator
  //Then email and teamMemberNames would be here the same and shown twice
  return parsedEmail.trim() !== getPlayerTeamMemberNames(teamMemberNames).trim() ? parsedEmail : ''
}

const defineEmailTrustworthiness = (email: string, teamMemberNames: string): boolean => {
  if ((email ?? '').toString().includes('@')) {
    return true
  } else if (!safeIsNullOrEmpty(teamMemberNames) && teamMemberNames.split('|')[0] !== 'null') {
    return false
  } else {
    return false
  }
}

export const toPushUser = (msg: string): Player | Instructor | Evaluator | StudentInstructor => {
  const {
    id,
    player_code,
    name,
    real_name,
    user_status,
    role,
    points,
    team_member_names,
    player_email,
    start_task,
    badges,
  } = JSON.parse(msg)
  return {
    id,
    name,
    nickName: real_name,
    //status: user_status,
    role:
      (team_member_names || '').split(',')[0] === 'GRADINGLICENCE'
        ? RoleType.Evaluator
        : (team_member_names || '').split(',')[0] === 'STUDENTLICENCE'
        ? RoleType.StudentInstructor
        : role === 'teacher'
        ? RoleType.Instructor
        : RoleType.Player,
    playerCode: player_code,
    points,
    teamMemberNames: getPlayerTeamMemberNames(team_member_names),
    email: getPlayerEmail(player_email, team_member_names),
    deleted: user_status === 'REMOVED',
    validUntil: (team_member_names || '').length > 0 ? team_member_names.split(',')[1] : '',
    isEmailTrusted: defineEmailTrustworthiness(player_email, team_member_names),
    startingTask: safeIsTrimmedNumeric(start_task) ? parseInt(start_task) : null,
    badges: parsePlayerBadges(badges),
  }
}

export const isEvaluator = (dataString: string) => {
  return dataString.includes('GRADINGLICENCE')
}

export const isStudentInstructor = (dataString: string) => {
  return dataString.includes('STUDENTLICENCE')
}

export const isInstructor = (role: string, dataString: string) => {
  return role === RoleType.Instructor && !isEvaluator(dataString) && !isStudentInstructor(dataString)
}

const parsePlayerBadges = (raw: string) => {
  if (safeIsNullOrEmpty(raw)) {
    return []
  }
  try {
    const badgesObj = JSON.parse(trimDoubleQuotes(raw))
    return Object.values<BadgeData>(badgesObj).map(parseBadgeResponseToBadge)
  } catch (error) {
    console.error('Failed to parse player badges, returning empty array')
    console.error(error)
    console.warn(raw)
    return []
  }
}

export const getGamePeople = (flatPeople: { [key: number]: APIGamePerson }): GamePeople => {
  const peopleArray = Object.values(flatPeople)
  return {
    players: peopleArray
      .filter((u) => u.role === RoleType.Player)
      .map((p) => {
        return {
          id: p.user_id,
          name: p.name,
          nickName: p.real_name,
          playerCode: p.session_id,
          points: p.points,
          teamMemberNames: getPlayerTeamMemberNames(p.team_member_names),
          email: getPlayerEmail(p.player_email, p.team_member_names),
          role: RoleType.Player,
          deleted: p.user_status === 'REMOVED',
          isEmailTrusted: defineEmailTrustworthiness(p.player_email, p.team_member_names),
          startingTask: safeIsTrimmedNumeric(p.start_task) ? parseInt(p.start_task) : null,
          badges: parsePlayerBadges(p.badges),
        }
      }),
    instructors: peopleArray
      .filter((u) => isInstructor(u.role, u.team_member_names))
      .map((p) => {
        return {
          id: p.user_id,
          name: p.name,
          nickName: p.real_name,
          teamMemberNames: p.team_member_names.length > 0 ? p.team_member_names.split('|')[1] : '',
          playerCode: '',
          role: RoleType.Instructor,
          validUntil: '',
        }
      }),
    evaluators: peopleArray
      .filter((u) => isEvaluator(u.team_member_names))
      .map((p) => {
        return {
          id: p.user_id,
          name: p.name,
          nickName: p.real_name,
          //TODO: both teammembernames and validUntil contain the validUntil date. teamMemberNames is required elsewhere, so didn't remove
          teamMemberNames: p.team_member_names.length > 0 ? p.team_member_names.split(',')[1] : '',
          validUntil: p.team_member_names.length > 0 ? p.team_member_names.split(',')[1] : '',
          playerCode: p.session_id,
          role: RoleType.Evaluator,
        }
      }),
    studentInstructors: peopleArray
      .filter((u) => isStudentInstructor(u.team_member_names))
      .map((p) => {
        return {
          id: p.user_id,
          name: p.name,
          nickName: p.real_name,
          //TODO: both teammembernames and validUntil contain the validUntil date. teamMemberNames is required elsewhere, so didn't remove
          teamMemberNames: p.team_member_names.length > 0 ? p.team_member_names.split(',')[1] : '',
          validUntil: p.team_member_names.length > 0 ? p.team_member_names.split(',')[1] : '',
          playerCode: p.session_id,
          role: RoleType.StudentInstructor,
        }
      }),
  }
}

const getNotificationSettings = (notificationSettingsVM: Partial<NotificationSettings>): NotificationSettingsData => {
  return {
    messages: notificationSettingsVM?.messages ?? null,
    answers: notificationSettingsVM?.answers ?? null,
    instant_enabled: notificationSettingsVM?.instantEnabled ?? false,
    summary_enabled: notificationSettingsVM?.summaryEnabled ?? false,
    emails: (notificationSettingsVM?.emails || []).join(','),
    sending_time: notificationSettingsVM?.summarySendTime ?? null,
  }
}

export const getGameParams = (data: GameForm): BaseGameSubset => {
  return {
    name: data.name,
    short_description: data.description ?? '',
    ...((data.gameBoardSettings.gameBoardType === MapType.LIVE || data.gameBoardSettings.gameBoards.length) && {
      map_type: data.gameBoardSettings.gameBoardType,
    }),
    ...([MapType.LIVE, MapType.PANORAMA].includes(data.gameBoardSettings.gameBoardType) && {
      longitude: data.gameBoardSettings.mapOptions.center[0],
      latitude: data.gameBoardSettings.mapOptions.center[1],
      zoom: data.gameBoardSettings.mapOptions.zoom,
    }),
    maps: data.gameBoardSettings.gameBoards.map((map, ind) => {
      return {
        map_id: ind - 1,
        name: map.name,
        map_url: map.url,
        map_dimensions: { x: 0, y: 0 },
        location:
          data.gameBoardSettings.gameBoardType === MapType.PANORAMA
            ? JSON.stringify({
                lng: map.mapOptions?.center[0] ?? 0,
                lat: map.mapOptions?.center[1] ?? 0,
                zoom: map.mapOptions?.zoom ?? 100,
              })
            : '',
      }
    }),
    keywords: (data.keywords || []).join(','),
    age: data.ages,
    subject: data.topics,
    language: data.language ?? '',
    notification_settings: `${JSON.stringify(getNotificationSettings(data.notificationSettings))}`,
    no_gps:
      data.gameBoardSettings.gameBoardType === MapType.LIVE ? data.advancedSettings?.gpsEnabled === false : undefined,
    player_email_required: data.advancedSettings?.emailRequired,
    team_member_names_required: data.advancedSettings?.teamMemberNamesRequired,
    no_chat: data.advancedSettings?.chatEnabled === false,
    branch_type:
      data.gameBoardSettings.gameBoardType !== MapType.PANORAMA && data.advancedSettings?.allowBranching
        ? 'TREE'
        : null,
    advanced_mode:
      data.gameBoardSettings.gameBoardType !== MapType.LIVE ? data.advancedSettings?.explorationMode : undefined,
    levels_enabled: data.advancedSettings?.levelsEnabled,
    ordering_enabled: data.advancedSettings?.orderingEnabled,
    player_continues_sent_ans_enabled: data.advancedSettings?.allowPlayersToImproveAnswers,
    no_points_game: data.advancedSettings?.noPointsGame,
    hide_scoreboard: data.advancedSettings?.hideScoreboard,
    happy_or_not: data.advancedSettings?.askPlayerFeedback,
    badges_enabled: data.advancedSettings?.badgesEnabled,
    share_secret: data.advancedSettings?.publicLinkEnabled ? data.advancedSettings.publicLinkSecret ?? null : null,
    hide_exercise_animations: data.advancedSettings?.hideIllustrations ?? false,
    offline_enabled: data.advancedSettings?.offlineMode ?? false,
  }
}

export const parseScoreboard = (data: GetScoreboardResponse): PlayerScore[] => {
  return data.score_table.map((player, index) => {
    return {
      playerId: player.user_id,
      name: player.real_name ?? player.user_name,
      points: player.points,
      position: index + 1,
    }
  })
}

export const parseTaskOrderResponse = (
  exerciseSubset: Pick<Exercise, 'level' | 'map_id' | 'order_number'>,
): Pick<Task, 'level' | 'mapIndex' | 'order'> => {
  return {
    ...(exerciseSubset.level != null && { level: exerciseSubset.level }),
    ...(exerciseSubset.map_id != null && { mapIndex: exerciseSubset.map_id + 1 }),
    ...(exerciseSubset.order_number != null && { order: exerciseSubset.order_number }),
  }
}

export const getOldParentIdIfParentTaskChanged = (tasks: Task[], task: Task, subtasksToDelete: number[]) => {
  const notAnymoreParentIds = task.subtasks
    .slice(1)
    .map((t) => t.id)
    .concat(subtasksToDelete)
  const currentParentIds = tasks.map((t) => t.id)
  return currentParentIds?.filter((id) => notAnymoreParentIds.includes(id))[0]
}

export const taskConnectionIdSwitch = (prev: TaskConnection[], oldId: number, newId: number) => {
  const temp: TaskConnection[] = JSON.parse(JSON.stringify(prev))
  temp.filter((tc) => tc.fromId === oldId).forEach((tc) => (tc.fromId = newId))
  temp.filter((tc) => tc.toId === oldId).forEach((tc) => (tc.toId = newId))
  return temp
}

type MapStructure = {
  [key: string]: {
    doors: Array<{
      x: number
      y: number
      id: number
      leadsToMapId: number | string
      pair?: number | string
    }>
    world_pos: {
      x: string
      y: string
    }
  }
}

const toBackendWorldPosX = (relativeX = 0): number => {
  return (relativeX / 100) * LEGACY_EXPLORATION_OVERVIEW_SIZE_X
}
const toBackendWorldPosY = (relativeY = 0): number => {
  return (relativeY / 100) * LEGACY_EXPLORATION_OVERVIEW_SIZE_Y
}

export const toBackendMapStructure = (boards: GameBoard[]): MapStructure => {
  const mapStructure: MapStructure = {}
  boards.forEach((board) => {
    mapStructure[(board.mapIndex ?? 0) - 1] = {
      doors:
        board.doors?.map((door) => {
          return {
            x: door.longitude,
            y: door.latitude,
            id: door.id,
            leadsToMapId: door.leadsToBoardIndex - 1,
            pair: door.pair,
          }
        }) ?? [],
      world_pos: {
        x: toBackendWorldPosX(board.worldPosition?.[0]) + '',
        y: toBackendWorldPosY(board.worldPosition?.[1]) + '',
      },
    }
  })
  return mapStructure
}

export const toBackendMapStructureWithDefaultDoors = (mapType: MapType, boards: GameBoard[]): MapStructure => {
  const mapStructure: MapStructure = {}
  const nextDoorBase = 1000
  const prevDoorBase = 2000

  boards.forEach((board, ind) => {
    const defaultDoorToNext =
      ind < boards.length - 1
        ? {
            default: true,
            x: mapType === MapType.PANORAMA ? board.mapOptions?.center[0] ?? 50 : 50,
            y: mapType === MapType.PANORAMA ? board.mapOptions?.center[1] ?? 50 : 50,
            id: nextDoorBase + ind,
            leadsToMapId: ind + 1 - 1, //-1 to convert to backend index, separated for clarity
            pair: prevDoorBase + ind + 1,
          }
        : null
    const defaultDoorToPrevious =
      ind > 0
        ? {
            default: true,
            x: mapType === MapType.PANORAMA ? (board.mapOptions?.center[0] ?? 50) + 2 : 52,
            y: mapType === MapType.PANORAMA ? (board.mapOptions?.center[1] ?? 50) + 2 : 52,
            id: prevDoorBase + ind,
            leadsToMapId: ind - 1 - 1,
            pair: nextDoorBase + ind - 1,
          }
        : null
    const doors = []
    if (defaultDoorToNext) doors.push(defaultDoorToNext)
    if (defaultDoorToPrevious) doors.push(defaultDoorToPrevious)

    mapStructure[ind - 1] = {
      doors: doors,
      world_pos: getDefaultMapStructure(ind - 1)[ind - 1 + ''].world_pos,
    }
  })

  return mapStructure
}

export const EVALUATOR_PERMISSIONS: EditorPermissions = {
  actions: {
    accessChatModal: true,
    accessPeopleModal: true,
    addPlayers: false,
    dashboardLink: false,
    generalSettingsLink: false,
    instructors: false,
    modifyGameSettings: false,
    modifyPlayers: true,
    shareGame: false,
    startGame: false,
    storyAndRules: false,
    tasks: false,
    flashTasks: true,
  },
  gameSettings: false,
}

export const STUDENT_INSTRUCTOR_PERMISSIONS: EditorPermissions = {
  actions: {
    accessChatModal: false,
    accessPeopleModal: false,
    addPlayers: false,
    dashboardLink: false,
    generalSettingsLink: false,
    instructors: false,
    modifyGameSettings: true,
    modifyPlayers: true,
    shareGame: false,
    startGame: false,
    storyAndRules: false,
    tasks: true,
    flashTasks: true,
  },
  gameSettings: {
    advancedSettings: true,
    ages: true,
    description: true,
    gameBoardSettings: true,
    keywords: true,
    language: true,
    name: true,
    notificationSettings: false,
    topics: true,
  },
}

export const STUDENT_INSTRUCTOR_RESTRICTED_PERMISSIONS: EditorPermissions = {
  actions: {
    accessChatModal: false,
    accessPeopleModal: false,
    addPlayers: false,
    dashboardLink: false,
    generalSettingsLink: false,
    instructors: false,
    modifyGameSettings: true,
    modifyPlayers: true,
    shareGame: false,
    startGame: false,
    storyAndRules: false,
    tasks: false,
    flashTasks: true,
  },
  gameSettings: {
    name: true,
    advancedSettings: {
      askPlayerFeedback: true,
      chatEnabled: true,
      emailRequired: true,
      hideScoreboard: true,
      publicLinkEnabled: true,
      publicLinkSecret: true,
      teamMemberNamesRequired: true,
    },
  },
}

export const INSTRUCTOR_PERMISSIONS: EditorPermissions = {
  actions: {
    accessChatModal: true,
    accessPeopleModal: true,
    addPlayers: true,
    dashboardLink: true,
    generalSettingsLink: true,
    instructors: true,
    modifyGameSettings: true,
    modifyPlayers: true,
    shareGame: true,
    startGame: true,
    storyAndRules: true,
    tasks: true,
    flashTasks: true,
  },
  gameSettings: true,
}

export const INSTRUCTOR_RESTRICTED_PERMISSIONS: EditorPermissions = {
  actions: {
    accessChatModal: true,
    accessPeopleModal: true,
    addPlayers: true,
    dashboardLink: true,
    generalSettingsLink: true,
    instructors: true,
    modifyGameSettings: true,
    modifyPlayers: true,
    shareGame: false,
    startGame: true,
    storyAndRules: false,
    tasks: false,
    flashTasks: true,
  },
  gameSettings: {
    name: true,
    advancedSettings: {
      askPlayerFeedback: true,
      chatEnabled: true,
      emailRequired: true,
      hideScoreboard: true,
      publicLinkEnabled: true,
      publicLinkSecret: true,
      teamMemberNamesRequired: true,
    },
    notificationSettings: true,
  },
}

export const GAME_3D_PERMISSIONS: EditorPermissions = {
  actions: {
    accessChatModal: false,
    accessPeopleModal: true,
    addPlayers: true,
    dashboardLink: true,
    generalSettingsLink: true,
    instructors: true,
    modifyGameSettings: true,
    modifyPlayers: true,
    shareGame: false,
    startGame: true,
    storyAndRules: true,
    tasks: true,
    flashTasks: false,
  },
  gameSettings: {
    name: true,
    keywords: false,
    language: true,
    topics: true,
    ages: true,
    gameBoardSettings: false,
    advancedSettings: {
      orderingEnabled: true,
      askPlayerFeedback: false,
      badgesEnabled: true,
      chatEnabled: false,
      allowPlayersToImproveAnswers: true,
      noPointsGame: false,
      emailRequired: true,
      hideScoreboard: true,
      publicLinkEnabled: true,
      publicLinkSecret: true,
      teamMemberNamesRequired: true,
    },
    notificationSettings: false,
  },
}

export const getEditorPermissions = (user: TUser, restricted: boolean, is3DGame = false): EditorPermissions => {
  if (process.env.REACT_APP_ENV === 'development' && localStorage.getItem('editor-permission-debug-override') != null) {
    try {
      return JSON.parse(localStorage.getItem('editor-permission-debug-override')!).value as EditorPermissions
    } catch {}
  }
  if (user.isStudentTeacher) {
    return restricted ? STUDENT_INSTRUCTOR_RESTRICTED_PERMISSIONS : STUDENT_INSTRUCTOR_PERMISSIONS
  }
  if (user.isGradingInstructor) {
    return EVALUATOR_PERMISSIONS
  }
  if (is3DGame) {
    return restricted ? GAME_3D_PERMISSIONS && INSTRUCTOR_RESTRICTED_PERMISSIONS : GAME_3D_PERMISSIONS
  }
  // NOTE: restricted game might specifically allow changing the board game? Can be changed specific to game settings - currently it's hardcoded false
  return restricted ? INSTRUCTOR_RESTRICTED_PERMISSIONS : INSTRUCTOR_PERMISSIONS
}
