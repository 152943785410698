import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from '../../../../../../../../../common/components/button/Button'
import { getIcon } from '../../../../../../../../../common/components/icons/utils'
import { AnswerStateEnum, Evaluation } from '../../../../../../../../../types/commonTypes'
import { compareStrings } from '../../../../../../../../../util/string'
import sidebarStyles from '../../../../../EditorSidebar.module.css'
import { AWARD_BADGE_BUTTON_ID, EvaluationSortBy } from '../../../../types'
import { TaskForPlayer } from '../../../Task/TaskForPlayer'
import styles from './PlayerEvaluationList.module.css'

type PlayerEvaluationListProps = {
  evaluations: Evaluation[]
  onSelectTask: (taskId: number) => void
  selectedTaskId?: number
  noPointsGame?: boolean
  hasLevels?: boolean
  isCompact: boolean
  sortBy: EvaluationSortBy
  onClickAwardBadge?: () => void
}

const getStateWeight = (state: AnswerStateEnum) => {
  return [
    AnswerStateEnum.READY,
    AnswerStateEnum.TIME_FINISHED,
    AnswerStateEnum.GRADED,
    AnswerStateEnum.REVISION,
    AnswerStateEnum.IN_PROGRESS,
  ].indexOf(state)
}

const evaluationSort = (a: Evaluation, b: Evaluation): number => {
  const aStateWeight = getStateWeight(a.state)
  const bStateWeight = getStateWeight(b.state)
  if (aStateWeight === bStateWeight) {
    return a.answers[0].answer.completedAt.localeCompare(b.answers[0].answer.completedAt)
  }
  return aStateWeight - bStateWeight
}

const getSortFn = (sortBy: EvaluationSortBy): ((a: Evaluation, b: Evaluation) => number) => {
  switch (sortBy) {
    case EvaluationSortBy.Evaluation_asc:
      return evaluationSort
    case EvaluationSortBy.AnswerTimestamp_asc:
      return (a: Evaluation, b: Evaluation) =>
        a.answers[0].answer.completedAt.localeCompare(b.answers[0].answer.completedAt)
    case EvaluationSortBy.AnswerTimestamp_desc:
      return (a: Evaluation, b: Evaluation) =>
        b.answers[0].answer.completedAt.localeCompare(a.answers[0].answer.completedAt)
    case EvaluationSortBy.TaskName_asc:
      return (a: Evaluation, b: Evaluation) => compareStrings(a.task.name, b.task.name)
    case EvaluationSortBy.TaskName_desc:
      return (a: Evaluation, b: Evaluation) => compareStrings(b.task.name, a.task.name)
    default:
      return (a: Evaluation, b: Evaluation) => 0
  }
}

export const PlayerEvaluationList: React.FC<PlayerEvaluationListProps> = ({
  evaluations,
  onSelectTask,
  noPointsGame,
  hasLevels,
  selectedTaskId,
  isCompact,
  sortBy,
  onClickAwardBadge,
}) => {
  const { t } = useTranslation()

  const evaluationsSorted = useMemo(() => [...evaluations].sort(getSortFn(sortBy)), [evaluations, sortBy])

  return (
    <div className={styles.playerEvaluationContainer}>
      {onClickAwardBadge != null && (
        <Button
          className={sidebarStyles.sidebarCentredButton}
          variant='outline-tiny'
          onClick={onClickAwardBadge}
          id={AWARD_BADGE_BUTTON_ID}
        >
          <span className='iconWrapperMedium'>{getIcon('badgeGradient')}</span>
          {t('game_editor.sidebar.award_badge.open_award_badge_panel_button', 'Award badge')}
        </Button>
      )}
      {evaluations.length === 0 ? (
        <p className={sidebarStyles.emptyStateInfo}>
          {t('game_editor.sidebar.evaluation_list_panel.no_answers_for_player', 'No answers for selected player')}
        </p>
      ) : (
        <ul className={styles.playerEvaluationList}>
          {evaluationsSorted.map((evaluation) => (
            <TaskForPlayer
              evaluation={evaluation}
              onSelectTask={onSelectTask}
              key={`taskForPlayer_${evaluation.task.id}`}
              noPointsGame={noPointsGame}
              isExplore={evaluation.task.isSingleExplore}
              isSelected={evaluation.task.id === selectedTaskId}
              hasLevels={hasLevels}
              isCompact={isCompact}
            />
          ))}
        </ul>
      )}
    </div>
  )
}
