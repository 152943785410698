import { TFunction } from 'i18next'
import { SelectOption } from '../../../common/components/Select/Select'
import { MemberRole } from '../../../types/commonTypes'

export const getMemberRoleOptions = (t: TFunction): SelectOption[] => [
  { label: t('settings_organization.members.role_label.admin', 'Admin'), value: MemberRole.ADMIN },
  {
    label: t('settings_organization.members.role_label.instructor', 'Instructor'),
    value: MemberRole.INSTRUCTOR,
  },
]

export const getSaigeAccessOptions = (t: TFunction): SelectOption<boolean>[] => [
  { label: t('settings_organization.members.saige_label.true', 'Enabled'), value: true },
  {
    label: t('settings_organization.members.saige_label.false', 'No access'),
    value: false,
  },
]
