import classNames from 'classnames'
import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ModalPortal } from '../../../../common/components/Portal/Portal'
import { Button } from '../../../../common/components/button/Button'
import { useTrapFocus } from '../../../../hooks/useTrapFocus'
import { isFirefox } from '../../../../util/browserSpecifics'
import { getBoardFileNameFromUrl } from '../../helpers'
import { GameBoard } from '../../types'
import styles from './AddGameBoardsModal.module.css'
import { UploadGameBoard } from './UploadGameBoard'

type AddGameBoardsModalProps = {
  previouslySelectedBoards: GameBoard[]
  allGameBoards: GameBoard[]
  onClickAddSelection: (selectedGameBoards: GameBoard[]) => void
  onClickBack: () => void
  onUploadGameBoard: (newBoard: GameBoard) => void
  isPanorama: boolean
  isBranching: boolean
  isExploration: boolean
}

export const AddGameBoardsModal: React.FC<AddGameBoardsModalProps> = ({
  allGameBoards,
  previouslySelectedBoards,
  onClickAddSelection,
  onClickBack,
  onUploadGameBoard,
  isPanorama,
  isBranching,
  isExploration,
}) => {
  const { t } = useTranslation()
  const modalRef = useTrapFocus<HTMLDivElement>()
  const [selectedGameBoards, setSelectedGameBoards] = useState<GameBoard[]>(previouslySelectedBoards)
  const maxBoardCount = isBranching ? 1 : isExploration ? 10 : 4

  const handleChangeSelectedBoard = (gameBoard: GameBoard) => (e: React.SyntheticEvent<HTMLInputElement>) => {
    const { checked } = e.currentTarget
    if (checked) {
      setSelectedGameBoards((prevState) => [...prevState, gameBoard])
    } else {
      setSelectedGameBoards((prevState) => prevState.filter(({ url }) => url !== gameBoard.url))
    }
  }

  const isBoardSelected = useCallback(
    (boardUrl: string) => {
      return selectedGameBoards.some(({ url }) => getBoardFileNameFromUrl(url) === getBoardFileNameFromUrl(boardUrl))
    },
    [selectedGameBoards],
  )

  const onClickAddSelectionInternal = () => {
    onClickAddSelection(selectedGameBoards)
  }

  const onUploadGameBoardInternal = useCallback(
    (newBoard: GameBoard) => {
      onUploadGameBoard(newBoard)
      setSelectedGameBoards((prev) => [...prev, newBoard])
    },
    [onUploadGameBoard],
  )

  return (
    <ModalPortal>
      <div className={styles.backdrop}>
        <div ref={modalRef} className={styles.addGameBoardsModal}>
          <Button variant='borderless-normal' onClick={onClickBack}>
            {t('game_editor.game_boards.back_button', 'Back')}
          </Button>
          <h1>{t('game_editor.game_boards.add_board_title', 'Add game boards')}</h1>
          <h4>{t('game_editor.game_boards.add_board_subtitle', 'Choose a premade board or upload your own')}</h4>
          <UploadGameBoard
            onUploadSuccessful={onUploadGameBoardInternal}
            disabled={selectedGameBoards.length >= maxBoardCount}
            isPanorama={isPanorama}
          />
          <div className={styles.boardsGrid}>
            {allGameBoards.map((board) => {
              const isSelected = isBoardSelected(board.url)
              return (
                <label
                  className={classNames(styles.selectableBoard, isSelected && styles.selectableBoardActive)}
                  key={board.fileName + board.name}
                >
                  {selectedGameBoards.length >= maxBoardCount && !isSelected && <div className={styles.dimOverlay} />}
                  <input
                    className={classNames(styles.originalCheckbox, isFirefox() && styles.originalCheckboxFF)}
                    id={board.url}
                    name={board.name}
                    type='checkbox'
                    onChange={handleChangeSelectedBoard(board)}
                    checked={isSelected}
                    disabled={selectedGameBoards.length >= maxBoardCount && !isSelected}
                  />
                  <img
                    src={board.url}
                    alt={board.name}
                    className={classNames(styles.gameBoard, isPanorama && styles.thumbnailImageCover)}
                  />
                  <span className={styles.boardName}>{board.name}</span>
                </label>
              )
            })}
          </div>
          <Button
            variant='big'
            disabled={!previouslySelectedBoards.length && !selectedGameBoards.length}
            onClick={onClickAddSelectionInternal}
          >
            {t('game_editor.game_boards.apply_selection', 'Apply selection')}
          </Button>
        </div>
      </div>
    </ModalPortal>
  )
}
