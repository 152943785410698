import { useTranslation } from 'react-i18next'
import { hideDistinctMessage, isMessageHidden, WelcomeBlockData } from '../helpers'
import { WelcomeBanner } from './WelcomeBanner'
import SvgWelcomeDay04Icon from '../../../common/components/icons/WelcomeDay04Icon'
import { useUser } from '../../../contexts/userContext'
import { useCallback } from 'react'
import { HIDDEN_MESSAGES } from '../constants'
import { useStorage } from '../../../hooks/useStorage'

type LtiBannerProps = {}

export const LtiBanner: React.FC<LtiBannerProps> = () => {
  const { user } = useUser()
  const { t } = useTranslation()
  const { storedValue, storeValue } = useStorage<string[] | null>(HIDDEN_MESSAGES, null)
  const id = user?.ltiContext ?? 'lti_default'

  let genericeBannerData: WelcomeBlockData | undefined = undefined

  const handleCloseInternal = useCallback(() => {
    hideDistinctMessage(id, storedValue, storeValue)
  }, [id, storeValue, storedValue])

  if (storedValue != null && isMessageHidden(id, storedValue)) {
    return null
  }

  if (user?.ltiContext) {
    genericeBannerData = {
      id: user?.ltiContext + '',
      title: t('lti_banner.title', 'You are using an LMS integration'),
      body: t(
        'lti_banner.body',
        'Use the activate buttons in the game cards to control which game is linked to the game',
      ),
      primaryButton: {
        label: t('lti_banner.help_button', 'Read more'),
        type: 'link-external',
        link:
          user.language === 'fi'
            ? 'https://manual.seppo.io/fi/peliopas/lms-integraatio/'
            : 'https://manual.seppo.io/manual/lms-integration/',
        icon: 'help',
      },
    }
    return (
      <WelcomeBanner
        injectRawBody
        onClose={handleCloseInternal}
        icon={<SvgWelcomeDay04Icon />}
        data={genericeBannerData}
      />
    )
  } else {
    return null
  }
}
