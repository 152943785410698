import classNames from 'classnames'
import DOMPurify from 'dompurify'
import { useCallback, useState } from 'react'
import { Field, useField } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import { EditorFormField } from '../../../../../../../../../common/components/Form/EditorFormField/EditorFormField'
import SvgScoreCircledIcon from '../../../../../../../../../common/components/icons/ScoreCircledIcon'
import { getIcon } from '../../../../../../../../../common/components/icons/utils'
import { AnswerStateEnum } from '../../../../../../../../../types/commonTypes'
import { getHtmlText, safeIsNullOrEmpty } from '../../../../../../../../../util/string'
import { PointsInput } from '../../../../../../Points/PointsInput'
import { TimeExceededInfo } from '../TimeExceededInfo'
import styles from './CreativeAnswer.module.css'
import { PERCENTAGES, PointPercentageButtons } from './PointPercentageButtons'
import { CannedMessages } from '../CannedMessages'

type CreativeAnswerEvaluateProps = {
  guidelines?: string
  maxPoints?: number
  noPointsGame?: boolean
  name: string
  state: AnswerStateEnum
  gameId?: number
  existingComment: string | null
  defaultFeedback?: string
}

const getRoundedPercentageValue = (percentage: number, maxValue?: number) => {
  return Math.round((maxValue ?? 0) * (percentage / 100))
}

const isActivePercentageOfValue = (percentage: number, value: number | null, maxValue?: number) => {
  return getRoundedPercentageValue(percentage, maxValue) === value
}

export const CreativeAnswerEvaluate: React.FC<CreativeAnswerEvaluateProps> = ({
  guidelines,
  noPointsGame,
  maxPoints,
  name,
  state,
  gameId,
  existingComment,
  defaultFeedback = '',
}) => {
  const { t } = useTranslation()
  const [guidelinesExpanded, setGuidelinesExpanded] = useState<boolean>(true)
  const toggleGuidelinesExpanded = () => setGuidelinesExpanded((prev) => !prev)
  const { input } = useField<number>(`${name}.points`)
  const [inputValue, setInputValue] = useState(existingComment ?? '')
  const [manuallyChangedValue, setManuallyChangedValue] = useState<string | null>(null)
  const [activePercentage, setActivePercentage] = useState<number | undefined>(
    PERCENTAGES.find((percentage) => isActivePercentageOfValue(percentage, input.value, maxPoints)),
  )
  const onActivePercentageChange = (percentage: number) => {
    setActivePercentage(percentage)
    input.onChange(getRoundedPercentageValue(percentage, maxPoints))
  }

  const appendInputValue = useCallback(
    (inputValue: string) => {
      setInputValue((prev) => {
        if (
          !manuallyChangedValue &&
          defaultFeedback.length > 0 &&
          getHtmlText(defaultFeedback ?? '') === getHtmlText(existingComment ?? '')
        )
          return inputValue
        return (manuallyChangedValue === null ? prev : manuallyChangedValue).concat(' ', inputValue)
      })
      setManuallyChangedValue(null)
    },
    [defaultFeedback, existingComment, manuallyChangedValue],
  )

  const manuallyChanged = useCallback((value: string) => {
    setManuallyChangedValue(value)
  }, [])

  return (
    <div className={styles.creativeEvaluation}>
      <div className={styles.evaluationTitle}>
        {t('game_editor.sidebar.evaluate_answer_panel.instructor_evaluation', 'Instructor evaluation')}
      </div>
      {state === AnswerStateEnum.TIME_FINISHED ? (
        <TimeExceededInfo
          title={t(
            'game_editor.sidebar.evaluate_answer_panel.time_exceeded_player_out_of_time',
            'Player ran out of time',
          )}
          subtitle={t(
            'game_editor.sidebar.evaluate_answer_panel.time_exceeded_can_request_revision',
            'You can ask for a revised answer',
          )}
        />
      ) : (
        <>
          {!safeIsNullOrEmpty(guidelines) && (
            <div className={classNames(styles.evaluationGuidelinesContainer, 'tiny')}>
              <div className={styles.evaluationGuidelinesHeader}>
                <span onClick={toggleGuidelinesExpanded}>
                  {t('game_editor.tasks.evaluation_guidelines', 'Evaluation guidelines')}
                </span>
                <button
                  className={classNames(guidelinesExpanded && styles.iconExpanded)}
                  type='button'
                  onClick={toggleGuidelinesExpanded}
                >
                  {getIcon('arrowDown')}
                </button>
              </div>
              {guidelinesExpanded && (
                <div
                  className={styles.evaluationGuidelinesContent}
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(guidelines ?? ''),
                  }}
                />
              )}
            </div>
          )}
          <Field component='input' type='hidden' name={`${name}.id`} />
          {!noPointsGame && (
            <>
              <div className={styles.pointsInputHeader}>
                <span className={styles.pointsLabel}>
                  {t('game_editor.sidebar.evaluate_answer_panel.points_label', 'Points to award')}
                </span>
                <span className={classNames('tiny', styles.maxPoints)}>
                  <SvgScoreCircledIcon className={styles.scoreIcon} width={14} height={14} />
                  <span className='bold uppercase'>
                    {t('game_editor.sidebar.evaluate_answer_panel.max_points', {
                      defaultValue: 'Max %{max_points}',
                      max_points: maxPoints,
                    })}
                  </span>
                </span>
              </div>
              {state === AnswerStateEnum.GRADED && (
                <div className={styles.gradedWarning}>
                  {t(
                    'game_editor.sidebar.evaluate_answer_panel.already_graded_warning',
                    'Warning! You have already graded this answer. Are you sure you want to grade it again?',
                  )}
                </div>
              )}
              <div className={styles.pointsContainer}>
                <PointsInput
                  disabled={state === AnswerStateEnum.REVISION}
                  name={`${name}.points`}
                  validateOnlyIfDirty
                />
                <PointPercentageButtons
                  onChange={onActivePercentageChange}
                  activePercentage={activePercentage}
                  name={name}
                  disabled={state === AnswerStateEnum.REVISION}
                />
              </div>
            </>
          )}
          <EditorFormField
            initProps={{ height: 200 }}
            onlyBasicOptions
            name={`${name}.comment`}
            label={t('game_editor.sidebar.evaluate_answer_panel.feedback_label', 'Feedback')}
            fieldContainerClassName={styles.fieldContainer}
            validateOnlyIfDirty
            onEditorChange={manuallyChanged}
            value={inputValue}
            withEmojis
          />
          <CannedMessages gameId={gameId} setInputValue={appendInputValue} />
        </>
      )}
    </div>
  )
}
