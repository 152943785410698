import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styles from './Engine.module.css'
import { useUser } from '../../contexts/userContext'
import { Select, SelectOption } from '../../common/components/Select/Select'
import { EngineRunState, getRunState } from './EngineHelper'
import { InstructionsAndForm } from './InstructionsAndForm'
import { GeneratingStatus } from './GeneratingStatus'
import { LoginStep } from './LoginStep'
import { ENGINE_LANGUAGE_SELECT_DEFAULT_OPTION, ENGINE_LANGUAGE_SELECT_OPTIONS } from '../../common/constants'
import { SingleValue } from 'react-select'
import i18n from '../../i18n'
import { ChooseStep } from './ChooseStep'
import { GeneratedStep } from './GeneratedStep'
import { Button } from '../../common/components/button/Button'
import { useNotification } from '../../contexts/NotificationContext'
import { useConfirmation } from '../../contexts/ConfirmationContext'

enum EngineStep {
  LOGIN = 'LOGIN',
  FILL_FORM = 'FILL_FORM',
  GENERATE = 'GENERATE',
  CHOOSE = 'CHOOSE',
  FINAL = 'FINAL',
}

export type EngineForm = {
  gameName: string
  instructorToAdd: string
  generatorPin: string
  inputFile: File | string | undefined
  language: 'English' | 'Finnish' | 'Portuguese' | 'Dutch'
  template: 'plain' | 'news_desk' | 'tapas_fiesta' | 'delivery' | 'triathlon' | 'random'
}

const TOTAL_STEPS_COUNT = 3

export const Engine: React.FC = () => {
  const { t } = useTranslation()
  const { user } = useUser()
  const [mainStep, setMainStep] = useState(EngineStep.LOGIN)
  const [stepIndex, setStepIndex] = useState(1)
  const [initialValues] = useState<Partial<EngineForm>>({
    gameName: '',
    instructorToAdd: user?.name,
    generatorPin: '',
    inputFile: undefined,
    language: 'English',
    template: 'random',
  })
  const [formData, setFormData] = useState(initialValues)
  const intervalPoller = useRef(0)
  const [engineRunId, setEngineRunId] = useState(0)
  const [runState, setRunState] = useState<Partial<EngineRunState> | null>({
    fileUploaded: false,
    matchPairsCreated: false,
    multiChoiceCreated: false,
    checkBoxCreated: false,
    missingWordCreated: false,
    creativeCreated: false,
  })
  const [statusText, setStatusText] = useState(t('engine.general_status.start', 'Game not generated yet'))
  const [errorStatus, setErrorStatus] = useState<string | null>(null)
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null)
  const [gameId, setGameId] = useState(0)
  const { notifyError } = useNotification()
  const errorShown = useRef(false)
  const { requestConfirmation } = useConfirmation()
  const [has3D, setHas3D] = useState(false)

  useEffect(() => {
    if (engineRunId > 0) {
      intervalPoller.current = window.setInterval(async function () {
        const getIt = await fetch(`${process.env.REACT_APP_ENGINE_BACKEND}/engine_file?run_id=${engineRunId}`)
        const content = await getIt.text()
        const rState = getRunState(content)
        if (rState.error) {
          setErrorStatus(rState.error)
          window.clearInterval(intervalPoller.current)
        }

        if (rState.selectionOptions && mainStep !== EngineStep.CHOOSE && mainStep !== EngineStep.FINAL) {
          setMainStep(EngineStep.CHOOSE)
          setStatusText(t('engine.general_status.customize', 'Game building blocks generated'))
        }
        if (rState.allReady) {
          setGameId(rState.gameId ?? 0)
          window.clearInterval(intervalPoller.current)
        }
        setRunState(rState)
      }, 2000)
    }

    return () => {
      window.clearInterval(intervalPoller.current)
    }
  }, [engineRunId, mainStep, t])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [mainStep])

  const handleStartAgain = useCallback(() => {
    if (intervalPoller.current) window.clearInterval(intervalPoller.current)
    setTimeout(() => {
      errorShown.current = false
    }, 2000)
    setEngineRunId(0)
    setErrorStatus(null)
    setStepIndex(1)
    setMainStep(EngineStep.LOGIN)
    setStatusText(t('engine.general_status.start', 'Game not generated yet'))
  }, [t])

  useEffect(() => {
    if (errorShown.current) return
    if (errorStatus?.includes('Invalid') || errorStatus?.includes('not found')) {
      errorShown.current = true
      handleStartAgain()
      notifyError({
        customDismissLabel: t('engine.error.try_again', 'Try again'),
        title: t('engine.error.pin_or_account.title', 'Pin or account not found'),
        content: (
          <div className={styles.notificationContent}>
            {t(
              'engine.error.pin_or_account.content',
              'We weren’t able to verify your PIN or account. Please double-check and try again or reach out if you need support!',
            )}
          </div>
        ),
      })
    } else if (errorStatus?.includes('No rounds left')) {
      errorShown.current = true
      handleStartAgain()
      notifyError({
        title: t('engine.error.no_rounds.title', 'Out of rounds!'),
        content: (
          <div className={styles.notificationContent}>
            {t(
              'engine.error.no_rounds.content',
              'Your attempts have been used up. Reach out to our support and get more!',
            )}
          </div>
        ),
      })
    } else if (errorStatus) {
      errorShown.current = true
      handleStartAgain()
      notifyError({
        customDismissLabel: t('engine.error.try_again', 'Try again'),
        title: t('engine.error.generic.title', 'Uh-oh! It seems I need a moment'),
        content: (
          <div className={styles.notificationContent}>
            {t(
              'engine.error.generic.content',
              'An unexpected error occurred, but don’t worry — no engine rounds were lost! Please wait 5 minutes and try again.',
            )}
          </div>
        ),
      })
    }
  }, [errorStatus, handleStartAgain, notifyError, t])

  const handleSubmit = useCallback(
    async (values: EngineForm) => {
      if (mainStep === EngineStep.LOGIN) {
        const pinResponse = await fetch(
          `https://seppo-proxy.herokuapp.com/${process.env.REACT_APP_OLD_UI_BASE_URL}/engine_pin_check_only.json?email=${values.instructorToAdd}&pin_code=${values.generatorPin}`,
        )
        const reply = await pinResponse.text()
        if (!reply.includes('OK')) {
          setErrorStatus(reply)
          return
        }
        if (reply.includes('HAS_3D')) setHas3D(true)
        setFormData({ ...formData, ...values })
        setMainStep(EngineStep.FILL_FORM)
        setStepIndex(2)
      } else {
        setFormData({ ...formData, ...values })
        setMainStep(EngineStep.GENERATE)
        // Do the actual submitting here
        const xhr = new XMLHttpRequest()
        xhr.open('POST', `${process.env.REACT_APP_ENGINE_BACKEND}/engine_submit`)
        xhr.onload = function (event: any) {
          if (event.target.response.includes('Error occurred')) {
            setErrorStatus(event.target.response)
          } else {
            console.log('Success, server responded with: ' + event.target.response)
            const roundsLeft = parseInt(event.target.response.split('rounds left: ')[1])
            const temp =
              t('engine.general_status.generating', 'Game is being generated...') +
              ' ' +
              (isNaN(roundsLeft)
                ? ''
                : t('engine.general_status.rounds_left', 'Rounds left in your license:') + ' ' + roundsLeft)
            setStatusText(temp)
            setEngineRunId(event.target.response.split('=')[1])
          }
        }
        const engineForm = document.getElementById('ENGINE_FORM') as HTMLFormElement
        if (engineForm) {
          var formContent = new FormData(engineForm)
          xhr.send(formContent)
        }
      }
    },
    [formData, mainStep, t],
  )

  const handleSelectStyle = useCallback(
    (selectedIndex: number) => {
      setStepIndex(3)
      setMainStep(EngineStep.FINAL)
      setSelectedIndex(selectedIndex)
      setStatusText(t('engine.general_status.final', 'Game saved to Seppo Dashboard'))
      var xhr = new XMLHttpRequest()
      xhr.open(
        'GET',
        `${process.env.REACT_APP_ENGINE_BACKEND}/engine_select?run_id=${engineRunId}&selected_id=${selectedIndex}`,
      )
      xhr.send()
    },
    [engineRunId, t],
  )

  const validate = useCallback(
    (values: EngineForm): Partial<EngineForm> => {
      if (mainStep === EngineStep.LOGIN) {
        return {
          generatorPin: !values.generatorPin ? t('engine.value_required', 'Required field') : undefined,
          instructorToAdd: !values.instructorToAdd ? t('engine.value_required', 'Required field') : undefined,
        }
      }
      if (mainStep === EngineStep.FILL_FORM) {
        return {
          gameName: !values.gameName ? t('engine.value_required', 'Required field') : undefined,
        }
      }
      return {}
    },
    [mainStep, t],
  )

  const handleChangeLanguage = (selectOption: SingleValue<SelectOption>) => {
    i18n.changeLanguage(selectOption?.value ?? ENGINE_LANGUAGE_SELECT_DEFAULT_OPTION.value)
  }

  const cancelGeneration = useCallback(async () => {
    const confirm = await requestConfirmation({
      title: t('engine.confirm_cancel_title', 'Confirm canceling'),
      text: t('engine.confirm_cancel_text', 'Are you sure you want to cancel the game generation?'),
      confirmActionText: t('engine.confirm_cancel_button_text', 'Confirm'),
    })
    if (!confirm) {
      return false
    }
    if (engineRunId > 0) {
      await fetch(`${process.env.REACT_APP_ENGINE_BACKEND}/engine_kill?run_id=${engineRunId}`)
    }
    handleStartAgain()
  }, [engineRunId, handleStartAgain, requestConfirmation, t])

  const language =
    ENGINE_LANGUAGE_SELECT_OPTIONS.find((option) => option.value === i18n.language) ??
    ENGINE_LANGUAGE_SELECT_DEFAULT_OPTION

  return (
    <div className={styles.wrapper}>
      <div className={styles.headerRow}>
        <div className={styles.statusText}>{statusText}</div>
        <div className={styles.language}>
          {t('onboarding_wizard.language', 'Language')}
          {': '}
          <Select
            options={ENGINE_LANGUAGE_SELECT_OPTIONS}
            value={language}
            onChange={handleChangeLanguage}
            className={styles.languageSelect}
          />
        </div>
      </div>
      <div className={styles.container}>
        <div className={styles.top}>
          <img
            style={{ width: '90px' }}
            alt='Seppo'
            src='https://s3-eu-west-1.amazonaws.com/smartfeet-assets-production/assets/seppo.png'
          />
        </div>
        <div className={styles.mainAreaContainer}>
          {mainStep === EngineStep.LOGIN && (
            <LoginStep user={user} onSubmit={handleSubmit} validate={validate} initialValues={formData} />
          )}
          {mainStep === EngineStep.FILL_FORM && (
            <InstructionsAndForm onSubmit={handleSubmit} validate={validate} initialValues={formData} has3D={has3D} />
          )}
          {mainStep === EngineStep.GENERATE && <GeneratingStatus runState={runState} />}
          {mainStep === EngineStep.CHOOSE && runState?.selectionOptions && (
            <ChooseStep selectionOptions={runState?.selectionOptions} onSelect={handleSelectStyle} />
          )}
          {mainStep === EngineStep.FINAL && (
            <GeneratedStep
              runState={runState}
              gameName={formData.gameName ?? ''}
              gameId={gameId}
              gameMapUrl={runState?.selectionOptions?.image_urls[selectedIndex ?? 0] ?? ''}
            />
          )}
        </div>
        <div className={styles.stepDisplay}>
          {t('onboarding_wizard.step', 'Step')} {stepIndex} / {TOTAL_STEPS_COUNT}
        </div>
        {stepIndex === 2 && (
          <div className={styles.stepDisplay}>
            <Button variant='outline-tiny' onClick={cancelGeneration}>
              {t('engine.cancel', 'Cancel generation')}
            </Button>
          </div>
        )}
      </div>
    </div>
  )
}
