import classNames from 'classnames'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useGame } from '../../../../../../../../contexts/GameContext'
import styles from './CannedMessages.module.css'
import { getIcon } from '../../../../../../../../common/components/icons/utils'
import { Button } from '../../../../../../../../common/components/button/Button'
import { EditorFormField } from '../../../../../../../../common/components/Form/EditorFormField/EditorFormField'
import { CannedMessageList } from './CannedMessageList'

type CannedMessagesProps = {
  gameId?: number
  setInputValue: (inputValue: string) => void
}

export const CannedMessages: React.FC<CannedMessagesProps> = ({ gameId, setInputValue }) => {
  const { t } = useTranslation()

  const [isExpanded, setIsExpanded] = useState<boolean>(true)
  const [toBeCannedValue, setToBeCannedValue] = useState('')
  const [toBeCannedId, setToBeCannedId] = useState<number | null>(null)
  const [initialToBeCannedValue, setInitialToBeCannedValue] = useState<string | null>(null)
  const [showGameOrPersonal, setShowGameOrPersonal] = useState<'game' | 'personal'>('game')
  const {
    cannedUserMessages,
    postCannedUserMessage,
    deleteCannedUserMessage,
    cannedGameMessages,
    postCannedGameMessage,
    deleteCannedGameMessage,
  } = useGame()

  const toggleIsExpanded = () => setIsExpanded((prev) => !prev)

  const handleSetShowGameOrPersonal = (state: 'game' | 'personal') => {
    setShowGameOrPersonal(state)
    setToBeCannedId(null)
  }

  const handleEditMessage = (id: number, text: string) => {
    setToBeCannedId(id)
    handleSetToBeCannedValue(text)
    setInitialToBeCannedValue(text)
  }

  const handleDeleteMessage = (id: number) => {
    if (showGameOrPersonal === 'game') deleteCannedGameMessage(id)
    else deleteCannedUserMessage(id)
  }

  const handleSelectMessage = (text: string) => {
    setInputValue(text)
  }

  const handleSaveMessage = () => {
    if (toBeCannedValue && toBeCannedValue.trim().length !== 0) {
      if (showGameOrPersonal === 'game') postCannedGameMessage(toBeCannedId, toBeCannedValue)
      else postCannedUserMessage(toBeCannedId, toBeCannedValue)
    }
    setToBeCannedId(null)
    setToBeCannedValue('')
    setInitialToBeCannedValue('')
  }

  const handleSetToBeCannedValue = (text: string) => {
    setToBeCannedValue(text)
    if (text.trim().length === 0) {
      setToBeCannedId(null)
    }
  }

  return (
    <div className={classNames(styles.cannedMessagesContainer, 'tiny')}>
      <div className={styles.cannedMessagesHeader}>
        <span onClick={toggleIsExpanded}>
          <label className={classNames(styles.label)}>{t('canned_messages.title', 'Saved feedback')}</label>
        </span>
        <button className={classNames(isExpanded && styles.iconExpanded)} type='button' onClick={toggleIsExpanded}>
          {getIcon('arrowDown')}
        </button>
      </div>
      {isExpanded && (
        <div className={styles.cannedMessagesContent}>
          <div className={styles.tabsContainer}>
            <button
              className={classNames(styles.tab, showGameOrPersonal === 'game' && styles.selectedTab)}
              type='button'
              onClick={() => handleSetShowGameOrPersonal('game')}
            >
              {t('canned_messages.tabs.game', 'Game')}
            </button>
            <button
              className={classNames(styles.tab, showGameOrPersonal === 'personal' && styles.selectedTab)}
              type='button'
              onClick={() => handleSetShowGameOrPersonal('personal')}
            >
              {t('canned_messages.tabs.personal', 'Personal')}
            </button>
          </div>
          <div className={styles.selectedTabContainer}>
            <div className={styles.infoContainer}>
              <div className={styles.infoIcon}>{getIcon('info')}</div>
              <p className={styles.infoMessage}>
                {showGameOrPersonal === 'game'
                  ? t(
                      'canned_messages.game_tab_info',
                      'Saved messages on the game tab are shared with all the instructors of the game',
                    )
                  : t(
                      'canned_messages.personal_tab_info',
                      'Saved messages on personal tab are visible for you in all the games you instruct',
                    )}
              </p>
            </div>
            <CannedMessageList
              editMessage={handleEditMessage}
              deleteMessage={handleDeleteMessage}
              selectMessage={handleSelectMessage}
              messages={showGameOrPersonal === 'game' ? cannedGameMessages : cannedUserMessages}
            />
            <EditorFormField
              initProps={{ height: 200 }}
              onlyBasicOptions
              name={'save message'}
              label={t('canned_messages.input_label', 'Create a feedback to be saved')}
              fieldContainerClassName={styles.fieldContainer}
              validateOnlyIfDirty
              onEditorChange={(value) => handleSetToBeCannedValue(value)}
              value={initialToBeCannedValue}
              withEmojis
            />
            <Button className={styles.saveButton} variant='tiny' type='button' onClick={handleSaveMessage}>
              {t('canned_message.save_canned', 'Save feedback')}
            </Button>
          </div>
        </div>
      )}
    </div>
  )
}
