import { Try, toFailure, toSuccess } from '../types/commonTypes'
import { createAxios } from './axios'
import {
  AddMembersParams,
  DeleteMemberParams,
  GetBusinessSettingsParams,
  GetBusinessSettingsResponse,
  UpdateAllMembersSaigeParams,
  UpdateMemberRoleParams,
} from './businessSettingsTypes'
import { TEST_USER_AUTHORIZATION } from './userApiService'

const ADD_MEMBERS_MESSAGE = 'USE_BACKEND_DEFAULT'

export async function getBusinessSettingsData({
  authorization = TEST_USER_AUTHORIZATION,
  businessId,
}: GetBusinessSettingsParams): Promise<Try<GetBusinessSettingsResponse>> {
  try {
    const url = `/admin/businesses/${businessId}.json?ux3=true`
    const { data } = await createAxios(authorization).get<GetBusinessSettingsResponse>(url)
    return toSuccess(data)
  } catch (e: any) {
    return toFailure(e)
  }
}

export async function addMembersApi({
  authorization = TEST_USER_AUTHORIZATION,
  businessId,
  members,
  message = ADD_MEMBERS_MESSAGE,
}: AddMembersParams): Promise<Try<undefined>> {
  try {
    const url = `/admin/businesses/${businessId}/member`
    await createAxios(authorization).post<undefined>(url, {
      business_id: businessId.toString(),
      members,
      message1: message,
    })
    return toSuccess(undefined)
  } catch (e: any) {
    return toFailure(e)
  }
}

export async function changeMemberAdminRoleApi({
  authorization = TEST_USER_AUTHORIZATION,
  businessId,
  userName,
}: UpdateMemberRoleParams): Promise<Try<undefined>> {
  try {
    const url = `/admin/businesses/${businessId}/member_toggle_admin`
    await createAxios(authorization).post<undefined>(url, {
      username: userName,
    })
    return toSuccess(undefined)
  } catch (e: any) {
    return toFailure(e)
  }
}

export async function changeMemberSaigeAccessApi({
  authorization = TEST_USER_AUTHORIZATION,
  businessId,
  userName,
}: UpdateMemberRoleParams): Promise<Try<undefined>> {
  try {
    const url = `/admin/businesses/${businessId}/member_toggle_saige_access`
    await createAxios(authorization).post<undefined>(url, {
      username: userName,
    })
    return toSuccess(undefined)
  } catch (e: any) {
    return toFailure(e)
  }
}

export async function changeAllMemberSaigeAccessApi({
  authorization = TEST_USER_AUTHORIZATION,
  businessId,
  enabledState,
}: UpdateAllMembersSaigeParams): Promise<Try<undefined>> {
  try {
    const url = `/admin/businesses/${businessId}/member_toggle_saige_access_all`
    await createAxios(authorization).post<undefined>(url, {
      enabled_state: enabledState,
    })
    return toSuccess(undefined)
  } catch (e: any) {
    return toFailure(e)
  }
}

export async function removeMemberApi({
  authorization = TEST_USER_AUTHORIZATION,
  businessId,
  userName,
}: DeleteMemberParams): Promise<Try<undefined>> {
  try {
    const url = `/admin/businesses/${businessId}/member_remove`
    await createAxios(authorization).post<undefined>(url, {
      username: userName,
      remove: true,
    })
    return toSuccess(undefined)
  } catch (e: any) {
    return toFailure(e)
  }
}

export async function getQuotaUpdate({
  authorization = TEST_USER_AUTHORIZATION,
  businessId,
}: GetBusinessSettingsParams): Promise<Try<GetBusinessSettingsResponse>> {
  try {
    const url = `/admin/businesses/${businessId}/quota_usage.json?ux3=true`
    const { data } = await createAxios(authorization).get<GetBusinessSettingsResponse>(url)
    return toSuccess(data)
  } catch (e: any) {
    return toFailure(e)
  }
}
