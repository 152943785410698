import { useTranslation } from 'react-i18next'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { getIcon } from '../../../common/components/icons/utils'
import { HelpMenu } from '../../../composites/HelpMenu/HelpMenu'
import { useGame } from '../../../contexts/GameContext'
import { useTheme } from '../../../contexts/ThemeContext'
import { useUser } from '../../../contexts/userContext'
import { getOldUIRoutes, routes } from '../../../routes'
import { EditorPreference, setShouldOpenWizardOnGameOpen } from '../../../util/editorPreference'
import { UserMenu } from '../components/Header/UserMenu'
import styles from './LegacyEditor.module.css'
import { useCallback, useEffect, useMemo } from 'react'
import { ExerciseType } from '../../../api/gameTypes'

export const LegacyGameEditor: React.FC = () => {
  const navigate = useNavigate()
  const { gameId } = useParams()
  const [searchParams] = useSearchParams()
  const { logoUrl } = useTheme()
  const { gameData, editorPermissions } = useGame()
  const { t } = useTranslation()
  const { updateEditorPreference } = useUser()

  const handleNavigateBack = () => navigate(-1)
  const handleGoToDashboard = () => navigate(routes.homepage)
  const handleUseNewEditor = useCallback(() => {
    if (window.location.href.includes('is_new') && gameId) setShouldOpenWizardOnGameOpen(gameId)
    updateEditorPreference(EditorPreference.NEW)
  }, [gameId, updateEditorPreference])

  const hasExploreTask = useMemo(() => {
    return (
      gameData &&
      gameData.tasks &&
      gameData.tasks.filter(
        (task) => task.subtasks.filter((subtask) => subtask.type === ExerciseType.ExploreExercise).length > 0,
      ).length > 0
    )
  }, [gameData])

  useEffect(() => {
    if (gameData?.gameBoardSettings.is3D || hasExploreTask) {
      handleUseNewEditor()
    }
  }, [gameData?.gameBoardSettings.is3D, handleUseNewEditor, hasExploreTask])

  if (gameId == null) {
    return null
  }

  const url = getOldUIRoutes.gameEditor(gameId, searchParams.get('is_new') === 'true')

  return (
    <div className={styles.mainContainer}>
      <div className={styles.topbar}>
        <div className={styles.topbarSection}>
          <button onClick={handleNavigateBack} className={styles.backButton}>
            {getIcon('backIcon')}
          </button>
          <img onClick={handleGoToDashboard} className={styles.logo} width={90} src={logoUrl} alt='logo' />
          {!gameData?.disableNewEditor && (
            <div onClick={handleUseNewEditor} className={styles.useNewEditorLink}>
              {t('game_editor.header.use_new_editor', 'Use the new editor (BETA)')}
            </div>
          )}
        </div>
        <div className={styles.topbarSection}>
          <UserMenu showSettingsLink={editorPermissions.actions.generalSettingsLink} />
          <HelpMenu isLegacyEditor />
        </div>
      </div>
      <div className={styles.editorContainer}>
        <iframe title='Game editor' src={url} className={styles.editorIframe} />
      </div>
    </div>
  )
}
