import * as React from 'react'
import { SVGProps } from 'react'

const SvgSeppoAi = (props: SVGProps<SVGSVGElement>) => (
  <svg width='1.4em' height='1.4em' style={{margin: '-3px'}} fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M4.5 6.76a.75.75 0 0 1 .75-.75h1.5v-1.5a.75.75 0 1 1 1.5 0v1.5h1.5a.75.75 0 1 1 0 1.5h-1.5v1.5a.75.75 0 0 1-1.5 0v-1.5h-1.5a.75.75 0 0 1-.75-.75Zm12.75 12h-.75v-.75a.75.75 0 1 0-1.5 0v.75h-.75a.75.75 0 1 0 0 1.5H15v.75a.75.75 0 0 0 1.5 0v-.75h.75a.75.75 0 0 0 0-1.5Zm5.25-4.5H21v-1.5a.75.75 0 1 0-1.5 0v1.5H18a.75.75 0 1 0 0 1.5h1.5v1.5a.75.75 0 0 0 1.5 0v-1.5h1.5a.75.75 0 0 0 0-1.5Zm-1.939-6L7.501 21.32a1.5 1.5 0 0 1-2.12 0l-1.942-1.939a1.5 1.5 0 0 1 0-2.121L16.501 4.2a1.5 1.5 0 0 1 2.121 0l1.939 1.938a1.5 1.5 0 0 1 0 2.122Zm-5.121 3L13.5 9.32l-9 9 1.94 1.94 9-9ZM19.5 7.2l-1.939-1.94-3 3 1.94 1.94 3-3Z'
      fill={props.color ?? '#607DD9'}
    />
  </svg>
)
export default SvgSeppoAi
