import { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from '../../../../common/components/button/Button'
import { getIcon } from '../../../../common/components/icons/utils'
import { TAddMember, TMember } from '../../../../types/commonTypes'
import sharedStyles from '../../Settings.module.css'
import { AddMembersFormValues, AddMembersModal } from './AddMembersModal'
import styles from './Members.module.css'
import { MembersTable } from './MembersTable'

type MembersProps = {
  organizationName: string
  members: TMember[]
  maxMembers?: number
  limitMembers?: boolean
  isLoading: boolean
  onChangeMemberRole: (userName: string) => Promise<boolean>
  onToggleMemberSaigeAccess: (userName: string) => Promise<boolean>
  onRemoveMember: (userName: string) => Promise<boolean>
  onAddMembers: (members: TAddMember[], message: string) => Promise<boolean>
  onViewAllMembers?: () => void
  isShowingAll?: boolean
  orgHasSaigeAccess: boolean
  saigeAllMembers: boolean
}

export const Members: React.FC<MembersProps> = ({
  organizationName,
  members,
  maxMembers,
  limitMembers = true,
  onChangeMemberRole,
  onToggleMemberSaigeAccess,
  onRemoveMember,
  onViewAllMembers,
  onAddMembers,
  isLoading,
  isShowingAll,
  orgHasSaigeAccess,
  saigeAllMembers,
}) => {
  const { t } = useTranslation()
  const [isAddMembersModalOpen, setIsAddMembersModalsOpen] = useState<boolean>(false)
  const addMemberButtonRef = useRef<HTMLButtonElement>(null)

  const handleSubmitAddMembers = (values: AddMembersFormValues) => {
    return onAddMembers(values.members, values.message)
  }

  const handleCloseAddMembersModal = () => {
    setIsAddMembersModalsOpen(false)
    addMemberButtonRef.current?.focus()
  }

  return (
    <div className={sharedStyles.section}>
      <div className={styles.topContainer}>
        <h1 className={sharedStyles.sectionTitle}>
          {t('settings_organization.members.title', {
            organization_name: organizationName,
            defaultValue: '%{organization_name} members',
          })}
        </h1>
        {isShowingAll && (
          <Button
            ref={addMemberButtonRef}
            variant='outline-tiny'
            onClick={() => setIsAddMembersModalsOpen(true)}
            className={styles.showingAllAddNewButton}
          >
            <span className='iconWrapperMedium'>{getIcon('plus')}</span>
            {t('settings_organization.members.add_members.button_text.add_new', 'Add new')}
          </Button>
        )}
      </div>
      <MembersTable
        isLoading={isLoading}
        members={members}
        onRemoveMember={onRemoveMember}
        onChangeMemberRole={onChangeMemberRole}
        onToggleMemberSaigeAccess={onToggleMemberSaigeAccess}
        orgHasSaigeAccess={orgHasSaigeAccess}
        saigeAllMembers={saigeAllMembers}
      />
      {!isShowingAll && (
        <div className={styles.actionsContainer}>
          <Button ref={addMemberButtonRef} variant='outline-tiny' onClick={() => setIsAddMembersModalsOpen(true)}>
            <span className='iconWrapperMedium'>{getIcon('plus')}</span>
            {t('settings_organization.members.add_members.button_text.add_new', 'Add new')}
          </Button>
          <Button ref={addMemberButtonRef} variant='outline-tiny' onClick={onViewAllMembers}>
            <span className='iconWrapperBig'>{getIcon('view')}</span>
            {t('settings_organization.members.add_members.button_text.view_all', 'View all')}
          </Button>
        </div>
      )}
      {isAddMembersModalOpen && (
        <AddMembersModal
          onSubmit={handleSubmitAddMembers}
          onClose={handleCloseAddMembersModal}
          organizationName={organizationName}
          memberCount={members.length}
          maxMembers={maxMembers || 1}
          limitMembers={limitMembers}
          orgHasSaigeAccess={orgHasSaigeAccess}
        />
      )}
    </div>
  )
}
