import {
  DEFAULT_MAP_OPTIONS,
  EMPTY_CONTENT_FALLBACK,
  LEGACY_EXPLORATION_OVERVIEW_SIZE_X,
  LEGACY_EXPLORATION_OVERVIEW_SIZE_Y,
} from '../common/constants'
import { generateUserInitials } from '../composites/UserMenu/helpers'
import { globalAllowNewEditor } from '../contexts/DebugContext'
import { DEFAULT_THEME_SETTINGS } from '../contexts/ThemeContext'
import { Door, GameBoard, GameBoardSettings, MapOptions } from '../pages/GameEditor/types'
import {
  AllowedOption,
  AnswerStateEnum,
  Badge,
  Business,
  CheckboxSubtask,
  CreateAndCommentSubtask,
  CreativeAnswerOption,
  CreativeSubtask,
  DashboardMessage,
  ExploreSubtask,
  Game,
  GameAdvancedSettings,
  GameCreatorFilterOption,
  GameMap,
  GameMapsAndExercises,
  GamePackage,
  GameStatus,
  LevelCriteria,
  MatchPairsSubtask,
  Maybe,
  MemberRole,
  MissingWordSubtask,
  MultipleChoiceSubtask,
  NewBusinessInfo,
  NotificationSettings,
  ObjectAny,
  PaymentInfo,
  PlayerAccount,
  PointsType,
  ReceivedAnswer,
  Report,
  SecondaryGameStatus,
  Subtask,
  SubtaskBase,
  TAddMember,
  TBusinessSettings,
  TGameCard,
  TMember,
  TMessage,
  TUser,
  Tag,
  Task,
  TaskAdvanced,
  ThemeSettings,
  WPInfomercial,
  WordPressData,
} from '../types/commonTypes'
import { isPastDate } from '../util/date'
import { prefersOldEditor } from '../util/editorPreference'
import { getOrderedDescriptionAndAnswers, transformLegacyToUx3Description } from '../util/missingWord'
import { isNullOrZero, safeParseInt } from '../util/number'
import {
  dateTimeToISOString,
  dateTimeToLocalDateString,
  dateTimeToLocalDateTimeString,
  decodeHtml,
  safeIsNullOrEmpty,
  trimDoubleQuotes,
} from '../util/string'
import { AddMember, BusinessSettingsData, Member, PaymentData, PaymentMethod } from './businessSettingsTypes'
import {
  Answer,
  BadgeData,
  CheckGameOrPlayerResponse,
  CheckGameResponse,
  CombineExercise,
  CreativeExercise,
  Exercise,
  ExerciseType,
  ExploreExercise,
  FeedbackMessage,
  GameCreator,
  GamePackageResponse,
  GetGameResponse,
  GetGamesResponse,
  GetLibraryGamesResponse,
  GetWordPressDataResponse,
  LevelCriteriaData,
  LibrarySource,
  MapStructure,
  MapType,
  MissingWordExercise,
  MultichoiceExercise,
  PollExercise,
  TaskIconType,
} from './gameTypes'
import { RawMessage } from './messagingTypes'
import { Tag as ApiTag, ApiTheme, PlayerAccountSearchResponse, RawPlayerAccount, User } from './userTypes'

const getGameStatus = (game: GetGameResponse, librarySource?: LibrarySource, expired?: boolean): GameStatus => {
  if ([librarySource, game.source].includes(LibrarySource.COMMUNITY)) {
    return game.sponsored ? GameStatus.SPONSORED : GameStatus.COMMUNITY
  }
  if ([librarySource, game.source].includes(LibrarySource.ORG)) {
    return game.corporate ? GameStatus.TEMPLATE : GameStatus.LIBRARY
  }
  if ([librarySource, game.source].includes(LibrarySource.TEMPLATE)) {
    return GameStatus.TEMPLATE
  }
  if (expired) {
    return GameStatus.EXPIRED
  }
  if ([librarySource, game.source].includes(LibrarySource.PACKAGE)) {
    return GameStatus.LIBRARY
  }
  if (game.is_archived) {
    return GameStatus.ARCHIVED
  }
  if (game.open) {
    return GameStatus.IN_PROGRESS
  }
  if (!isNullOrZero(game.answers_count)) {
    return GameStatus.PAUSED
  }
  return GameStatus.DRAFT
}

const getGameSecondaryStatus = (
  game: GetGameResponse,
  librarySource?: LibrarySource,
): SecondaryGameStatus | undefined => {
  if (![librarySource, game.source].includes(LibrarySource.OWN)) {
    return undefined
  }
  if (game.imported) {
    return SecondaryGameStatus.DOWNLOADED
  }
  if (game.exported) {
    return SecondaryGameStatus.PUBLISHED
  }
}

export const parseGamesResponseToGameCards = (
  data: GetGamesResponse,
  librarySource?: LibrarySource,
  gamePackageSource?: GamePackage,
): TGameCard[] => {
  return data.my_games.map((game): TGameCard => {
    let validUntil: string | null = null
    if (game.good_trough) validUntil = dateTimeToISOString(game.good_trough.toString())
    else if (gamePackageSource?.validUntil) validUntil = gamePackageSource?.validUntil
    const expired =
      (game.good_trough != null && isPastDate(game.good_trough.toString())) ||
      (validUntil != null && isPastDate(validUntil))
    return {
      id: game.id.toString(),
      gameName: game.name,
      gameOwners: game.owner_names || [game.creator],
      modifiedAt: dateTimeToLocalDateTimeString(game.updated_at?.toString()),
      status: getGameStatus(game, librarySource, expired),
      secondaryStatus: getGameSecondaryStatus(game, librarySource),
      librarySource: librarySource ?? game.source ?? LibrarySource.COMMUNITY,
      gamePackageSource,
      thumbnail:
        game.cover_picture ||
        game.cover_picture_url ||
        game.thumbnail ||
        game.thumbnail_url ||
        game.map_url ||
        game.map,
      exercisesCount: game.exercises?.length ?? 0,
      storyHtml: game.description_html,
      storyEndHtml: game.story_end,
      rulesHtml: game.rules_html ?? game.rules,
      showStoryAndRules: !!game.show_story_rules,
      instructionsForTeacherHtml: game.instructions_for_teacher,
      ages: game.age,
      subjects: game.subject,
      language: game.language,
      mapsCount: (game.additional_maps?.length ?? 0) + 1,
      disableNewEditor: game.disable_ux3_editor && !globalAllowNewEditor,
      ...(!safeIsNullOrEmpty(game.keywords) && { tags: game.keywords.split(',').map((keyword) => keyword.trim()) }),
      ...(game.short_description != null && { description: game.short_description }),
      ...(game.rating_details?.[0] != null && {
        rating: {
          average: game.rating_details[0].av_rating.toFixed(1).replace('.0', ''),
          count: game.rating_details[0].details.length,
        },
      }),
      ...(game.downloaded != null && { downloaded: game.downloaded }),
      ...(game.player_info != null && { playersCount: game.player_info }),
      ...(validUntil && { validUntil }),
      expired,
      has_activation_code: game.activation_code ? true : false,
      ltiContext: game.dikaios_id ?? undefined,
    }
  })
}

export const parseLibraryGamesResponseToGameCards = (
  data: GetLibraryGamesResponse,
  gameType: LibrarySource,
  gamePackage?: GamePackage,
): TGameCard[] => {
  return parseGamesResponseToGameCards(
    {
      my_games: data.games,
      count: data.count,
      active_count: 0,
    },
    gameType,
    gamePackage,
  )
}

export const parseWordPressResponseToWPData = (data: GetWordPressDataResponse): WordPressData => {
  var infomercials: Array<WPInfomercial> = []
  data.forEach((value) => {
    if (value.acf.data_type === 'infomercial')
      infomercials.push({
        imageUrl: value.acf.infomercial_image,
        descriptionEn: value.acf.en,
        descriptionFi: value.acf.fi,
        showDescriptionText: value.acf.show_description_text,
        linkUrl: value.acf.link_url,
      })
  })

  return {
    infomercials: infomercials,
  }
}

export const parseGamePackage = (data: GamePackageResponse): GamePackage => {
  return {
    id: data.game_package.id,
    name: data.game_package.name,
    validUntil: dateTimeToISOString(data.game_package.valid_to),
    defaultInstructor: data.game_package.default_instructor,
  }
}

const getGameMapsForGameResponse = (game: GetGameResponse): GameMap[] => {
  const firstMap: GameMap = {
    url: game.map_url ?? game.map,
  }
  return [
    firstMap,
    ...game.additional_maps.map((map) => ({
      url: map.map_url,
    })),
  ]
}

export const gameCreatorDataToVm = (creators: GameCreator[]): GameCreatorFilterOption[] => {
  return creators.map((creator) => ({ id: creator[0], name: creator[1] }))
}

export const getPointsForExercise = (exercise: Exercise): number => {
  switch (exercise.type) {
    //isArray checks are mostly likely not necessary, but in some old exercises
    case ExerciseType.MissingWordExercise:
      return Array.isArray(exercise.data.points)
        ? exercise.data.points.reduce((sum, optionPoints) => sum + Math.max(0, optionPoints), 0)
        : 0
    case ExerciseType.MultichoiceExercise:
      return Array.isArray(exercise.data.points) ? Math.max(...exercise.data.points) : 0
    case ExerciseType.PollExercise:
      return Array.isArray(exercise.data.points)
        ? exercise.data.points.reduce((sum, optionPoints) => sum + Math.max(0, optionPoints), 0)
        : 0
    case ExerciseType.CollaborationExercise:
      return 0
    default:
      return safeParseInt(exercise.points) ?? 0
  }
}

export const parseGameResponseToGameMapsAndExercises = (game: GetGameResponse): GameMapsAndExercises => {
  return {
    gameId: game.id,
    maps: getGameMapsForGameResponse(game),
    tasks: (game.exercises || []).map(parseTaskResponseToTaskVm),
    noPointsGame: game.no_points_game,
  }
}

const toRelativeWorldPosX = (legacyX: string): number => (parseInt(legacyX) / LEGACY_EXPLORATION_OVERVIEW_SIZE_X) * 100
const toRelativeWorldPosY = (legacyY: string): number => (parseInt(legacyY) / LEGACY_EXPLORATION_OVERVIEW_SIZE_Y) * 100

const getDoors = (mapStructure: MapStructure, index: number): Door[] => {
  return mapStructure[index + ''].doors.map((door) => {
    return {
      id: door.id,
      boardIndex: index + 1,
      latitude: door.y,
      longitude: door.x,
      leadsToBoardIndex: parseInt(door.leadsToMapId + '') + 1,
      pair: parseInt(door.pair + ''),
      isDefault: door.default,
    }
  })
}

export const getDefaultMapStructure = (index: number): MapStructure => {
  return {
    [index + '']: {
      doors: [],
      world_pos: {
        x: (50 + 75 * ((index + 1) % 5)).toString(),
        y: (125 * Math.floor((index + 1) / 5)).toString(),
      },
    },
  }
}

const getWorldPositionWithDefaultFallback = (mapStructure: MapStructure, index: number): { x: string; y: string } => {
  return safeIsNullOrEmpty(mapStructure[index + '']?.world_pos?.x)
    ? getDefaultMapStructure(index)[index + ''].world_pos
    : mapStructure[index + ''].world_pos
}

const getEditorGameBoards = (game: GetGameResponse): GameBoard[] => {
  let mapStructure: MapStructure | null = null

  if (game.advanced_mode) {
    try {
      if (game.map_structure) mapStructure = JSON.parse(game.map_structure)
    } catch {}
    if (!mapStructure) {
      mapStructure = getDefaultMapStructure(-1)
      game.additional_maps.forEach((map, index) => {
        mapStructure = {
          ...mapStructure,
          ...getDefaultMapStructure(index),
        }
      })
    }
  }
  // The game might have map_structure, but it might miss the world_pos data. Get default pos for this index if data missing
  let worldPos = mapStructure ? getWorldPositionWithDefaultFallback(mapStructure, -1) : null
  return [
    {
      mapIndex: 0,
      name: game.map_title ?? '',
      fileName: game.map_file_name,
      url: game.map_url,
      ...(game.map_type === MapType.STATIC && { dimensions: game.map_dimensions }),
      ...(game.map_type === MapType.PANORAMA && { mapOptions: { center: [game.lng, game.lat], zoom: game.zoom } }),
      ...(mapStructure &&
        worldPos &&
        mapStructure['-1'] && {
          worldPosition: [toRelativeWorldPosX(worldPos.x), toRelativeWorldPosY(worldPos.y)],
        }),
      ...(mapStructure && mapStructure['-1']?.doors && { doors: getDoors(mapStructure, -1) }),
    },
    ...game.additional_maps.map((map, index): GameBoard => {
      let mapOptions: MapOptions | null = null
      worldPos = mapStructure ? getWorldPositionWithDefaultFallback(mapStructure, index) : null
      if (game.map_type === MapType.PANORAMA) {
        const location: { lng: number; lat: number; zoom: number } = JSON.parse(map.location)
        mapOptions = {
          center: [location.lng, location.lat],
          zoom: location.zoom,
        }
      }
      return {
        mapIndex: map.map_id + 1,
        name: map.map_title ?? '',
        fileName: map.map_id.toString(),
        url: map.map_url,
        dimensions: map.map_dimensions,
        ...(game.map_type === MapType.PANORAMA && mapOptions && { mapOptions: mapOptions }),
        ...(mapStructure &&
          mapStructure[index + ''] &&
          worldPos && {
            worldPosition: [toRelativeWorldPosX(worldPos.x), toRelativeWorldPosY(worldPos.y)],
          }),
        ...(mapStructure && mapStructure[index + '']?.doors && { doors: getDoors(mapStructure, index) }),
      }
    }),
  ]
}

export const parseBadgeResponseToBadge = (badge: BadgeData): Badge => {
  return {
    id: parseInt(badge.id.toString()),
    name: badge.name,
    imageUrl: badge.image_url,
  }
}

const parseGameResponseToAdvancedSettings = (game: GetGameResponse): GameAdvancedSettings => {
  return {
    emailRequired: game.player_email_required ?? false,
    teamMemberNamesRequired: game.team_member_names_required ?? false,
    gpsEnabled: !game.no_gps,
    chatEnabled: !game.no_chat,
    levelsEnabled: game.levels_enabled,
    allowBranching: game.branch_type === 'TREE' ? true : false,
    allowPlayersToImproveAnswers: game.player_continues_sent_ans_enabled,
    noPointsGame: game.no_points_game,
    hideScoreboard: game.hide_scoreboard,
    askPlayerFeedback: game.happy_or_not,
    explorationMode: game.advanced_mode,
    orderingEnabled: game.ordering_enabled,
    badgesEnabled: game.badges_enabled,
    publicLinkEnabled: !safeIsNullOrEmpty(game.share_secret),
    hideIllustrations: game.hide_exercise_animations ?? false,
    offlineMode: game.offline_enabled ?? false,
    ...(game.share_secret != null && !safeIsNullOrEmpty(game.share_secret) && { publicLinkSecret: game.share_secret }),
    pinCodeEnabled: !!game.pin_code_player_enabled,
    strongAuthRequired: !!(game.strong_auth_url && game.strong_auth_url.length > 10),
    strongAuthUrl: game.strong_auth_url && game.strong_auth_url.length > 10 ? game.strong_auth_url : undefined,
  }
}

const parseGameResponseToNotificationSettings = (game: GetGameResponse): NotificationSettings => {
  return {
    messages: game.notification_settings.messages ?? undefined,
    answers: game.notification_settings.answers ?? undefined,
    instantEnabled: game.notification_settings.instant_enabled,
    summaryEnabled: game.notification_settings.summary_enabled,
    summarySendTime: game.notification_settings.sending_time ?? undefined,
    emails: safeIsNullOrEmpty(game.notification_settings.emails)
      ? []
      : (game.notification_settings.emails || '').split(','),
  }
}

const parseGameResponseToBoardSettings = (game: GetGameResponse): GameBoardSettings => {
  return {
    gameBoardType: game.map_type,
    is3D: game.keywords?.includes('Seppo 3D Game'),
    mapOptions:
      game.map_type === MapType.LIVE || game.map_type === MapType.PANORAMA
        ? {
            center: [game.lng, game.lat],
            zoom: game.zoom,
          }
        : DEFAULT_MAP_OPTIONS,
    gameBoards: getEditorGameBoards(game),
    isBranching: game.branch_type === 'TREE',
  }
}

const parseSingleLevelCriteria = (data: LevelCriteriaData, isActive: boolean, exploration?: boolean): LevelCriteria => {
  return {
    name: data.name,
    points: safeParseInt(data.points) ?? 0,
    completedTasks: safeParseInt(data.completed_exercises) ?? 0,
    defaultBoardIndex: exploration ? undefined : (safeParseInt(data.defaultMap) ?? -1) + 1,
    isActive,
  }
}

const getEmptyLevelCriteria = (isActive: boolean, exploration?: boolean): LevelCriteria => {
  return {
    isActive,
    name: '',
    points: 0,
    completedTasks: 0,
    defaultBoardIndex: exploration ? undefined : 0,
  }
}

// levels 0 and 1 are always active, levels 5 to 10 are always inactive without exploration mode
// otherwise, a level is active if it has a name, or any tasks
const checkIfLevelActive = (levelIndex: number, tasks: Task[], levelName?: string, exploration?: boolean): boolean => {
  if (levelIndex < 2) {
    return true
  } else if (!exploration && levelIndex > 4) {
    return false
  }
  return !safeIsNullOrEmpty(levelName) || tasks?.some((t) => t.level === levelIndex)
}

const parseLevelsCriteria = (rawData: string | null, tasks: Task[], exploration?: boolean): LevelCriteria[] => {
  if (rawData == null) {
    return [getEmptyLevelCriteria(true, exploration), getEmptyLevelCriteria(true, exploration)]
  }
  try {
    const raw = JSON.parse(rawData) as LevelCriteriaData[]
    const parsed = raw
      .filter((i) => i)
      .map((rawCriteria: LevelCriteriaData, index) => {
        const isActive = checkIfLevelActive(index, tasks, rawCriteria.name, exploration)
        return parseSingleLevelCriteria(rawCriteria, isActive, exploration)
      })
    return parsed.map((parsedItem, index) => ({
      ...parsedItem,
      // set any lower level as active if a higher level is active
      isActive: parsedItem.isActive || parsed.some((otherItem, otherIndex) => otherIndex > index && otherItem.isActive),
    }))
  } catch (error) {
    console.error('Failed to parse levels criteria')
    console.error(error)
    return []
  }
}

export const parseGameResponseToGameVm = (game: GetGameResponse, tasksOverride?: Task[]): Game => {
  const tasks = tasksOverride ?? (game.exercises || []).map(parseTaskResponseToTaskVm)
  return {
    gameId: game.id,
    open: game.open,
    tasks,
    name: game.name,
    disableNewEditor: game.disable_ux3_editor && !globalAllowNewEditor,
    description: game.short_description || '',
    ages: game.age,
    instructionsForTeacherHtml: game.instructions_for_teacher,
    keywords: safeIsNullOrEmpty(game.keywords) ? [] : game.keywords?.split(','),
    language: game.language,
    storyHtml: game.description,
    storyEndHtml: game.story_end,
    rulesHtml: game.rules_html,
    showStoryAndRules: !!game.show_story_rules,
    topics: game.subject,
    gameBoardSettings: parseGameResponseToBoardSettings(game),
    ...(game.notification_settings != null && { notificationSettings: parseGameResponseToNotificationSettings(game) }),
    advancedSettings: parseGameResponseToAdvancedSettings(game),
    pinCode: game.pin_code_player,
    ...(game.levels_enabled && {
      levelsCriteria: parseLevelsCriteria(game.levels_criteria, tasks, game.advanced_mode),
    }),
    validUntil: game.good_trough?.toString(),
    exported: !!game.exported,
    imported: !!game.imported,
    rootTaskId: game.root_id ?? undefined,
    badges: (game.badges || []).map(parseBadgeResponseToBadge),
    editRestricted: game.edit_restricted ?? false,
    maxPoints: game.max_points,
  }
}

export const parseMessagesResponseToMessages = (messages: RawMessage[]): TMessage[] => {
  return messages
    .filter(({ from }) => from != null)
    .map(({ created_at, from, id, msg_type, text, to }) => {
      return {
        createdAt: created_at,
        from: {
          name: from.real_name || from.name,
          id: from.id,
        },
        id,
        msgType: msg_type,
        text,
        to: to != null ? { id: to.id, name: to.real_name || to.name } : null,
        isGeneralMessage: to == null ? true : false,
      }
    })
}

export const parseOneRawMessageToMessage = ({ created_at, from, id, msg_type, text, to }: RawMessage) => {
  return {
    createdAt: created_at,
    from: {
      name: from.real_name || from.name,
      id: from.id,
    },
    id,
    msgType: msg_type,
    text,
    to: to != null ? { id: to.id, name: to.real_name || to.name } : null,
    isGeneralMessage: to == null ? true : false,
  }
}

export const SEPPO_GROUPING_BUSINESS = 'SEPPO_GROUPING_BUSINESS'

export const parseUserInfoResponseToTUser = (user: User): TUser => {
  const allowedOptions: AllowedOption[] = (user.allowed_options || '').split(',').map((opt) => {
    return AllowedOption[opt as keyof typeof AllowedOption]
  })

  const businesses: Business[] = user.businesses.map((business) => {
    return {
      id: business.id,
      name: (business.name || '').replace(' Individual', ''),
      validUntil: business.good_trough?.toString(),
      country: business.country,
      loginCount: business.login_count || 0,
      industry: !business.business_type || business.business_type === 9 ? SEPPO_GROUPING_BUSINESS : business.industry,
      playerAuthUrl: business.player_auth_url,
      engine_pin: business.engine_pin,
      engine_rounds: business.engine_rounds,
      hasSaigeAccess: (business.engine_pin || '').length > 6,
      saigeAllMembers: !!business.engine_all_members,
    }
  })

  const tags = user.classes?.map((tag: ApiTag): Tag => {
    return {
      id: tag.id,
      label: tag.group_tag,
      businessId: tag.business_id,
      playerCount: user.classes_counts ? user.classes_counts[tag.id] : undefined,
    }
  })

  let gamePackages: GamePackage[] = []
  //The data from backend is like ",ID1,VALID_UNTIL1,ID2,VALID_UNTIL2,"
  //BUT, there may be multiple ids in the beginning without dates and there may be extra commas in between
  try {
    let maxLoops = 50
    let remainingPackageData = user.game_packages?.split(',').filter((item) => item.length > 0)
    let validUntilIndex = remainingPackageData?.findIndex((item) => item.length > 6) ?? 0 //First date based on length
    let packageId = remainingPackageData?.[validUntilIndex - 1]
    while (packageId && maxLoops-- > 0) {
      gamePackages.push({
        id: parseInt(packageId),
        name: '',
        validUntil: remainingPackageData?.[validUntilIndex],
      })
      remainingPackageData = remainingPackageData?.slice(validUntilIndex + 1)
      validUntilIndex = remainingPackageData?.findIndex((item) => item.length > 6) ?? 0
      packageId = remainingPackageData?.[validUntilIndex - 1]
    }
  } catch (e) {}

  let dashboardMessage: DashboardMessage | undefined = undefined
  // Format of the message from BE is like:
  // "GENERIC|||" + message_item.title + "|||" + message_item.message + "|||" + message_item.close_button_text + "|||" + message_item.id.to_s
  // If there is a link, it will be included in the message_item.message
  if (user.dashboard_message && user.dashboard_message.length > 10) {
    const messageParts = user.dashboard_message.split('|||')
    try {
      const linkUrlStart = messageParts[2].includes('<a href=') ? messageParts[2].indexOf('<a href=') + 9 : -1
      let linkUrlEnd = messageParts[2].indexOf("'", linkUrlStart + 1)
      if (linkUrlEnd === -1) linkUrlEnd = messageParts[2].indexOf('"', linkUrlStart + 1)
      const linkUrl = linkUrlStart > -1 ? messageParts[2].substring(linkUrlStart, linkUrlEnd) : undefined
      dashboardMessage = {
        title: messageParts[1],
        message: messageParts[2].substring(0, linkUrlStart),
        buttonLabel: messageParts[3],
        buttonLink: linkUrl,
        id: parseInt(messageParts[4]),
      }
    } catch (e) {
      console.warn('Failed to parse dashboard message')
    }
  }

  return {
    id: user.id,
    name: user.name,
    contactName: user.contact_name ?? '',
    userInitials: generateUserInitials(user.name),
    email: user.email,
    language: user.language,
    chatName: user.real_name,
    dashboardMessage: dashboardMessage,
    hasPlayerAccounts: user.allow_player_account,
    isBusinessAdmin: user.is_business_admin,
    isBusinessGroupMaster: user.is_business_group_master,
    isBusinessGroupTechMaster: user.is_business_group_tech_master,
    isBusinessSuperUser: user.is_business_super_user,
    isStudentTeacher: user.is_student_teacher,
    isGradingInstructor: user.is_grading_instructor,
    isSponsoredUser: user.is_sponsored_user,
    hasValidSponsored: user.has_valid_sponsored ?? false,
    // TODO: Check if this should remain as it is ->
    // according to PR comment https://github.com/Liitis/ux3.0/pull/65#discussion_r1165301825
    isTrial: user.businesses[0]?.business_type === 6,
    isCorporate: user.businesses[0]?.industry === 'Corporate',
    isEducation: user.businesses[0]?.industry === 'Education',
    hasOrgLibrary: user.allow_private_library,
    hasCommunity: user.allow_content_library,
    hasTemplates: user.allowed_options?.includes(AllowedOption.CORPORATE_LIBRARY),
    hasSaigeAccess: user.saige_access,
    enginePin: user.businesses[0]?.engine_pin,
    engineRounds: user.businesses[0]?.engine_rounds ?? 0,
    allowUseNewEditor: user.allowed_options?.includes(AllowedOption.ALLOW_NEW_EDITOR),
    preferLegacyEditor: prefersOldEditor(),
    passwordChanged: user.password_changed,
    termsAccepted: !!user.terms_accepted,
    hasTeacherReporting: !!user.teacher_reporting_path,
    allowedOptions: allowedOptions,
    activeBusiness: businesses[0],
    primaryBusinessId: businesses[0]?.id,
    businesses: businesses,
    tags: (tags as Tag[]) || [],
    createGame: user.create_game,
    createdAt: user.created_at,
    theme: {
      themeLogoUrl: user.theme_app_bar_icon,
      themeAllowedLanguages: user.theme_language_list,
      themeMainColor: user.theme_main_color,
      themeMainLightColor: user.theme_main_light_color,
      themeSecondaryColor: user.theme_turquoise_color,
      themeFaviconUrl: user.theme_tab_logo_url,
      themeTabName: user.theme_title,
    },
    reports: getReportsForUser(user),
    businessReportUrl: user.business_reporting_path,
    useOldDashboard: user.use_dashboard2,
    hasNewPaymentInfo: user.businesses[0]?.business_type === 10,
    ux3Shown: user.ux3_shown,
    gamePackages: gamePackages.length ? gamePackages : undefined,
    custom_invite: user.custom_invite,
    ltiContext: user.dikaios_id ?? undefined,
    playerAuthUrl: businesses[0]?.playerAuthUrl,
  }
}

const DETECT_MISSING_THEME_IMAGE = 'original/missing'

export const getTheme = (apiTheme: ApiTheme): ThemeSettings => {
  return apiTheme.theme_main_color
    ? {
        logoUrl: apiTheme.theme_app_bar_icon.includes('/assets/seppo.png')
          ? DEFAULT_THEME_SETTINGS.logoUrl
          : apiTheme.theme_app_bar_icon,
        allowedLanguages: apiTheme.theme_language_list,
        colorPrimary: apiTheme.theme_main_color,
        colorPrimaryLight: apiTheme.theme_main_light_color,
        colorSecondary: apiTheme.theme_turquoise_color,
        faviconUrl: apiTheme.theme_tab_logo_url,
        tabName: apiTheme.theme_title,
        backgroundImageUrl: (apiTheme.background_picture_url || '').includes(DETECT_MISSING_THEME_IMAGE)
          ? undefined
          : apiTheme.background_picture_url,
        rawCodeSectionTop: apiTheme.custom_code_login_top,
        rawCodeSectionBottom: apiTheme.custom_code_login_bottom,
        loginHideLanguageSelection: !!apiTheme.login_hide_language_selection,
        loginHideTeacherArea: !!apiTheme.login_hide_teacher_button,
        loginLanguage: apiTheme.login_language ?? 'en',
        loginBottomLogoUrl: apiTheme.login_bottom_logo_url ?? undefined,
      }
    : DEFAULT_THEME_SETTINGS
}

export const parseCheckGameResponse = (data: CheckGameOrPlayerResponse): CheckGameResponse => {
  return {
    succress: true,
    msg: data.msg,
    open: data.open,
    gameId: data.game_id,
    gamePin: data.game_pin,
    gameName: data.game_name,
    gameMapUrl: data.game_map_url,
    wasGameCode: data.was_game_code,
    requirePlayerEmail: !!data.requires_email,
    requireTeamMemberNames: !!data.requires_members,
    theme: getTheme(data),
    useNewPlayerUI: !!data.use_new_player_ui,
    playerName: data.player_name ?? undefined,
    is3dGame: data.is_3d_game,
    strongAuthRequired: data.strong_auth_required,
  }
}

const getReportsForUser = (user: User): Report[] => {
  const reports: Report[] = []
  if (user.is_business_admin) {
    reports.push(Report.BUSINESS)
  }
  if (user.is_business_group_master) {
    reports.push(Report.GROUP)
  }
  if (user.owns_games) {
    if (user.allowed_options?.includes(AllowedOption.TEACHER_REPORTING)) {
      reports.push(Report.TEACHER)
    }
    if (user.allowed_options?.includes(AllowedOption.PLAYER_REPORTING)) {
      reports.push(Report.PLAYER)
    }
    if (user.allowed_options?.includes(AllowedOption.SPONSORED_GAMES_REPORTING)) {
      reports.push(Report.SPONSORED_GAMES)
    }
  }
  return reports
}

const parseFeedbackMessage = (msg: string, comment: Maybe<string>): FeedbackMessage => {
  let feedbackMessage = null
  let feedbackImageUrl = null

  if (msg?.length > 0) {
    let commentStart = msg.indexOf('<div class=\\"comment-text\\">')
    if (commentStart < 0) commentStart = msg.indexOf('<div class="comment-text">')
    if (commentStart > -1) {
      const commentEnd = msg.indexOf('<hr', commentStart)
      const feedbackPart = msg.substring(commentStart, commentEnd)
      feedbackMessage = decodeHtml(feedbackPart)
        .replace(/<[^>]+>/g, '')
        .replace('&nbsp;', '')
        .trim()
      if (feedbackPart.indexOf('src') > 0)
        feedbackImageUrl = feedbackPart
          .substring(feedbackPart.indexOf('src') + 5, feedbackPart.indexOf('" width'))
          .replace('"', '')
          .replace('\\', '')
    }
  }
  feedbackMessage ||=
    comment &&
    decodeHtml(comment)
      .replace(/<[^>]+>/g, '')
      .replace('&nbsp;', '')
      .trim()
  return { feedbackMessage, feedbackImageUrl }
}

const parseSelfieUrl = (id: Maybe<number>, fileName: Maybe<string>): string | null => {
  if (id && fileName) {
    const urlTemplate = `${process.env.REACT_APP_POSTCARD_URL_TEMPLATE}`
    const ID_PART = id
      .toString()
      .padStart(9, '0')
      .match(/.{1,3}/g)
      ?.join('/')
    const IMAGE_NAME_PART = fileName
    return !ID_PART ? null : urlTemplate.replace('ID_PART', ID_PART).replace('IMAGE_NAME_PART', IMAGE_NAME_PART)
  } else {
    return null
  }
}

const getAnswerIndexes = (rawIndexes: string | number): number[] | null => {
  // NOTE: in initial csv load it arrives as just 1 number if player selected 1 option
  if (typeof rawIndexes === 'number') {
    return [rawIndexes]
  }
  return rawIndexes?.length > 0
    ? rawIndexes
        .split(',')
        .map((a) => parseInt(a))
        .filter((i) => !isNaN(i))
    : null
}

const parseAnswerBadge = (raw: string): Badge | undefined => {
  if (safeIsNullOrEmpty(raw)) {
    return undefined
  }
  return parseBadgeResponseToBadge(JSON.parse(trimDoubleQuotes(raw)))
}

const parseReceivedAnswers = (rawAnswers: Answer[]): ReceivedAnswer[] => {
  return rawAnswers.map((raw) => {
    const { feedbackMessage, feedbackImageUrl } = parseFeedbackMessage(
      raw.feedback_message || raw.feedback,
      raw.comment,
    )

    //Below block is to remove \ chars from missing word answers
    let modifiedRawAnswer = raw.answer
    try {
      if (typeof raw.answer !== 'string' && typeof raw.answer !== 'undefined') {
        let modifiedRawAnswerObject = JSON.parse(JSON.stringify(raw.answer)) as ObjectAny
        if (typeof modifiedRawAnswerObject === 'object') {
          Object.keys(modifiedRawAnswerObject).forEach((key) => {
            modifiedRawAnswerObject[key] = modifiedRawAnswerObject[key]?.replaceAll('\\', '')
          })
          modifiedRawAnswer = modifiedRawAnswerObject as unknown as string
        }
      }
    } catch (e) {
      console.warn('Error in parsing missing word answer')
    }

    return {
      id: raw.id,
      exerciseId: raw.exercise_id,
      userId: raw.user_id || raw.user.id,
      answer: typeof raw.answer === 'string' ? raw.answer?.replaceAll('\\', '') : modifiedRawAnswer,
      answerIndex: raw.answer_index,
      answerIndexes: getAnswerIndexes(raw.answer_indexes),
      feedbackHtml: (raw.comment + '').length > 0 ? raw.comment : null,
      feedbackMessage,
      feedbackImageUrl,
      //selfie = postcard comes in different ways in push messages and on initial fetch
      selfieUrl: raw.postcard ? raw.postcard.image_url : parseSelfieUrl(raw.postcard_id, raw.postcard_file_name),
      selfieMessage: raw.postcard ? raw.postcard.greetings : raw.postcard_greeting,
      state: raw.state as AnswerStateEnum,
      points: raw.points,
      completedAt: dateTimeToISOString(
        safeIsNullOrEmpty(raw.completed_at) ? raw.updated_at : raw.completed_at ?? raw.updated_at,
      ), // answers in progress do not have completed_at, hence the updated_at fallback
      hasMorePreviousAnswers: (raw.prev_ans_count ?? 0) > 0,
      //NOTE the previousAnswers is empty for the data fetched initially from BE. In PUSH updates the data is there
      //For the initially fetched answers the data is fetched if empty when needed in UI
      previousAnswers: parseReceivedAnswers(raw.previous_answers || []),
      badge: parseAnswerBadge(raw.badge),
    }
  })
}

const parseTaskBase = (taskResponse: Exercise): SubtaskBase => {
  return {
    description: taskResponse.description,
    id: taskResponse.id,
    // tempId is used as identifier for both new and existing tasks, useful for managing field array items in task form
    tempId: taskResponse.id,
    answers: parseReceivedAnswers(Object.values(taskResponse.answers || {})),
    maxPoints: getPointsForExercise(taskResponse),
  }
}

const parseTaskAdvanced = (taskResponse: Exercise): TaskAdvanced => {
  const hasCustomIconType = !(
    safeIsNullOrEmpty(taskResponse.icon_type) || taskResponse.icon_type === TaskIconType.seppo
  )
  return {
    ...(taskResponse.is_flash && { isFlash: true }),
    ...(taskResponse.is_hidden && { isHidden: true }),
    ...(taskResponse.requires_postcard && { requirePictureOrSelfie: true }),
    ...(taskResponse.time_pressure && { hasTimeLimit: true }),
    ...(taskResponse.time_pressure && { timeLimitSeconds: parseInt(taskResponse.time_pressure) }),
    ...(taskResponse.has_proximity && { hasProximityLock: true }),
    ...(taskResponse.has_proximity &&
      taskResponse.required_proximity && { requiredProximity: taskResponse.required_proximity.toString() }),
    ...(taskResponse.has_lock_code && { hasLockCode: true }),
    ...(taskResponse.has_lock_code && taskResponse.lock_code && { lockCode: taskResponse.lock_code.toString() }),
    ...(taskResponse.has_lock_code && taskResponse.code_hint && { lockClue: taskResponse.code_hint }),
    hasCustomIconType,
    iconType:
      (hasCustomIconType && taskResponse.icon_type) ||
      (taskResponse.has_lock_code && TaskIconType.codeLock) ||
      undefined,
    hasAutomaticBadge: taskResponse.badge_auto_enabled,
    ...(taskResponse.badge_auto_enabled && {
      automaticBadgeId: safeParseInt(taskResponse.badge_id),
      automaticBadgePoints: safeParseInt(taskResponse.badge_points_req),
    }),
  }
}

const parseCollaborationExercise = (taskResponse: Exercise): CreateAndCommentSubtask => {
  return {
    ...parseTaskBase(taskResponse),
    type: ExerciseType.CollaborationExercise,
    data: {
      test: 'temp',
    },
  }
}

const checkIfAllPointsSame = (points: number[]): boolean => {
  return points.length > 1 && !points.some((point) => point !== points[points.length - 1])
}

const parseCombineExercise = (taskResponse: CombineExercise): MatchPairsSubtask => {
  const allAnswersHaveSamePoints = checkIfAllPointsSame(taskResponse.data.points)
  return {
    ...parseTaskBase(taskResponse),
    type: ExerciseType.CombineExercise,
    data: {
      allAnswersHaveSamePoints,
      ...(allAnswersHaveSamePoints && { allAnswersPoints: taskResponse.data.points[0] }),
      answers: taskResponse.data.choices.map((choice, index) => ({
        left: {
          text: choice.leftText.replace(/\\/g, ''),
          imageUrl: choice.leftImage,
        },
        right: {
          text: choice.rightText.replace(/\\/g, ''),
          imageUrl: choice.rightImage,
        },
        points: taskResponse.data.points[index],
      })),
    },
  }
}

const parseCreativeExercise = (taskResponse: CreativeExercise): CreativeSubtask => {
  return {
    ...parseTaskBase(taskResponse),
    type: ExerciseType.CreativeExercise,
    data: {
      answerOptions:
        ([
          taskResponse.allows_text ? CreativeAnswerOption.Text : null,
          taskResponse.allows_image ? CreativeAnswerOption.Images : null,
          taskResponse.allows_audio ? CreativeAnswerOption.Audio : null,
          taskResponse.allows_video ? CreativeAnswerOption.Video : null,
        ].filter((item) => item != null) as CreativeAnswerOption[]) || [],
      hasAutomatedFeedback: taskResponse.has_default_feedback ?? false,
      hasEvaluationGuideline: taskResponse.has_reference_answer ?? false,
      pointsForAnswer: taskResponse.points,
      pointsType:
        !safeIsNullOrEmpty(taskResponse.auto_score_percentage) && !isNullOrZero(taskResponse.auto_score_percentage)
          ? PointsType.Automatic
          : PointsType.Evaluate,
      automatedFeedback: taskResponse.default_feedback ?? undefined,
      ...(taskResponse.auto_score_percentage != null &&
        !safeIsNullOrEmpty(taskResponse.auto_score_percentage) && {
          autoScoreOption: [0, 25, 50, 75, 100].includes(taskResponse.auto_score_percentage)
            ? taskResponse.auto_score_percentage.toString()
            : 'other',
          autoScorePercentage: [0, 25, 50, 75, 100].includes(taskResponse.auto_score_percentage)
            ? undefined
            : taskResponse.auto_score_percentage,
        }),
      evaluationGuideline: taskResponse.reference_answer ?? undefined,
    },
  }
}

const parseExploreExercise = (taskResponse: ExploreExercise): ExploreSubtask => {
  return {
    ...parseTaskBase(taskResponse),
    type: ExerciseType.ExploreExercise,
    maxPoints: 0,
    data: {
      ackRequired: taskResponse.grading_mode === 'ACK_REQUIRED',
    },
  }
}

const parseMissingWordExercise = (taskResponse: MissingWordExercise): MissingWordSubtask => {
  const [description, answers] = getOrderedDescriptionAndAnswers(
    transformLegacyToUx3Description(taskResponse.description),
    taskResponse.data.choices,
    taskResponse.data.points,
  )
  const allAnswersHaveSamePoints = checkIfAllPointsSame(taskResponse.data.points)
  return {
    id: taskResponse.id,
    maxPoints: getPointsForExercise(taskResponse),
    description,
    type: ExerciseType.MissingWordExercise,
    data: {
      allAnswersHaveSamePoints,
      ...(allAnswersHaveSamePoints && { allAnswersPoints: taskResponse.data.points[0] }),
      answers,
    },
    answers: parseReceivedAnswers(Object.values(taskResponse.answers || {})),
  }
}

const parseMultiChoiceExercise = (taskResponse: MultichoiceExercise): MultipleChoiceSubtask => {
  const allAnswersHaveSamePoints = checkIfAllPointsSame(taskResponse.data.points)
  return {
    ...parseTaskBase(taskResponse),
    type: ExerciseType.MultichoiceExercise,
    data: {
      allAnswersHaveSamePoints,
      ...(allAnswersHaveSamePoints && { allAnswersPoints: taskResponse.data.points[0] }),
      answers: taskResponse.data?.choices.map((choice, index) => {
        return {
          text: choice,
          imageUrl: taskResponse.data.picture_urls?.[index] ?? '',
          points: taskResponse.data.points[index],
          isCorrectAnswer: taskResponse.data.correct_index === index,
          hasFeedback: !safeIsNullOrEmpty(taskResponse.data.feedbacks?.[index]),
          feedback: taskResponse.data.feedbacks?.[index] ?? undefined,
          nextTaskId: taskResponse.data.next_ex_ids?.[index] || '',
        }
      }),
    },
  }
}

const parsePollExercise = (taskResponse: PollExercise): CheckboxSubtask => {
  const anyAnswerDifferentPoints = taskResponse.data.points.some(
    (point) => point !== taskResponse.data.points[taskResponse.data.points.length - 1],
  )
  return {
    ...parseTaskBase(taskResponse),
    type: ExerciseType.PollExercise,
    data: {
      allAnswersHaveSamePoints: !anyAnswerDifferentPoints,
      allAnswersPoints: taskResponse.data.points[0],
      hasAutomatedFeedback:
        (taskResponse.data.has_default_feedback ||
          (taskResponse.has_default_feedback !== false && !safeIsNullOrEmpty(taskResponse.default_feedback))) ??
        false,
      automatedFeedback: (taskResponse.data.feedback || taskResponse.default_feedback) ?? undefined,
      answers: taskResponse.data?.choices.map((choice, index) => {
        return {
          text: choice,
          imageUrl: taskResponse.data.picture_urls[index],
          points: taskResponse.data.points[index],
          isCorrectAnswer: taskResponse.data.points[index] === 1,
        }
      }),
    },
  }
}

const parseExerciseIfDataString = (taskResponse: any): Exercise => {
  // in some cases, data is a string, if so - parse it
  if (taskResponse.data && typeof taskResponse.data === 'string') {
    return { ...taskResponse, data: JSON.parse(taskResponse.data) } as Exercise
  }
  return taskResponse as Exercise
}

const parseTaskResponseToSubtask = (taskResponse: Exercise): Subtask => {
  const parsedExercise = parseExerciseIfDataString(taskResponse)
  switch (parsedExercise.type) {
    case ExerciseType.CollaborationExercise:
      return parseCollaborationExercise(parsedExercise)
    case ExerciseType.CombineExercise:
      return parseCombineExercise(parsedExercise)
    case ExerciseType.CreativeExercise:
      return parseCreativeExercise(parsedExercise)
    case ExerciseType.MissingWordExercise:
      return parseMissingWordExercise(parsedExercise)
    case ExerciseType.MultichoiceExercise:
      return parseMultiChoiceExercise(parsedExercise)
    case ExerciseType.PollExercise:
      return parsePollExercise(parsedExercise)
    case ExerciseType.ExploreExercise:
      return parseExploreExercise(parsedExercise)
  }
}

export const parseTaskResponseToTaskVm = (taskResponse: Exercise): Task => {
  if (taskResponse.points === undefined) {
    return {
      id: taskResponse.id,
      name: taskResponse.name,
      x: 50,
      y: 50,
      type: taskResponse.type,
      advanced: {},
      isOpen: true,
      subtasks: [],
    }
  }
  const subtasks = [
    parseTaskResponseToSubtask(taskResponse),
    ...taskResponse.children.sort((a, b) => a.child_order - b.child_order).map(parseTaskResponseToSubtask),
  ]

  let orderNumberFromName = null
  try {
    orderNumberFromName = parseInt(taskResponse.name)
    if (isNaN(orderNumberFromName)) orderNumberFromName = null
  } catch (e) {}
  const orderNumber = !safeIsNullOrEmpty(taskResponse.order_number) ? taskResponse.order_number : orderNumberFromName
  return {
    id: taskResponse.id,
    name: taskResponse.name,
    subtasks,
    advanced: parseTaskAdvanced(taskResponse),
    x: taskResponse.x,
    y: taskResponse.y,
    mapIndex: taskResponse.map_id === 0 || taskResponse.map_id ? taskResponse.map_id + 1 : 0,
    pointsSum: subtasks.reduce((sum, subtask) => sum + subtask.maxPoints, 0),
    type: taskResponse.type,
    isOpen: taskResponse.open,
    isGoalTask: (taskResponse.is_branch_end || '').toString() === 'true',
    isSingleExplore: taskResponse.type === ExerciseType.ExploreExercise && subtasks.length === 1,
    exploreAckRequired:
      taskResponse.type === ExerciseType.ExploreExercise &&
      subtasks.length === 1 &&
      subtasks[0].type === ExerciseType.ExploreExercise &&
      subtasks[0].data.ackRequired,
    ...(!safeIsNullOrEmpty(taskResponse.level) && { level: taskResponse.level! }),
    ...(!safeIsNullOrEmpty(orderNumber) && { order: orderNumber! }),
    ...(!safeIsNullOrEmpty(taskResponse.flash_sort_order) && { flashOrder: taskResponse.flash_sort_order! }),
    ...(!safeIsNullOrEmpty(taskResponse.positionchoice) && { order3D: parseInt(taskResponse.positionchoice) }),
  }
}

const parsePaymentDataToPaymentInfoVM = (data: PaymentData): PaymentInfo => {
  if (data.payment_type === PaymentMethod.CARD) {
    return {
      paymentType: PaymentMethod.CARD,
      maskedNumber: data.card_masked_number || '',
      validUntil: data.card_valid_until || '',
      email: data.email || '',
    }
  }
  return {
    paymentType: PaymentMethod.INVOICE,
    city: data.city || '',
    country: data.country || '',
    postalCode: data.postal_code || '',
    streetAddress: data.street_address || '',
    einvAddress: data.einv_address || '',
    einvOperator: data.einv_operator || '',
    email: data.email || '',
  }
}

const parseAdminSettingsDataToNewBusinessInfoVM = (data: BusinessSettingsData): NewBusinessInfo => {
  return {
    licenceCount: data.total_player_seats || 0,
    usedPlayerAccountSeats: data.player_account_seat_count || 0,
    usedPinSeats: data.pin_seat_count || 0,
    remainingUnusedSeats:
      (data.total_player_seats || 0) - (data.player_account_seat_count || 0) - (data.pin_seat_count || 0),
    autoRenew: data.auto_renew || false,
    renewCycle: data.renew_cycle || 0,
    nextInvoiceDate: dateTimeToLocalDateString(data.good_trough.toString()),
    ...(data.renew_cycle === 12 &&
      data.next_free && { nextDateWhenLicencesFreed: dateTimeToLocalDateString(data.next_free.toString()) }),
    ...(data.auto_renew && data.next_price != null && { nextInvoicePrice: data.next_price }),
    ...(data.payment_method && { paymentInfo: parsePaymentDataToPaymentInfoVM(data.payment_method) }),
    contactPerson: data.payment_method?.contact_name || '',
    vat: data.payment_method?.vat || '',
    emailSeatHolders: data.used_player_account_seats || '',
    pinSeatHolders: data.used_pin_seats || '',
  }
}

export const parseMemberDataToVm = (member: Member): TMember => {
  return {
    id: member.id,
    email: member.name,
    name: member.contact_name ?? EMPTY_CONTENT_FALLBACK,
    role: member.is_admin ? MemberRole.ADMIN : MemberRole.INSTRUCTOR,
    orgId: member.org_id || '',
    saigeAccess: member.saige_access,
  }
}

export const parseAdminSettingsDataToVm = (data: BusinessSettingsData): TBusinessSettings => {
  return {
    businessInfo: {
      validUntil: data.good_trough?.toString(),
      maxMembers: data.max_members,
      maxPlayers: data.max_teams,
      totalPlayersCount: data.all_time_player_count,
      limitMembers: data.limit_members === null ? true : data.limit_members,
      hasSaigeAccess: (data.engine_pin || '').length > 6,
      saigeAllMembers: data.engine_all_members,
      ...(data.business_type === 10 && { newBusinessInfo: parseAdminSettingsDataToNewBusinessInfoVM(data) }),
    },
    members: (data.members || []).map(parseMemberDataToVm),
  }
}

export const parseAddMemberVmToAddMemberData = (user: TAddMember): AddMember => {
  return {
    email: user.email,
    is_admin: user.role === MemberRole.ADMIN,
    name: user.name,
    org_id: user.orgId,
    saige_access: user.saigeAccess,
  }
}

export const parseBadgeVmToBadgeData = (badge: Badge): BadgeData => {
  return {
    id: badge.id,
    name: badge.name,
    image_url: badge.imageUrl,
    url: '',
  }
}

export const parsePlayerAccountsFromPlayerAccountsSearch = (
  playerAccountSearchResponse: PlayerAccountSearchResponse,
): PlayerAccount[] => {
  const rawPlayerAccounts = JSON.parse(playerAccountSearchResponse.players) as RawPlayerAccount[]
  return rawPlayerAccounts.map((raw) => {
    const { id, email, real_name, invited_by, classes } = raw
    return {
      id,
      email,
      nickName: real_name,
      invitedBy: invited_by,
      tags: classes.map((item: ApiTag): Tag => {
        return { id: item.id, label: item.group_tag, businessId: item.business_id }
      }),
    }
  })
}
