import classNames from 'classnames'
import sharedStyles from '../../Settings.module.css'
import { getIcon } from '../../../../common/components/icons/utils'
import styles from './OrganizationInfo.module.css'
import { useTranslation } from 'react-i18next'
import { safeIsNullOrEmpty } from '../../../../util/string'
import { NewBusinessInfo } from '../../../../types/commonTypes'
import { ToggleSwitch } from '../../../../common/components/ToggleSwitch/ToggleSwitch'
import { useCallback } from 'react'
import { useUser } from '../../../../contexts/userContext'

type OrganizationInfoProps = {
  name: string
  membersCount?: number
  licenseValidUntilDate?: string
  maxMembersCount: number | null
  businessInfo: NewBusinessInfo | null
  engineRounds?: number
  hasSaigeAccess: boolean
  limitMembers: boolean
  orgHasSaigeAccess: boolean
  saigeAllMembers: boolean
  onSetAllMembersSaigeAccess: (enabledState: boolean) => Promise<boolean>
}

export const OrganizationInfo: React.FC<OrganizationInfoProps> = ({
  name,
  membersCount,
  licenseValidUntilDate,
  maxMembersCount,
  businessInfo,
  engineRounds,
  hasSaigeAccess,
  limitMembers,
  orgHasSaigeAccess,
  saigeAllMembers,
  onSetAllMembersSaigeAccess,
}) => {
  const { t } = useTranslation()
  const { user } = useUser()
  const playerLicenseCount = businessInfo?.licenceCount
  const usedPlayerLicenseCount = (businessInfo?.usedPinSeats || 0) + (businessInfo?.usedPlayerAccountSeats || 0)

  const internalSetAllMembersSaigeAccess = useCallback(
    (evt: any) => {
      onSetAllMembersSaigeAccess(evt.target.checked)
    },
    [onSetAllMembersSaigeAccess],
  )

  return (
    <div className={classNames(sharedStyles.sectionCardContainer, styles.container)}>
      <div className={styles.organizationIconContainer}>
        <span>{getIcon('organizationGradient')}</span>
      </div>
      <div className={styles.orgInfoList}>
        <h1>{name}</h1>
        {membersCount != null && (
          <span className='bold normal'>
            {!maxMembersCount || !limitMembers
              ? t('settings_organization.org_info.members_count', {
                  count: membersCount,
                  defaultValue: '%{count} members',
                })
              : t('settings_organization.org_info.members_count_with_max', {
                  members_count: membersCount,
                  max_count: maxMembersCount,
                  defaultValue: '%{members_count}/%{max_count} members',
                })}
          </span>
        )}
        {playerLicenseCount && (
          <span className='bold normal'>
            {t('settings_organization.org_info.players_count', {
              players_count: usedPlayerLicenseCount,
              max_count: playerLicenseCount,
              defaultValue: '%{players_count}/%{max_count} players',
            })}
          </span>
        )}
        {!safeIsNullOrEmpty(licenseValidUntilDate) && (
          <span className='normal'>
            {t('settings_organization.org_info.license_valid_until', {
              next_invoice: licenseValidUntilDate,
              defaultValue: 'License valid until: %{next_invoice}',
            })}
          </span>
        )}
        {!safeIsNullOrEmpty(engineRounds) && hasSaigeAccess && (
          <span className='normal'>
            {t('settings_organization.org_info.saige_rounds_left', {
              saige_tokens: engineRounds,
              defaultValue: 'Saige tokens: %{saige_tokens}',
            })}
          </span>
        )}
        {orgHasSaigeAccess && user?.isBusinessAdmin && (
          <div className={styles.orgSaigeRow}>
            {t('settings_prganization.org_info.saige_all_members', 'Saige for all members')}
            <ToggleSwitch onChange={internalSetAllMembersSaigeAccess} defaultChecked={saigeAllMembers} />
          </div>
        )}
      </div>
    </div>
  )
}
