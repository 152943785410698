import { useCallback, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { v4 as uuid } from 'uuid'
import { uploadMedia } from '../../../../api/fileUploadApiService'
import { Button } from '../../../../common/components/button/Button'
import { useNotification } from '../../../../contexts/NotificationContext'
import { GameBoard } from '../../types'
import { useConfirmation } from '../../../../contexts/ConfirmationContext'

type UploadGameBoardProps = {
  disabled?: boolean
  onUploadSuccessful: (board: GameBoard) => void
  isPanorama?: boolean
}

const MAX_FILE_SIZE = 2048 //2MB
const MAX_FILE_SIZE_PANORAMA = 4096 //4MB

export const UploadGameBoard: React.FC<UploadGameBoardProps> = ({
  disabled,
  onUploadSuccessful,
  isPanorama = false,
}) => {
  const { t } = useTranslation()
  const { notifyError, notifyWarning } = useNotification()
  const { requestConfirmation } = useConfirmation()

  const hiddenFileInput = useRef<HTMLInputElement>(null)
  const [loading, setLoading] = useState<boolean>(false)

  const handleUploadBoardGame = useCallback(async () => {
    if (hiddenFileInput.current?.files?.[0] == null) {
      return
    }
    const inputFile = hiddenFileInput.current.files[0]
    const fileSizeKiloBytes = inputFile.size / 1024

    console.log(fileSizeKiloBytes)
    console.log(isPanorama)
    if (isPanorama) {
      if (fileSizeKiloBytes > MAX_FILE_SIZE_PANORAMA) {
        const response = await requestConfirmation({
          forceTop: true,
          title: t(
            'game_editor.game_boards.max_upload_size_warning_panorama.title',
            'Maximum recommended size exceeded',
          ),
          text: t(
            'game_editor.game_boards.max_upload_size_warning_panorama.content',
            'If your game will have players with mobile phones / tables, maximum file size needs to be under 4MB. Otherwise you can choose to proceed with this file.',
          ),
        })
        if (!response) return
      }
    } else if (fileSizeKiloBytes > MAX_FILE_SIZE) {
      notifyWarning({
        title: t('game_editor.game_boards.max_upload_size_warning.title', 'Maximum size exceeded'),
        content: t(
          'game_editor.game_boards.max_upload_size_warning.content',
          'Failed to upload game board, file size needs to be under 2MB',
        ),
      })
      return
    }
    setLoading(true)
    const fileNameWithoutExt = inputFile.name.substring(0, inputFile.name.lastIndexOf('.')) || inputFile.name
    const uniqueFileName = `${uuid()}-${inputFile.name}`
    uploadMedia({ fileName: uniqueFileName, inputFile })
      .then((result) => {
        if (result.success) {
          onUploadSuccessful({ name: fileNameWithoutExt, fileName: uniqueFileName, url: result.value.url })
        } else {
          throw result.error
        }
      })
      .catch((e) => {
        console.error(e)
        notifyError({
          title: t('game_editor.game_boards.upload_failure_notification.title', 'Failed to upload game board'),
          content: t(
            'game_editor.game_boards.upload_failure_notification.content',
            'Failed to upload game board. Please try again or contact us for support.',
          ),
        })
      })
      .finally(() => {
        setLoading(false)
      })
  }, [isPanorama, requestConfirmation, t, notifyWarning, onUploadSuccessful, notifyError])

  const handleAddImageClick = useCallback(() => {
    hiddenFileInput.current?.click()
  }, [])

  return (
    <>
      <Button disabled={disabled || loading} onClick={handleAddImageClick}>
        {t('game_editor.game_boards.upload_own_game_button', 'Upload your own file')}
      </Button>
      <input
        type='file'
        onChange={handleUploadBoardGame}
        style={{ display: 'none' }}
        ref={hiddenFileInput}
        accept='image/*'
      />
    </>
  )
}
