import { useTranslation } from 'react-i18next'
import styles from './CannedMessages.module.css'
import { getIcon } from '../../../../../../../../common/components/icons/utils'
import { Button } from '../../../../../../../../common/components/button/Button'
import { getHtmlText } from '../../../../../../../../util/string'
import { CannedMessage } from '../../../../../../../../types/commonTypes'
import classNames from 'classnames'

type CannedMessageListProps = {
  editMessage: (id: number, text: string) => void
  deleteMessage: (id: number) => void
  selectMessage: (text: string) => void
  messages: CannedMessage[]
}

export const CannedMessageList: React.FC<CannedMessageListProps> = ({
  editMessage,
  deleteMessage,
  selectMessage,
  messages,
}) => {
  const { t } = useTranslation()

  return (
    <div className={styles.cannedMessageList}>
      {messages.map((message) => {
        const imgOnly = getHtmlText(message.text).length === 0 && message.text.includes('img')
        return (
          <div className={styles.contentRow}>
            {message.default && (
              <button
                className={classNames(styles.button, styles.buttonOutline)}
                onClick={() => selectMessage(t(message.text))}
                type='button'
              >
                {t(message.text)}
              </button>
            )}
            {!message.default && (
              <button
                className={classNames(styles.button, styles.buttonOutline)}
                onClick={() => selectMessage(message.text)}
                type='button'
              >
                {imgOnly ? <div className={styles.imgOnlyArea}>{getIcon('photo')}</div> : getHtmlText(message.text)}
              </button>
            )}
            <div className={styles.optionButtonContainer}>
              <Button
                variant='borderless-tiny'
                className={styles.optionButton}
                onClick={() => deleteMessage(message.id)}
              >
                {getIcon('trash')}
              </Button>
              <Button
                variant='borderless-tiny'
                className={styles.optionButton}
                onClick={() => editMessage(message.id, message.text)}
              >
                {getIcon('text')}
              </Button>
            </div>
          </div>
        )
      })}
    </div>
  )
}
