import classNames from 'classnames'
import { AnswerStateEnum, ReceivedAnswer } from '../../../../../../../../../types/commonTypes'
import { safeIsNullOrEmpty } from '../../../../../../../../../util/string'
import sharedStyles from '../AnswerOptionLayout.module.css'
import { TimeExceededInfo } from '../TimeExceededInfo'
import styles from '../Creative/CreativeAnswer.module.css'
import { t } from 'i18next'

const CHECKMARK_SVG =
  '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M2.99902 12.001C2.99902 7.03006 7.02909 3 12 3C16.971 3 21.001 7.03006 21.001 12.001C21.001 16.9719 16.971 21.002 12 21.002C7.02909 21.002 2.99902 16.9719 2.99902 12.001ZM12 1.5C6.20066 1.5 1.49902 6.20164 1.49902 12.001C1.49902 17.8004 6.20066 22.502 12 22.502C17.7994 22.502 22.501 17.8004 22.501 12.001C22.501 6.20164 17.7994 1.5 12 1.5ZM17.789 7.98405C18.0458 7.65908 17.9906 7.18743 17.6656 6.93058C17.3407 6.67373 16.869 6.72895 16.6122 7.05392L9.8142 15.6547L9.8141 15.6547L9.80573 15.6657C9.79733 15.6767 9.78401 15.6902 9.76541 15.703C9.69275 15.7504 9.59454 15.7305 9.54629 15.6584L9.53614 15.6436L7.41258 12.6299C7.174 12.2913 6.7061 12.2102 6.3675 12.4488C6.0289 12.6874 5.94782 13.1553 6.18641 13.4939L8.30556 16.5014C8.81712 17.2556 9.84324 17.456 10.6014 16.9485L10.6047 16.9463C10.7495 16.8482 10.8829 16.7256 10.9946 16.5803L17.789 7.98405Z" fill="#69AE97"/></svg>'

type ExploreAnswerProps = {
  answer: ReceivedAnswer
}

const hasContent = (rawHtml: string) => {
  const element = document.createElement('div')
  element.innerHTML = rawHtml
  return (
    !safeIsNullOrEmpty(element.textContent) ||
    element.querySelector('img') != null ||
    element.querySelector('audio') != null ||
    element.querySelector('video') != null
  )
}

export const ExploreAnswer: React.FC<ExploreAnswerProps> = ({ answer }) => {
  if (answer.state === AnswerStateEnum.TIME_FINISHED && !hasContent(answer.answer)) {
    return <TimeExceededInfo />
  }
  console.log(answer.answer)
  return (
    <div
      className={classNames(
        styles.creativeAnswer,
        'tiny',
        answer.state === AnswerStateEnum.TIME_FINISHED && sharedStyles.timeExceededFadeout,
      )}
      dangerouslySetInnerHTML={{
        __html:
          answer.answer && answer.answer.indexOf('true') > -1
            ? '<div style="display:flex;align-items:center;gap: 8px;">' +
              CHECKMARK_SVG +
              t('answer.explore_answer_confirmed', 'Player has confirmed reviewing the content') +
              '</div>'
            : '<div>' + t('answer.explore_answer_seen', 'Player has seen the content') + '</div>',
      }}
    />
  )
}
