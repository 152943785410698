import classNames from 'classnames'
import DOMPurify from 'dompurify'
import { Fragment, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ExerciseType } from '../../api/gameTypes'
import { getIcon } from '../../common/components/icons/utils'
import { EMPTY_CONTENT_FALLBACK } from '../../common/constants'
import { getTitleForTaskType } from '../../pages/GameEditor/components/Tasks/TaskModal/helpers'
import { Task } from '../../types/commonTypes'
import { safeIsNullOrEmpty } from '../../util/string'
import styles from './TaskPreview.module.css'
import { CheckboxPreview } from './components/CheckboxPreview'
import { MatchPairsPreview } from './components/MatchPairsPreview'
import { MissingWordPreview } from './components/MissingWordPreview'
import { MultipleChoicePreview } from './components/MultipleChoicePreview'
import { TaskHeaderDetails } from './components/TaskHeaderDetails'

type TaskPreviewProps = {
  task: Task
  noPointsGame?: boolean
}

type TaskPreviewBaseProps = TaskPreviewProps & {
  headerPrefixElement?: JSX.Element
  taskNameHtml?: string
  authorHtml?: string
  isSelected?: boolean
  shadowOnExpanded?: boolean
  big?: boolean
}

type SelectableTaskPreviewProps = TaskPreviewProps & {
  selectableTaskId: string
  isSelected: boolean
  onSelect: (selectableTaskId: string) => void
  authorHtml: string
  taskNameHtml: string
}

const destroyFontFamilyAndSize = (text: string): string => {
  return text.replaceAll(/font-family/gi, 'ff').replaceAll(/font-size/gi, 'fs')
}

const TaskPreviewBase: React.FC<TaskPreviewBaseProps> = ({
  headerPrefixElement,
  task,
  authorHtml,
  taskNameHtml,
  noPointsGame,
  isSelected,
  shadowOnExpanded,
  big,
}) => {
  const { t } = useTranslation()
  const [isExpanded, setIsExpanded] = useState<boolean>(false)
  const toggleExpanded = () => setIsExpanded((prev) => !prev)
  const isMultiple = task.subtasks.length > 1
  const typeTitle = isMultiple
    ? `${t('tasks.multiple_tasks_title', 'Multiple')} (${task.subtasks.length})`
    : task.subtasks[0]
    ? getTitleForTaskType(task.subtasks[0].type, t)
    : getTitleForTaskType(task.type, t)

  return (
    <li
      className={classNames(
        styles.taskPreviewContainer,
        shadowOnExpanded && isExpanded && styles.taskPreviewContainer_shadowExpanded,
        big && styles.taskPreviewContainer_big,
      )}
    >
      <div className={styles.taskPreviewHeader}>
        {headerPrefixElement}
        <div className={styles.taskPreviewHeaderContent} onClick={toggleExpanded}>
          <div
            className={classNames(isSelected || isExpanded ? 'grey-900' : 'grey-700', styles.taskPreviewName)}
            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(taskNameHtml ?? task.name) }}
          />
          <TaskHeaderDetails
            points={task.pointsSum ?? 0}
            typeTitle={typeTitle}
            noPointsGame={noPointsGame || task.isSingleExplore}
          />
          {!safeIsNullOrEmpty(authorHtml) && (
            <div className='tiny grey-700' dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(authorHtml || '') }} />
          )}
        </div>
        <button
          className={classNames(styles.toggleCollapseButton, isExpanded && styles.iconExpanded)}
          type='button'
          onClick={toggleExpanded}
        >
          {getIcon('arrowDown')}
        </button>
      </div>
      {isExpanded && (
        <div className={styles.taskPreviewExpandedContainer}>
          {task.subtasks.length === 0 && (
            <>{t('tasks.hidden_description', 'Task content shown when the game is downloaded.')}</>
          )}
          {task.subtasks.map((subtask, index) => (
            <Fragment key={`subtask_${subtask.id}`}>
              {isMultiple && index > 0 && <div className={styles.subtasksDivider} />}
              <div className={styles.subtaskContainer}>
                {isMultiple && (
                  <TaskHeaderDetails
                    points={subtask.maxPoints ?? 0}
                    typeTitle={getTitleForTaskType(subtask.type, t)}
                    noPointsGame={noPointsGame || subtask.type === ExerciseType.ExploreExercise}
                  />
                )}
                {subtask.type !== ExerciseType.MissingWordExercise && (
                  <p
                    className={styles.subtaskDescription}
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(
                        destroyFontFamilyAndSize(subtask.description || EMPTY_CONTENT_FALLBACK),
                        {
                          FORBID_TAGS: ['button'],
                          KEEP_CONTENT: false,
                        },
                      ),
                    }}
                  />
                )}
                {subtask.type === ExerciseType.PollExercise && (
                  <CheckboxPreview subtask={subtask} noPointsGame={noPointsGame} />
                )}
                {subtask.type === ExerciseType.MultichoiceExercise && (
                  <MultipleChoicePreview subtask={subtask} noPointsGame={noPointsGame} />
                )}
                {subtask.type === ExerciseType.MissingWordExercise && (
                  <MissingWordPreview subtask={subtask} noPointsGame={noPointsGame} />
                )}
                {subtask.type === ExerciseType.CombineExercise && (
                  <MatchPairsPreview subtask={subtask} noPointsGame={noPointsGame} />
                )}
              </div>
            </Fragment>
          ))}
        </div>
      )}
    </li>
  )
}

export const TaskPreview: React.FC<TaskPreviewProps> = (props) => {
  const taskPinEl = (
    <div className={styles.taskIconContainer}>{getIcon(props.task.advanced.isFlash ? 'taskFlash' : 'exercisePin')}</div>
  )
  return <TaskPreviewBase shadowOnExpanded headerPrefixElement={taskPinEl} {...props} />
}

export const SelectableTaskPreview: React.FC<SelectableTaskPreviewProps> = ({
  selectableTaskId,
  isSelected,
  onSelect,
  ...rest
}) => {
  const onCbChange = () => onSelect(selectableTaskId)

  const cbElement = (
    <div className={styles.selectableTaskPreviewCheckboxContainer}>
      <input className={styles.checkbox} checked={isSelected} type='checkbox' onChange={onCbChange} />
    </div>
  )

  return <TaskPreviewBase headerPrefixElement={cbElement} isSelected={isSelected} big {...rest} />
}
